import {
  Box,
  Card,
  Divider,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import PGEButton from '../buttons/PGE-Button';
import { useIsMobile } from '../../util/style-utils';
import Tabs from '../tabs/Tabs';
import colors from '../../themes/main-colors';

import { navigate } from 'gatsby';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
    },

    tabDetailContainer: {
      fontSize: theme.spacing(1.75),
      display: 'flex',
      height: '345px',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        rowGap: `${theme.spacing(0.5)}px`,
      },
    },

    tabDetailContainerMobile: {
      fontSize: theme.spacing(1.75),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        rowGap: `${theme.spacing(0.5)}px`,
      },
    },

    button: {
      marginTop: '2rem',
      alignSelf: 'flex-end',
    },

    buttonMobile: {
      width: '100%',
      marginTop: '2rem',
      alignSelf: 'flex-end',
    },

    description: {
      marginTop: '1rem',
    },

    tabContainerMobile: {
      padding: '1rem',
    },

    header: {
      marginBottom: '1rem',
    },

    divider: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },

    img: {
      width: '-webkit-fill-available',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '1rem',
      height: '280px',
    },

    tabs: {
      marginTop: '1rem',
    },
  }),
);
type TabProps = {
  tabsInfo: TabDetailsProps[];
};
export type TabDetailsProps = {
  ctaText: string;
  ctaLink: string;
  mobileHeading?: string;
  title: string;
  description: React.ReactNode;
  image?: string;
  mobileImage?: string;
};

export default function TabSet(props: TabProps) {
  const { tabsInfo } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();

  function renderMobileViewTab(): React.ReactElement {
    return (
      <div className={classes.tabContainerMobile}>
        {tabsInfo.map(tab => (
          <>
            <TabDetails
              title={tab.title}
              description={tab.description}
              ctaText={tab.ctaText}
              ctaLink={tab.ctaLink}
              mobileImage={tab.mobileImage ?? tab.image}
              mobileHeading={tab.mobileHeading}
            />
            <Divider className={classes.divider} />
          </>
        ))}
      </div>
    );
  }

  const generateTabDetails = () => {
    return tabsInfo.map(tab => {
      return {
        label: tab.mobileHeading,
        data: (
          <TabDetails
            title={tab.title}
            description={tab.description}
            ctaText={tab.ctaText}
            ctaLink={tab.ctaLink}
            image={tab.image}
          />
        ),
      };
    });
  };

  return (
    <>
      <Card className={classes.tabs}>
        {isMobile ? (
          renderMobileViewTab()
        ) : (
          <Tabs
            tabBackgroundOverride={colors.navBarHoverBackground}
            isEnableSelectedTabInfo={true}
            tabs={generateTabDetails()}
          />
        )}
      </Card>
    </>
  );
}

const tabContentDetails = (
  ctaText: string = '',
  ctaLink: string = '',
  title: string = '',
  description: React.ReactNode,
) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <Box
      className={
        isMobile ? classes.tabDetailContainerMobile : classes.tabDetailContainer
      }
    >
      <div>
        <Typography className={classes.title}>{title}</Typography>
        <Box className={classes.description}>{description}</Box>
      </div>
      {ctaText && ctaLink && (
        <PGEButton
          onClick={() => void navigate(ctaLink)}
          className={isMobile ? classes.buttonMobile : classes.button}
        >
          {ctaText}
        </PGEButton>
      )}
    </Box>
  );
};

const TabDetails = (props: TabDetailsProps) => {
  const {
    image,
    mobileImage,
    ctaLink,
    ctaText,
    mobileHeading,
    title,
    description,
  } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();

  const selectedimage = isMobile && mobileImage ? mobileImage : image;

  return (
    <>
      {isMobile && (
        <Typography variant="h2" className={classes.header}>
          {mobileHeading}
        </Typography>
      )}
      {selectedimage && (
        <>
          <Grid container>
            <Grid item xs={12} md={4} sm={12}>
              <img src={selectedimage} className={classes.img} />
            </Grid>

            <Grid item xs={12} md={8} sm={12}>
              {tabContentDetails(ctaText, ctaLink, title, description)}
            </Grid>
          </Grid>
        </>
      )}
      {!selectedimage &&
        tabContentDetails(ctaText, ctaLink, title, description)}
    </>
  );
};
