import useFormState from '../../../hooks/useFormState';
import { useMemo, useEffect, useState } from 'react';
import {
  AccountDetail,
  AccountCustomer,
  SendActivationCodeResponse,
  NotificationType,
  UpdateTextPhoneInput,
  UpdateTextPhoneResponse,
  NotificationFor,
  SubmitCommPreferencesRequest,
  NotificationTypeInput,
  DeliveryTypeInput,
  SubmitCommPreferencesResponse,
  NotificationOption,
  DeliveryType,
  AlertReponse,
  LinkedPerson,
  DeletePersonDetailsResponse,
  UpdatePersonContactDetailsRequest,
  UpdatePersonContactDetailsResponse,
  CommPreference,
  DeliveryTypeDetail,
  ContactDetail,
  PendingUserCommPreferenseResponse,
} from '../../../__generated__/pge-types';
import validationRules from './ManageAlerts.rules';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import {
  sendActivationCodeMutation,
  getAccountDetails,
  updateTextPhoneMutation,
  submitCommPreferenceMutation,
  getLinkedPersons,
  deletePersonDetailsMutation,
  updatePersonContactDetailsMutation,
  submitPendingPersonCommPrefMutation,
} from './queries';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { useTranslation } from '../../../hooks/useTranslation';
import { Model, AlertOptions } from './types';
import { allAlertTypes, defaultAlertStates, defaultAlertState } from './utils';
import { useApolloClient } from '@apollo/react-hooks';
import {
  parsePhoneNumber,
  displayPhoneNumberFormat,
} from '../../../util/format';
import { compact } from 'lodash';
import { GraphQLError } from 'graphql';
import { useGetManageAlertsService } from '../../../hooks/useGetManageAlerts';

function getNotification(
  alertType: NotificationType,
  personAlerts: AlertReponse | undefined,
) {
  return (
    personAlerts?.alerts?.find(
      a => a?.notificationType === alertType?.toString(),
    ) || undefined
  );
}

function getAlertStates(
  personAlerts: AlertReponse | undefined,
  isLoggedInUser: boolean | undefined,
) {
  return allAlertTypes.reduce((result, tp) => {
    const notificationType = getNotification(tp, personAlerts);
    return {
      ...result,
      [tp]: notificationType
        ? {
            email: isLoggedInUser
              ? notificationType?.deliveryTypeDetails
                  ?.find(a => a?.deliveryType === DeliveryType.Email)
                  ?.contactDetails?.some(con => con?.isSelected)
              : notificationType.notificationType === NotificationType.Webdisc
              ? false
              : notificationType?.deliveryTypeDetails
                  ?.find(a => a?.deliveryType === DeliveryType.Email)
                  ?.contactDetails?.some(con => con?.isSelected),
            text: notificationType?.deliveryTypeDetails
              ?.find(a => a?.deliveryType === DeliveryType.Sms)
              ?.contactDetails?.some(con => con?.isSelected),
          }
        : defaultAlertState,
    };
  }, defaultAlertStates);
}

function getAlertPhone(personAlerts: AlertReponse | undefined) {
  const smsOutContactDetails = personAlerts?.alerts
    ?.find(a => a?.notificationType === NotificationType.Out)
    ?.deliveryTypeDetails?.find(a1 => a1?.deliveryType === DeliveryType.Sms)
    ?.contactDetails;
  const primaryPhone = smsOutContactDetails?.find(c1 => c1?.isPrimary === true)
    ?.value;
  const phoneNumber = smsOutContactDetails?.[0]?.value;
  return primaryPhone ? primaryPhone : phoneNumber;
}
function getAlertEmail(
  personAlerts: AlertReponse | undefined,
  isLoggedInUser: boolean | undefined,
) {
  const emailOutContactDetails = personAlerts?.alerts
    ?.find(a => a?.notificationType === NotificationType.Out)
    ?.deliveryTypeDetails?.find(a1 => a1?.deliveryType === DeliveryType.Email)
    ?.contactDetails;
  const primaryEmail = emailOutContactDetails?.find(
    c1 => c1?.isPrimary === true,
  )?.value;
  const email = emailOutContactDetails?.[0]?.value;
  return isLoggedInUser ? email : primaryEmail ? primaryEmail : email;
}

function getDefaultFormValues(
  customer?: AccountCustomer,
  account?: AccountDetail,
  alertsDetails?: AlertReponse,
  isLoggedInUser?: boolean,
  selectedPerson?: LinkedPerson,
): Model {
  const threshold =
    getNotification(
      NotificationType.Webexc,
      selectedPerson && selectedPerson.verificationStatus === 'Pending'
        ? JSON.parse(selectedPerson.inputData!).commPrefInfo
        : alertsDetails,
    )?.notificationOptions!?.value || '';

  return {
    email:
      selectedPerson && selectedPerson.verificationStatus === 'Pending'
        ? getAlertEmail(
            JSON.parse(selectedPerson.inputData!)?.commPrefInfo,
            isLoggedInUser,
          )!
        : getAlertEmail(alertsDetails, isLoggedInUser)! || '',
    phoneNumber:
      selectedPerson && selectedPerson.verificationStatus === 'Pending'
        ? getAlertPhone(JSON.parse(selectedPerson.inputData!).commPrefInfo)
          ? parsePhoneNumber(
              getAlertPhone(
                JSON.parse(selectedPerson?.inputData!).commPrefInfo,
              )!,
            )
          : ''
        : getAlertPhone(alertsDetails)
        ? parsePhoneNumber(getAlertPhone(alertsDetails)!)
        : '',
    selectedActivationContactType: 'PHONE',
    exceedThreshold: threshold,
    oldAlertState:
      selectedPerson && selectedPerson.verificationStatus === 'Pending'
        ? getAlertStates(
            JSON.parse(selectedPerson.inputData!).commPrefInfo!,
            isLoggedInUser,
          )
        : getAlertStates(alertsDetails, isLoggedInUser),
    ...(selectedPerson && selectedPerson.verificationStatus === 'Pending'
      ? getAlertStates(
          JSON.parse(selectedPerson.inputData!).commPrefInfo!,
          isLoggedInUser,
        )
      : getAlertStates(alertsDetails, isLoggedInUser)),
  };
}

type Arguments = {
  params: {
    selectedPersonParams: LinkedPerson | null;
  };
};

export function useManageAlerts({ params }: Arguments) {
  const { t, richT } = useTranslation();
  const { customer } = useAccountCustomer();
  const { accountParams } = useSelectedAccountParams();
  const [showWeeklyUsageAlertsText, setShowWeeklyUsageAlertsText] = useState(
    false,
  );
  const [displayError, setDisplayError] = useState<
    string | React.ReactNode | undefined
  >(undefined);
  const { selectedPersonParams } = params;
  const [
    showOutageNotificationAlertsText,
    setShowOutageNotificationAlertsText,
  ] = useState(false);
  const isLoggedInUser = selectedPersonParams
    ? selectedPersonParams.personId === customer?.personId
    : false;
  const encryptedPersonIdParams = selectedPersonParams
    ? selectedPersonParams.encryptedPersonId
    : accountParams?.encryptedPersonId;
  const { loading: hookLoading, data, refetch: refetchAccount } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
      commPreferencesParams: {
        notificationFor: NotificationFor.Alert,
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });
  const { data: personData, refetch: refetchPerson } = useAuthQuery<{
    getLinkedPersons: Array<LinkedPerson>;
  }>(getLinkedPersons, {
    variables: {
      params: {
        accountId: accountParams?.accountNumber,
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
    fetchPolicy: 'no-cache',
  });

  const {
    data: alertData,
    loading: alertsLoading,
    refetch: getAlertService,
  } = useGetManageAlertsService({
    requestParams: {
      params: {
        acctId: accountParams?.accountNumber,
        encryptedPersonId: encryptedPersonIdParams,
        commPreferencesParams: {
          notificationFor: NotificationFor.Alert,
        },
      },
      skipParam: !(
        selectedPersonParams &&
        selectedPersonParams.verificationStatus === 'Verified'
      ),
    },
  });

  const loading = hookLoading || !data || alertsLoading;
  const account = data?.getAccountDetails?.[0];
  const alertDeatils = alertData?.getAlerts;
  const mainPersonEmail =
    (personData &&
      personData?.getLinkedPersons.find(per => per.accountRelTypeCd === 'MAIN')
        ?.email) ||
    '';
  const apolloClient = useApolloClient();
  const form = useFormState(
    getDefaultFormValues(
      customer,
      account,
      alertDeatils,
      isLoggedInUser,
      selectedPersonParams!,
    ),
    {
      validate: validationRules,
      validationContext: { t },
    },
  );

  const defaultFormValues = useMemo(
    () =>
      getDefaultFormValues(
        customer,
        account,
        alertDeatils,
        isLoggedInUser,
        selectedPersonParams!,
      ),
    [alertDeatils, encryptedPersonIdParams],
  );

  useEffect(() => {
    setDisplayError(undefined);
    form.reset(defaultFormValues);
  }, [defaultFormValues, alertDeatils]);

  const showEnergyTracker =
    account?.pgeEnergyTracker?.billableAccountDetails?.isAccountBillable &&
    account?.pgeEnergyTracker?.billableAccountDetails
      ?.isServiceDesignationElectric &&
    account?.pgeEnergyTracker?.energyTrackerInfo?.showEnergyTracker;

  //Manage with customer eligibility for outage notification data
  const showOutageNotification = account?.commPreferences?.some(
    a => a?.notificationType === 'OUT',
  );

  const handleChangePersonDetails = async () => {
    setDisplayError(undefined);
  };

  const handleDeletePersonDetails = async (
    formData: Model,
    selectedPerson: LinkedPerson,
  ) => {
    const updatedAlertArr = compact(
      allAlertTypes.map(typ => {
        return (
          (formData[typ].email && typ !== NotificationType.Webdisc) ||
          formData[typ].text
        );
      }),
    );
    if (updatedAlertArr.length > 0) {
      setDisplayError(t('PERSON_NOT_DELETE_WHEN_ENROLLED'));
    } else if (selectedPerson.isFinanciallyResponsible) {
      setDisplayError(
        richT('REMOVE_PERSON_FINANCIALLY_RESPONSIBLE_MESSAGE', {
          PERSON_NAME: selectedPerson.name ?? '',
          ACCOUNT_NUMBER: accountParams?.accountNumber,
          CUSTOMER_SERVICE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
        }),
      );
    } else {
      const { data: deleteData } = await apolloClient.mutate<{
        deletePersonDetails: DeletePersonDetailsResponse;
      }>({
        mutation: deletePersonDetailsMutation,
        variables: {
          payload: {
            accountNumber: accountParams?.accountNumber,
            encryptedPersonId: encryptedPersonIdParams,
            firstName: selectedPerson.name!.split(', ')[0] || '',
            lastName: selectedPerson.name!.split(', ')[1] || '',
            mainPersonEmailId: mainPersonEmail,
            updatedBy: customer?.encryptedPersonId,
            relationshipType: selectedPerson.accountRelTypeCd,
          },
        },
        refetchQueries: [
          {
            query: getLinkedPersons,
            variables: {
              params: {
                accountId: accountParams?.accountNumber,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      if (deleteData?.deletePersonDetails?.success) {
        await refetchPerson();
      }
    }
  };
  const isEmailDisabled =
    selectedPersonParams! &&
    selectedPersonParams?.isCssUser! &&
    !isLoggedInUser;
  const isTextDisabled =
    (selectedPersonParams! &&
      selectedPersonParams?.isCssUser! &&
      !isLoggedInUser) ||
    (selectedPersonParams! &&
      selectedPersonParams?.verificationStatus === 'Pending' &&
      !defaultFormValues.phoneNumber);
  const alerts: Array<AlertOptions> = [
    {
      key: NotificationType.Out,
      label: t('OUTAGE_NOTIFICATIONS'),
      hideEmail: true,
      onTextClick: () => setShowOutageNotificationAlertsText(true),
      hide: !showOutageNotification,
      emailDisabled: isEmailDisabled,
      textDisabled: isTextDisabled,
    },
    {
      key: NotificationType.Webpyrem,
      label: t('PAYMENT_REMINDER'),
      formLabel: 'paymentReminder',
      emailDisabled: isEmailDisabled,
      textDisabled: isTextDisabled,
    },
    {
      key: NotificationType.Webpyrcv,
      label: t('PAYMENT_RECEIVED'),
      formLabel: 'paymentReceived',
      emailDisabled: isEmailDisabled,
      textDisabled: isTextDisabled,
    },
    {
      key: NotificationType.Webpda,
      label: t('PAST_DUE_ALERT'),
      formLabel: 'pastDueAlert',
      emailDisabled: isEmailDisabled,
      textDisabled: isTextDisabled,
    },
    {
      key: NotificationType.Webdisc,
      label: t('DISCONNECT_NOTICE'),
      emailDisabled: true,
      formLabel: 'disconnectNotice',
      textDisabled: isTextDisabled,
    },
    {
      key: NotificationType.Webuse,
      label: t('WEEKLY_USAGE'),
      onTextClick: () => setShowWeeklyUsageAlertsText(true),
      hide: !showEnergyTracker,
      emailDisabled: isEmailDisabled,
      textDisabled: isTextDisabled,
    },
    {
      key: NotificationType.Webexc,
      label: t('BILL_MAY_EXCEED'),
      textFieldKey: 'exceedThreshold',
      hide: !showEnergyTracker,
      emailDisabled: isEmailDisabled,
      textDisabled: isTextDisabled,
    },
  ].filter(alrt => !alrt.hide);

  return {
    refetchAccount,
    loading,
    account,
    form,
    defaultFormValues,
    alerts,
    showWeeklyUsageAlertsText,
    setShowWeeklyUsageAlertsText,
    showOutageNotificationAlertsText,
    setShowOutageNotificationAlertsText,
    service: useManageAlertsService(
      customer,
      account,
      selectedPersonParams!,
      alertDeatils,
    ),
    personList: personData?.getLinkedPersons,
    handleChangePersonDetails,
    handleDeletePersonDetails,
    displayError,
    refetchPerson,
    customer,
    mainPersonEmail,
  };
}

function useManageAlertsService(
  customer: AccountCustomer | undefined,
  account: AccountDetail | undefined,
  selectedPerson: LinkedPerson | undefined,
  alertDeatils: AlertReponse | undefined,
) {
  const apolloClient = useApolloClient();

  async function sendActivationCode(phoneNumber: string) {
    const { data } = await apolloClient.mutate<{
      sendActivationCode: SendActivationCodeResponse;
    }>({
      mutation: sendActivationCodeMutation,
      variables: {
        phoneNumber,
      },
    });

    const encryptedActivationCode =
      data?.sendActivationCode?.encryptedActivationCode;

    if (!encryptedActivationCode) {
      return Promise.reject('No encrypted activation code in response');
    }

    return {
      encryptedActivationCode,
    };
  }

  async function validateandUpdatePhoneNumber(
    activationCode: string,
    encryptedActivationCode: string,
    formValues: Model,
    defaultFormValues: Model,
  ) {
    const updatePhonePayload: UpdateTextPhoneInput = {
      encryptedPersonId: selectedPerson
        ? selectedPerson?.encryptedPersonId!
        : customer?.encryptedPersonId!,
      phoneNumber: '+1' + formValues.phoneNumber,
      activationCodeInput: activationCode,
      encryptedActivationCode: encryptedActivationCode,
      isActive: 'true',
    };

    const { data } = await apolloClient.mutate<{
      updateTextPhone: UpdateTextPhoneResponse;
    }>({
      mutation: updateTextPhoneMutation,
      variables: {
        payload: updatePhonePayload,
      },
    });

    const updatedAlertArr = compact(
      allAlertTypes.map(typ => {
        return (
          formValues.oldAlertState[typ].email !== formValues[typ].email ||
          formValues.oldAlertState[typ].text !== formValues[typ].text
        );
      }),
    );

    return {
      success: data?.updateTextPhone.success,
      error: data?.updateTextPhone.updatePhoneError,
      removedPhoneNumber: !formValues.phoneNumber,
      encryptedContactId: data?.updateTextPhone.encryptedContactId,
      isAlertSelected: Boolean(updatedAlertArr.length > 0),
    };
  }

  async function updateAlerts(
    formValues: Model,
    defaultFormValues: Model,
    encryptedContactId?: string,
    encryptedEmailId?: string,
  ) {
    const originalMobilePhoneNumber = defaultFormValues?.phoneNumber;
    const originalEmail = defaultFormValues?.email;
    let removeContactSuccess: UpdatePersonContactDetailsResponse | undefined;
    let submitCommPreferencesSuccess: SubmitCommPreferencesResponse | undefined;
    let submitPendingPersonCommPrefSuccess:
      | PendingUserCommPreferenseResponse
      | undefined;
    let submitCommPreferencesError: readonly GraphQLError[] | undefined;
    const newPhoneNumber = !formValues.phoneNumber
      ? null
      : displayPhoneNumberFormat(formValues.phoneNumber);
    const newEmail = !formValues.email ? null : formValues.email;

    const paymentReminderDefaultValue = '5';
    const updatedAlertArray = compact(
      allAlertTypes.map(typ => {
        const state = formValues[typ];
        const selectedPersonOriginalAlert: CommPreference[] = JSON.parse(
          selectedPerson?.inputData!,
        )?.commPrefInfo?.alerts.map((alrt: any) => {
          return {
            notificationType: alrt.notificationType,
            notificationTypeDescription: alrt.notificationTypeDescription,
            notificationOptions: alrt.notificationOptions as NotificationOption,
            deliveryTypeDetails: alrt.deliveryTypeDetails.map((del: any) => {
              return {
                deliveryType: del.deliveryType,
                deliveryTypeDescription: del.deliveryTypeDescription,
                contactDetails: del.contactDetails.map((con: any) => {
                  return {
                    contactId: con.contactId,
                    encryptedContactId: con.encryptedContactId,
                    value: con.value,
                    preferenceId: null,
                    isSelected: con.isSelected,
                    isPrimary: con.isPrimary,
                  } as ContactDetail;
                }),
              } as DeliveryTypeDetail;
            }),
          } as CommPreference;
        });

        const originalAlert =
          selectedPerson && selectedPerson.verificationStatus === 'Verified'
            ? alertDeatils?.alerts?.find(alrt => alrt?.notificationType === typ)
            : selectedPersonOriginalAlert.find(
                alrt => alrt.notificationType === typ,
              );

        if (!originalAlert) {
          return undefined;
        }

        if (
          (originalAlert &&
            defaultFormValues.exceedThreshold !== formValues.exceedThreshold) ||
          (formValues.oldAlertState[typ].email !== state.email &&
            formValues.oldAlertState[typ].text !== state.text)
        ) {
          return {
            notificationType: originalAlert.notificationType,
            notificationOptions:
              originalAlert.notificationType === NotificationType.Webexc
                ? getNotificationOption(
                    originalAlert?.notificationOptions!,
                    formValues.exceedThreshold,
                  )
                : originalAlert.notificationType === NotificationType.Webpyrem
                ? getNotificationOption(
                    originalAlert?.notificationOptions!,
                    paymentReminderDefaultValue,
                  )
                : null,
            deliveryTypes: originalAlert?.deliveryTypeDetails?.map(
              deliveryTypeDetail => {
                const personContacts = compact(
                  deliveryTypeDetail?.contactDetails?.map(contact => {
                    if (
                      contact?.value ===
                        displayPhoneNumberFormat(
                          defaultFormValues.phoneNumber,
                        ) &&
                      deliveryTypeDetail.deliveryType === 'SMS'
                    ) {
                      return {
                        encryptedContactId: encryptedContactId
                          ? encryptedContactId
                          : contact?.encryptedContactId,
                        encryptedPreferenceId: contact?.encryptedPreferenceId
                          ? contact?.encryptedPreferenceId
                          : null,
                        value: encryptedContactId
                          ? newPhoneNumber
                          : contact?.value,
                        isSelected: state.text,
                      };
                    } else if (
                      deliveryTypeDetail.deliveryType === DeliveryType.Email &&
                      contact?.value === defaultFormValues.email
                    ) {
                      return {
                        encryptedContactId: encryptedEmailId
                          ? encryptedEmailId
                          : contact?.encryptedContactId,
                        encryptedPreferenceId: contact?.encryptedPreferenceId
                          ? contact?.encryptedPreferenceId
                          : null,
                        value: encryptedEmailId ? newEmail : contact?.value,
                        isSelected: state.email,
                      };
                    }
                  }),
                );
                return {
                  deliveryType: deliveryTypeDetail?.deliveryType,
                  contacts: personContacts,
                } as DeliveryTypeInput;
              },
            ),
          };
        } else if (
          originalAlert &&
          formValues.oldAlertState[typ].email !== state.email
        ) {
          return {
            notificationType: originalAlert.notificationType,
            notificationOptions:
              originalAlert.notificationType === NotificationType.Webexc
                ? getNotificationOption(
                    originalAlert?.notificationOptions!,
                    formValues.exceedThreshold,
                  )
                : originalAlert.notificationType === NotificationType.Webpyrem
                ? getNotificationOption(
                    originalAlert?.notificationOptions!,
                    paymentReminderDefaultValue,
                  )
                : null,
            deliveryTypes: compact(
              originalAlert?.deliveryTypeDetails?.map(delType => {
                if (delType?.deliveryType === DeliveryType.Email) {
                  const personContacts = compact(
                    delType?.contactDetails?.map(contact => {
                      if (
                        contact?.value === defaultFormValues.email &&
                        delType.deliveryType === 'EMAIL'
                      ) {
                        return {
                          encryptedContactId: encryptedEmailId
                            ? encryptedEmailId
                            : contact?.encryptedContactId,
                          encryptedPreferenceId: contact?.encryptedPreferenceId
                            ? contact?.encryptedPreferenceId
                            : null,
                          isSelected: state.email,
                          value: encryptedEmailId ? newEmail : contact?.value,
                        };
                      }
                    }),
                  );
                  return {
                    deliveryType: delType?.deliveryType,
                    contacts: personContacts,
                  } as DeliveryTypeInput;
                }
              }),
            ),
          };
        } else if (
          originalAlert &&
          formValues.oldAlertState[typ].text !== state.text
        ) {
          return {
            notificationType: originalAlert.notificationType,
            notificationOptions:
              originalAlert.notificationType === NotificationType.Webexc
                ? getNotificationOption(
                    originalAlert?.notificationOptions!,
                    formValues.exceedThreshold,
                  )
                : originalAlert.notificationType === NotificationType.Webpyrem
                ? getNotificationOption(
                    originalAlert?.notificationOptions!,
                    paymentReminderDefaultValue,
                  )
                : null,
            deliveryTypes: compact(
              originalAlert?.deliveryTypeDetails?.map(delType => {
                if (delType?.deliveryType === DeliveryType.Sms) {
                  const personContacts = compact(
                    delType?.contactDetails?.map(contact => {
                      if (
                        contact?.value ===
                          displayPhoneNumberFormat(
                            defaultFormValues.phoneNumber,
                          ) &&
                        delType.deliveryType === 'SMS'
                      ) {
                        return {
                          encryptedContactId: encryptedContactId
                            ? encryptedContactId
                            : contact?.encryptedContactId,
                          encryptedPreferenceId: contact?.encryptedPreferenceId
                            ? contact?.encryptedPreferenceId
                            : null,
                          value: encryptedContactId
                            ? newPhoneNumber
                            : contact?.value,
                          isSelected: state.text,
                        };
                      }
                    }),
                  );
                  return {
                    deliveryType: delType.deliveryType,
                    contacts: personContacts,
                  } as DeliveryTypeInput;
                }
              }),
            ),
          };
        }
      }),
    ) as NotificationTypeInput[];

    const submitCommpayload: SubmitCommPreferencesRequest = {
      accountNumber: account?.accountNumber!,
      encryptedPersonId: selectedPerson
        ? selectedPerson?.encryptedPersonId!
        : customer?.encryptedPersonId!,
      notificationTypes: updatedAlertArray,
    };

    if (updatedAlertArray.length > 0) {
      if (selectedPerson && selectedPerson.verificationStatus === 'Verified') {
        const { data, errors } = await apolloClient.mutate<{
          submitCommPreferences: SubmitCommPreferencesResponse;
        }>({
          mutation: submitCommPreferenceMutation,
          variables: {
            payload: submitCommpayload,
          },
        });
        submitCommPreferencesSuccess = data?.submitCommPreferences;
        submitCommPreferencesError = errors;
      } else {
        const inputData = JSON.parse(selectedPerson?.inputData!);
        const pendingUserNotificationType = compact(
          allAlertTypes.map(alertType => {
            if (formValues[alertType].text && formValues[alertType].email) {
              return {
                notificationType: alertType,
                notificationOptions: notificationOptionForPendingUser(
                  alertType,
                  formValues.exceedThreshold,
                  paymentReminderDefaultValue,
                ),
                deliveryTypes: [
                  {
                    deliveryType: DeliveryType.Email,
                    contacts: [
                      {
                        encryptedContactId: null,
                        encryptedPreferenceId: null,
                        value: originalEmail,
                        isSelected: true,
                        isPrimary: true,
                      },
                    ],
                  },
                  {
                    deliveryType: DeliveryType.Sms,
                    contacts: [
                      {
                        encryptedContactId: null,
                        encryptedPreferenceId: null,
                        value: displayPhoneNumberFormat(
                          originalMobilePhoneNumber,
                        ),
                        isSelected: true,
                        isPrimary: true,
                      },
                    ],
                  },
                ],
              };
            } else if (formValues[alertType].text) {
              return {
                notificationType: alertType,
                notificationOptions: notificationOptionForPendingUser(
                  alertType,
                  formValues.exceedThreshold,
                  paymentReminderDefaultValue,
                ),
                deliveryTypes: [
                  {
                    deliveryType: DeliveryType.Sms,
                    contacts: [
                      {
                        encryptedContactId: null,
                        encryptedPreferenceId: null,
                        value: displayPhoneNumberFormat(
                          originalMobilePhoneNumber,
                        ),
                        isSelected: true,
                        isPrimary: true,
                      },
                    ],
                  },
                ],
              };
            } else if (formValues[alertType].email) {
              return {
                notificationType: alertType,
                notificationOptions: notificationOptionForPendingUser(
                  alertType,
                  formValues.exceedThreshold,
                  paymentReminderDefaultValue,
                ),
                deliveryTypes: [
                  {
                    deliveryType: DeliveryType.Email,
                    contacts: [
                      {
                        encryptedContactId: null,
                        encryptedPreferenceId: null,
                        value: originalEmail,
                        isSelected: true,
                        isPrimary: true,
                      },
                    ],
                  },
                ],
              };
            } else {
              return null;
            }
          }),
        );

        const updatePendingPerson = {
          key: originalEmail,
          token: selectedPerson?.personId!,
          inputData: JSON.stringify({
            ...inputData,
            commPrefInfo: {
              alerts: allAlertTypes.map(alertType => {
                return {
                  notificationType: alertType,
                  deliveryTypeDetails: [
                    {
                      deliveryType: 'EMAIL',
                      contactDetails: [
                        {
                          contactId: null,
                          value: originalEmail,
                          encryptedPreferenceId: null,
                          isSelected: formValues[alertType].email,
                          encryptedContactId: inputData.commPrefInfo.alerts[0].deliveryTypeDetails.find(
                            (delTyp: any) =>
                              delTyp.deliveryType === DeliveryType.Email,
                          ).contactDetails[0].encryptedContactId,
                          isPrimary: true,
                        },
                      ],
                      deliveryTypeDescription: 'Email',
                    },
                    {
                      deliveryType: 'SMS',
                      contactDetails: [
                        {
                          contactId: null,
                          encryptedContactId: inputData.commPrefInfo.alerts[0].deliveryTypeDetails.find(
                            (delTyp: any) =>
                              delTyp.deliveryType === DeliveryType.Sms,
                          ).contactDetails[0].encryptedContactId,
                          value: displayPhoneNumberFormat(
                            originalMobilePhoneNumber,
                          ),
                          encryptedPreferenceId: null,
                          isSelected: formValues[alertType].text,
                          isPrimary: true,
                        },
                      ],
                      deliveryTypeDescription: 'SMS',
                    },
                  ],
                  notificationTypeDescription: alertType,
                  notificationOptions: notificationOptionForPendingUser(
                    alertType,
                    formValues.exceedThreshold,
                    paymentReminderDefaultValue,
                  ),
                };
              }),
            },
            submitCommPreferences:
              pendingUserNotificationType.length > 0
                ? {
                    accountNumber: account?.accountNumber!,
                    encryptedPersonId: selectedPerson
                      ? selectedPerson?.encryptedPersonId!
                      : customer?.encryptedPersonId!,
                    notificationTypes: pendingUserNotificationType,
                  }
                : null,
            loggedInUserEmail: customer?.email,
          }),
        };

        const { data } = await apolloClient.mutate<{
          pendingUserCommPreferenseResponse: PendingUserCommPreferenseResponse;
        }>({
          mutation: submitPendingPersonCommPrefMutation,
          variables: {
            payload: updatePendingPerson,
          },
        });
        submitPendingPersonCommPrefSuccess =
          data?.pendingUserCommPreferenseResponse;
      }

      if (!newPhoneNumber && originalMobilePhoneNumber) {
        removeContactSuccess = await removePhoneNumber(
          originalMobilePhoneNumber,
        );
      }
      return {
        removedPhoneNumber:
          !newPhoneNumber &&
          originalMobilePhoneNumber &&
          removeContactSuccess?.isSuccess,
        removedEmailId:
          !newEmail && originalEmail && removeContactSuccess?.isSuccess,
        error: submitCommPreferencesError || removeContactSuccess?.reason,
        success:
          submitCommPreferencesSuccess?.success ||
          submitPendingPersonCommPrefSuccess?.success,
      };
    } else {
      if (!newPhoneNumber && originalMobilePhoneNumber) {
        removeContactSuccess = await removePhoneNumber(
          originalMobilePhoneNumber,
        );
      }
      return {
        removedPhoneNumber: !newPhoneNumber && originalMobilePhoneNumber,
        removedEmailId:
          !newEmail && originalEmail && removeContactSuccess?.isSuccess,
        error: removeContactSuccess?.reason,
        success: removeContactSuccess?.isSuccess,
      };
    }
  }
  function getNotificationOption(
    commPreferencesOption: NotificationOption | undefined,
    exceedThreshold: string,
  ) {
    if (exceedThreshold !== commPreferencesOption?.value) {
      return {
        sequence: commPreferencesOption?.sequence
          ? commPreferencesOption?.sequence
          : '1',
        value: exceedThreshold,
      };
    } else {
      return {
        sequence: commPreferencesOption?.sequence
          ? commPreferencesOption?.sequence
          : '1',
        value: commPreferencesOption?.value
          ? commPreferencesOption?.value
          : '0',
      };
    }
  }
  const removePhoneNumber = async (
    originalMobilePhoneNumber: String,
  ): Promise<UpdatePersonContactDetailsResponse | undefined> => {
    const updatePersonContactPayload: UpdatePersonContactDetailsRequest = {
      encryptedPersonId: selectedPerson
        ? selectedPerson?.encryptedPersonId!
        : customer?.encryptedPersonId!,
      phoneDetails: {
        phoneType: 'MOB',
        isPrimaryPhone: 'false',
        phone: displayPhoneNumberFormat(originalMobilePhoneNumber),
        isPhoneActive: 'false',
      },
    };
    const { data: updateContactData } = await apolloClient.mutate<{
      updatePersonContactDetails: UpdatePersonContactDetailsResponse;
    }>({
      mutation: updatePersonContactDetailsMutation,
      variables: {
        payload: updatePersonContactPayload,
      },
    });
    return updateContactData?.updatePersonContactDetails;
  };

  const notificationOptionForPendingUser = (
    alertType: string,
    exceedThreshold: string,
    paymentReminderDefaultValue: string,
  ) => {
    return alertType === NotificationType.Webexc
      ? [
          {
            sequence: '1',
            value: exceedThreshold,
          },
        ]
      : alertType === NotificationType.Webpyrem
      ? [
          {
            sequence: '1',
            value: paymentReminderDefaultValue,
          },
        ]
      : null;
  };
  return { sendActivationCode, validateandUpdatePhoneNumber, updateAlerts };
}
