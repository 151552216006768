import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import { BillingAndPaymentHistorySummary } from '../../__generated__/pge-types';
import {
  toSentenceCase,
  toCurrencyString,
  toDateString,
} from '../../util/format';
import TextLink from '../text-link';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import { PictureAsPdf } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(1, 0),
    },
    viewBillTextLink: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
          '&:active': {
            borderBottom: 'none',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    gridRow: {
      paddingBottom: `${theme.spacing(2)}px !important`,
    },
    billsRow: {
      width: '100%',
      borderRadius: '3px',
      background: colors.lightGray2,
      marginTop: '5px',
      padding: '5px',
      fontWeight: 'bold',
      fontSize: '12px',
    },
    centerText: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      },
    },
    serviceAddress: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10px',
      },
    },
  }),
);

type BillHistorySearchResultsProps = {
  results: any;
  onDownloadSingleBill: (encryptedBillId: string, isSummary: boolean) => void;
};

const BillHistorySearchResults = ({
  results,
  onDownloadSingleBill,
}: BillHistorySearchResultsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentBillDate = Math.max(
    results.map((i: BillingAndPaymentHistorySummary) => i.billDate),
  );

  const currentBill = results.find(
    (i: BillingAndPaymentHistorySummary) => i.billDate === currentBillDate,
  );

  const getBillPDF = (record: BillingAndPaymentHistorySummary) => {
    const isCurrentBill = (item: BillingAndPaymentHistorySummary): boolean => {
      if (currentBill) {
        return item.encryptedBillingId === currentBill.encryptedBillingId;
      }
      return false;
    };

    return !isMobile ? (
      <>
        {isCurrentBill(record) ? (
          <TextLink
            to={ROUTES.VIEW_BILL}
            style={{
              textDecoration: 'underline',
              marginRight: '10px',
              fontSize: '12px',
            }}
          >
            {t('VIEW_BILL_ONLINE')}
          </TextLink>
        ) : null}
        {record.isSummaryBillAccount && <><PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
        <TextLink
          style={{
            textDecoration: 'underline',
            marginRight: '10px',
            fontSize: '12px',
          }}
          onClick={(e: any) => {
            e.preventDefault();
            onDownloadSingleBill(record?.encryptedBillingId || '', true);
          }}
        >
          {t('SUMMARY_BILL_PDF')}
        </TextLink></>}
        <PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
        <TextLink
          style={{
            textDecoration: 'underline',
            marginRight: '10px',
            fontSize: '12px',
          }}
          onClick={(e: any) => {
            e.preventDefault();
            onDownloadSingleBill(record?.encryptedBillingId || '', false);
          }}
        >
          {t('DETAIL_BILL_PDF')}
        </TextLink>
      </>
    ) : (
      <Grid container>
        {isCurrentBill(record) ? (
          <Grid item xs={12}>
            <TextLink
              to={ROUTES.VIEW_BILL}
              style={{
                textDecoration: 'underline',
                marginRight: '10px',
                fontSize: '12px',
              }}
            >
              {t('VIEW_BILL_ONLINE')}
            </TextLink>
          </Grid>
        ) : null}
        {record.isSummaryBillAccount && <><Grid item xs={6}>
          <PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
          <TextLink
            style={{
              textDecoration: 'underline',
              marginRight: '10px',
              fontSize: '12px',
            }}
            onClick={(e: any) => {
              e.preventDefault();
              onDownloadSingleBill(record?.encryptedBillingId || '', true);
            }}
          >
            {t('SUMMARY_BILL_PDF')}
          </TextLink>
        </Grid></>}
        <Grid item xs={6}>
          <PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
          <TextLink
            style={{
              textDecoration: 'underline',
              marginRight: '10px',
              fontSize: '12px',
            }}
            onClick={(e: any) => {
              e.preventDefault();
              onDownloadSingleBill(record?.encryptedBillingId || '', false);
            }}
          >
            {t('DETAIL_BILL_PDF')}
          </TextLink>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {!isMobile ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={7} md={5}>
              <Typography variant="body2">{t('SSM_ACCOUNT')}</Typography>
            </Grid>
            <Grid item xs={2} md={1}>
              <Typography variant="body2" component="div">
                <Box textAlign="right">{t('KWH')}</Box>
              </Typography>
            </Grid>
            <Grid item xs={3} md={2}>
              <Typography variant="body2" component="div">
                <Box textAlign="right">{t('AMOUNT_DUE')}</Box>
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="body2" component="div">
                <Box textAlign="right">{toSentenceCase(t('DUE_DATE'))}</Box>
              </Typography>
            </Grid>

            <Grid item md={2}>
              <Typography variant="body2" component="div">
                <Box textAlign="right">{toSentenceCase(t('BILL_DATE'))}</Box>
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />{' '}
        </>
      ) : null}
      <Grid container spacing={1} direction="column" alignItems="stretch">
        {results?.map(
          (item: BillingAndPaymentHistorySummary, index: number) => (
            <Grid
              item
              container
              key={item?.encryptedBillingId || index}
              className={classes.gridRow}
            >
              <Grid item xs={12} md={5}>
                <Typography variant="body2" component="div">
                  <Box display="flex">
                    <Typography style={{ fontWeight: 'bold' }}>
                      {item?.accountNumber}
                      {item?.description ? ' (' + item.description + ')' : ''}
                    </Typography>
                  </Box>
                  {item?.details?.map((sa: any, i: number) => (
                    <Box
                      key={sa?.serviceAddress || i}
                      className={classes.serviceAddress}
                    >
                      {sa?.serviceAddress}
                    </Box>
                  ))}
                </Typography>
              </Grid>
              <Grid item xs={6} md={1} className={classes.centerText}>
                <Typography variant="body2" component="div">
                  <Box textAlign={isMobile ? 'left' : 'right'}>
                    {item?.totalKwh + ' ' + t('KWH')}
                  </Box>
                </Typography>
                {item?.details?.map((sa: any, idx: number) => (
                  <Box fontSize="0.7rem" key={sa?.serviceAddress || idx}>
                    {sa?.kwh}
                  </Box>
                ))}
              </Grid>
              <Grid item xs={6} md={2} className={classes.centerText}>
                <Typography variant="body2" component="div">
                  <Box textAlign="right">
                    ${toCurrencyString(item?.amountDue || 0, true)}
                  </Box>
                </Typography>
              </Grid>
              <Hidden smUp>
                <Grid item md={2} xs={6} className={classes.centerText}>
                  <Typography variant="body2" component="div">
                    <Box textAlign={isMobile ? 'left' : 'right'}>
                      {t('BILL_DATE_2')}{' '}
                      {toDateString(
                        item?.billDate,
                        isMobile ? 'MM/dd/yy' : 'MM/dd/yyyy',
                      )}
                    </Box>
                  </Typography>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item md={2} xs={6} className={classes.centerText}>
                  <Typography variant="body2" component="div">
                    <Box textAlign="right">
                      {t('DUE_DATE_2')}{' '}
                      {toDateString(
                        item?.dueDate,
                        isMobile ? 'MM/dd/yy' : 'MM/dd/yyyy',
                      )}
                    </Box>
                  </Typography>
                </Grid>
              </Hidden>
              <Hidden xsDown>
                <Grid item md={2} xs={6} className={classes.centerText}>
                  <Typography variant="body2" component="div">
                    <Box textAlign="right">
                      {toDateString(item?.dueDate, 'MM/dd/yyyy')}
                    </Box>
                  </Typography>
                </Grid>
              </Hidden>
              <Hidden xsDown>
                <Grid item md={2} xs={6} className={classes.centerText}>
                  <Typography variant="body2" component="div">
                    <Box textAlign={isMobile ? 'left' : 'right'}>
                      {toDateString(item?.billDate, 'MM/dd/yyyy')}
                    </Box>
                  </Typography>
                </Grid>
              </Hidden>
              <Grid item className={classes.billsRow} data-testid={'bill-row'}>
                <Grid container spacing={2}>
                  <Grid item className={classes.viewBillTextLink}>
                    {getBillPDF(item)}
                  </Grid>
                </Grid>
              </Grid>
              <div className="spacer" />
              <Box width="100%">
                <Divider className={classes.divider} />
              </Box>
            </Grid>
          ),
        )}
      </Grid>
    </>
  );
};

export default BillHistorySearchResults;
