import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../../../components/account-summary/manage-alerts/styles';
import PhoneTextField from '../../phone-text-field/PhoneTextField';
import { useTranslation } from '../../../hooks/useTranslation';
import { useIsMobile } from '../../../util/style-utils';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import {
  notificationKey,
  NotificationPreferenceObj,
  PostEnrollmentNotificationSettings,
  PostEnrollmentNotificationSettingsModel,
 NotificationPreference, AlertStates } from '../ssm.types';
import Backdrop from '../../../components/backdrop';
import { EmailTextToggleControlSSM } from './EmailTextToggleControlSSM';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import { convertValidationRules } from '../../../hooks/useFormState';
import { validatePhoneNumber } from '../../../util/form-validation';
import { BaseValidationContext } from '../../../hooks/useFormState.types';
import routes from '../../../routes';
import { MobChangeWarningModal } from '../start/MobChangeWarning/MobChangeWarning';
import useGetAlertsService from '../../../hooks/useSSMGetNotification';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import {
  CommPreference,
  NotificationFor,
  GetAlertsRequest,
  NotificationType,
} from '../../../__generated__/pge-types';

const useNotificationStyles = makeStyles((theme: Theme) => ({
  headerText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
    paddingLeft: '0',
  },
  topDefinedSec: {
    background: colors.gray100,
    padding: '10px 10px 0',
  },

  inputText: {
    color: colors.noirBlur,
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: '20px',
  },
  emailTitle: {
    color: colors.noirBlur,
    fontSize: '16px',
    letterSpacing: -'0.16px',
    lineHeight: '20px',
    '& span': {
      fontWeight: '600',
    },
  },
  phoneField: {
    width: '100%',
    '& div': {
      backgroundColor: colors.siteBackground,
    },
    '& input': {
      padding: '11.5px 14px',
    },
    '& label ': {
      transform: ' translate(15px, 14px) scale(1)',
    },
  },
  emailBox: {
    marginRight: '50px',
    minWidth: '48px',
  },
  emailText: {
    color: colors.darkGray3,
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 'bold',
  },
  textFldText: {
    color: colors.darkGray3,
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 'bold',
  },
  textArea: {
    width: '50%',
    height: '48px !important',
    border: `1px solid ${colors.lightGray4}`,
    borderRadius: '4px',
    resize: 'none',
    marginTop: '-14px',
  },
  preferredLangText: {
    marginTop: '30px',
    marginBottom: '17px',
    color: colors.noirBlur,
    fontSize: '16px',
    lineHeight: '27px',
  },
  emailRow: {
    border: '0px',
    minHeight: 'auto',
    marginTop: '15px',
  },
  numericFld: {
    width: 140,
  },
  selectField: {
    width: '100%',
    '& > div': {
      padding: '11.5px 14px',
    },
  },
  footerText: {
    color: colors.noirBlur,
    fontSize: '14px',
    lineHeight: '18px',
    marginTop: '10px',
  },
  userInfo: {
    padding: '16px 0px',
  },
}));

const { t, richT } = useTranslation();
export const SSMNotificationSettings = ({
  formState,
  componentProps,
}: WizardFormProps<PostEnrollmentNotificationSettings>) => {
  const classes = useStyles();
  const notifyClass = useNotificationStyles();
  const isMobile = useIsMobile();
  const { customer, loading: customerLoading } = useAccountCustomer();
  const [emailText, setEmailText] = useState<NotificationPreferenceObj>({});
  const { mobNumber, setLoading } = componentProps;
  const [
    displayMobChangeWarningModal,
    setDisplayMobChangeWarningModal,
  ] = useState<boolean>(false);
  const [regNumber, setRegNumber] = useState<String | undefined>();
  const { accountParams } = useSelectedAccountParams();

  const preferredLanguages = {
    ENGLISH: t('ENGLISH'),
    ARABIC: t('ARABIC'),
    BURMESE: t('BURMESE'),
    CAMBODIAN: t('CAMBODIAN'),
    CHINESE_CANTONESE: t('CHINESE_CANTONESE'),
    CHINESE_MANDARIN: t('CHINESE_MANDARIN'),
    FARSI: t('FARSI'),
    JAPANESE: t('JAPANESE'),
    KOREAN: t('KOREAN'),
    LAOTIAN: t('LAOTIAN'),
    ROHINGYA: t('ROHINGYA'),
    ROMANIAN: t('ROMANIAN'),
    RUSSIAN: t('RUSSIAN'),
    SOMALI: t('SOMALI'),
    SPANISH: t('SPANISH'),
    SWAHILI: t('SWAHILI'),
    VIETNAMESE: t('VIETNAMESE'),
    OTHER: t('OTHER'),
  };

  const {
    data,
    loading: alertsLoading,
    refetch: getAlertService,
  } = useGetAlertsService({
    acctId: accountParams?.accountNumber,
    encryptedPersonId: accountParams?.encryptedPersonId,
    commPreferencesParams: {
      notificationFor: NotificationFor.Alert,
    },
  });

  const alertsList = data?.getAlerts?.alerts;

  const handleRegisteredMobileNumberBlur = (
    e: React.MouseEvent<HTMLInputElement>,
  ) => {
    if (Boolean(mobNumber)) {
      e.persist();
      const _regNumber = e.currentTarget.value.replace(/\D+/g, '') || '';
      if (
        _regNumber !== regNumber &&
        _regNumber.length === 10 &&
        _regNumber !== mobNumber
      ) {
        setDisplayMobChangeWarningModal(true);
      }
    }
    void formState.props('phone').onBlur();
  };
  const handleMobChangeWarningModal = () => {
    setRegNumber(formState.values.phone);
    setDisplayMobChangeWarningModal(false);
  };

  const updateNotificationStates = async () => {
    if (Object.keys(emailText).length) {
      await formState.setValue('notificationTypes', emailText);
    }
    await formState.setValue('email', customer?.email);
  };

  useEffect(() => {
    void updateNotificationStates();
  }, [emailText, customer]);

  const updateAlertState = async () => {
    let exceedThreshold = '';
    let oldAlertObj = {};
    alertsList?.forEach((alert: any) => {
      if (alert.notificationType === NotificationType.Webexc) {
        exceedThreshold = alert.notificationOptions?.value;
      }
      setEmailText(prev => {
        return {
          ...prev,
          [alert?.notificationType]: {
            email:
              alert?.deliveryTypeDetails?.[0]?.deliveryType === 'EMAIL'
                ? alert?.deliveryTypeDetails?.[0]?.contactDetails?.[0]
                    ?.isSelected
                : undefined,
            text:
              alert?.deliveryTypeDetails?.[0]?.deliveryType === 'SMS'
                ? alert?.deliveryTypeDetails?.[0]?.contactDetails?.[0]
                    ?.isSelected
                : alert?.deliveryTypeDetails?.[1]?.contactDetails?.[0]
                    ?.isSelected,
          },
        };
      });

      oldAlertObj = {
        ...oldAlertObj,
        [alert?.notificationType]: {
          email:
            alert?.deliveryTypeDetails?.[0]?.deliveryType === 'EMAIL'
              ? alert?.deliveryTypeDetails?.[0]?.contactDetails?.[0]?.isSelected
              : undefined,
          text:
            alert?.deliveryTypeDetails?.[0]?.deliveryType === 'SMS'
              ? alert?.deliveryTypeDetails?.[0]?.contactDetails?.[0]?.isSelected
              : alert?.deliveryTypeDetails?.[1]?.contactDetails?.[0]
                  ?.isSelected,
        },
      };
    });
    await formState.setValue('oldAlertState', oldAlertObj);
    await formState.setValue('originalAlerts', alertsList);
    await formState.setValue('exceedThreshold', exceedThreshold);
  };

  useEffect(() => {
    if (alertsList?.length) {
      void updateAlertState();
    }
    if (data?.getAlerts?.prefLanguage) {
      void formState.setValue('prefLanguage', data?.getAlerts?.prefLanguage);
    }
    if (data?.getAlerts?.prefLanguage != null) {
      const prefLanguage = data.getAlerts.prefLanguage;
      void formState.setValue('prefLanguage', prefLanguage);
    } else {
      void formState.setValue('prefLanguage', 'English');
    }
  }, [alertsList, data]);

  const loading = customerLoading || alertsLoading;

  const getAlertNotifications = async () => {
    setLoading(true);
    const params: GetAlertsRequest = {
      acctId: accountParams?.accountNumber,
      encryptedPersonId: accountParams?.encryptedPersonId,
      commPreferencesParams: {
        notificationFor: NotificationFor.Alert,
      },
    };
    const result = await getAlertService({ params });
    if (result?.data) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      formState.values.notificationTypes &&
      !Object.keys(emailText).length &&
      formState.values.email !== ''
    ) {
      void getAlertNotifications();
    }
  }, []);

  return (
    <>
      <Grid style={{ width: '100%' }}>
        {loading && <Backdrop forceOpen />}
        {!loading && (
          <Grid item className={notifyClass.userInfo}>
            <Box mb="8px">
              <Typography className={notifyClass.headerText}>
                {t('SSM_SETUP_NOTIFICATIONS_SUBTITLE')}
              </Typography>
            </Box>
            <Box
              style={{ minWidth: `${isMobile ? 'auto' : '384px'}` }}
              className={notifyClass.topDefinedSec}
            >
              <Typography
                variant={'body1'}
                className={classes.colorTextPrimary}
                component="span"
              >
                <Typography
                  variant={'body1'}
                  data-testid="test-email"
                  className={notifyClass.emailTitle}
                >
                  {`${t('EMAIL')}: `} <span>{formState.values.email}</span>
                </Typography>
              </Typography>
            </Box>
            <Box className={notifyClass.topDefinedSec}>
              <PhoneTextField
                name="phone"
                style={{ width: '100%', background: 'transparent' }}
                className={notifyClass.phoneField}
                {...formState.props('phone')}
                onBlur={handleRegisteredMobileNumberBlur}
              />
              <Typography className={notifyClass.inputText}>
                {richT('SSM_POST_NOTIFICATIONS_LEGAL_DISCLAIMER', {
                  PRIVACY_POLICY_LINK: routes.PRIVACY_POLICY,
                })}
              </Typography>
            </Box>
            <Box mb={2}>
              <div>
                <div className={classes.gridContainer}>
                  <div className={`${classes.row} ${notifyClass.emailRow}`}>
                    <Box></Box>
                    <Box className={notifyClass.emailBox}>
                      <Typography
                        variant={'body1'}
                        className={notifyClass.emailText}
                      >
                        {t('EMAIL')}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant={'body1'}
                        className={notifyClass.textFldText}
                      >
                        {t('TEXT')}
                      </Typography>
                    </Box>
                  </div>

                  {alertsList?.map(alert => (
                    <EmailTextToggleControlSSM
                      alert={alert as CommPreference}
                      emailText={emailText}
                      setEmailText={setEmailText}
                      form={formState}
                      textFieldKey={
                        alert?.notificationType === NotificationType.Webexc
                          ? 'exceedThreshold'
                          : ''
                      }
                    />
                  ))}
                  <Typography
                    variant="h2"
                    className={notifyClass.preferredLangText}
                    data-testid="preffered-lang-header"
                  >
                    {t('PREFERRED_CONTACT_LANGUAGE')}
                  </Typography>
                  {formState.values.prefLanguage !== undefined ? (
                    <Grid item xs={12} md={12}>
                      <Select
                        id="preferredLanguage"
                        name="preferredLanguage"
                        value={formState.values.prefLanguage?.toUpperCase()}
                        variant="outlined"
                        className={notifyClass.selectField}
                        onChange={async (e: any) => {
                          await formState.setValue(
                            'prefLanguage',
                            e.target.value,
                          );
                        }}
                      >
                        {Object.keys(preferredLanguages).map(v => (
                          <MenuItem key={v} value={v}>
                            {
                              preferredLanguages[
                                v as keyof typeof preferredLanguages
                              ]
                            }
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        variant="h2"
                        className={notifyClass.footerText}
                        data-testid="preffered-lang-preference"
                      >
                        {t('SSM_NOTIFICATION_LANGUAGE_PREFERENCE')}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Box>
          </Grid>
        )}
      </Grid>
      <MobChangeWarningModal
        isOpen={displayMobChangeWarningModal}
        handleClose={handleMobChangeWarningModal}
      />
    </>
  );
};

export const createNotificationFormAdapter = (mobNumber: string) => {
  return {
    toFormState: (
      initial: PostEnrollmentNotificationSettings,
    ): PostEnrollmentNotificationSettings => ({
      phone: initial.phone || mobNumber,
      email: initial.email,
      prefLanguage: initial.prefLanguage,
      notificationTypes: initial.notificationTypes,
      originalAlerts: initial.originalAlerts,
      oldAlertState: initial.oldAlertState,
      exceedThreshold: initial.exceedThreshold,
    }),
    fromFormState: (
      state: PostEnrollmentNotificationSettings,
    ): PostEnrollmentNotificationSettings => {
      return {
        phone: state.phone,
        email: state.email,
        prefLanguage: state.prefLanguage,
        notificationTypes: state.notificationTypes,
        originalAlerts: state.originalAlerts,
        oldAlertState: state.oldAlertState,
        exceedThreshold: state.exceedThreshold,
      };
    },
  };
};

const skipPhoneValidation = (
  context: BaseValidationContext<PostEnrollmentNotificationSettingsModel>,
): boolean => {
  const { notificationTypes } = context?.values;
  return Object.values(notificationTypes).some(
    (o: NotificationPreference) => o.text === true,
  );
};

export const createNotificationValidation = () =>
  convertValidationRules<PostEnrollmentNotificationSettingsModel>(context => {
    return {
      phone: skipPhoneValidation(context) ? validatePhoneNumber : null,
      exceedThreshold: value => {
        const values =
          context.values.notificationTypes[NotificationType.Webexc];
        if (values.text || values.email) {
          if (Number(value) === 0 || !value) {
            return t('THRESHOLD_ERROR'); //context.t('THRESHOLD_ERROR');
          }
          if (Number(value) > 99999) {
            return t('THRESHOLD_ERROR'); //context.t('THRESHOLD_ERROR');
          }
        }
      },
    };
  });
