import DateFnsUtils from '@date-io/date-fns';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import useDownloadBill from '../../hooks/useDownloadBill';
import { useTranslation } from '../../hooks/useTranslation';
import useViewAndDownloadBills from '../../hooks/useViewAndDownloadBills';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { getBillingAndPaymentHistoryDetails } from '../../queries/getBillingAndPaymentHistoryDetails.query';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import { getOneMonthInFuture } from '../../util/date-utils';
import {
  toCurrencyString,
  toDateString,
  toSentenceCase,
} from '../../util/format';
import {
  BillingAndPaymentDelimiterType,
  BillingAndPaymentReportType,
  Group,
  GroupType,
} from '../../__generated__/pge-types';
import AccountGroupDropdown from '../account-group-dropdown';
import Backdrop from '../backdrop';
import PGEButton from '../buttons';
import Dropdown from '../dropdown';
import TextLink from '../text-link';
import Page2ColumnLayout from '../utility/page2-column-layout';
import ViewBillLeftPane from '../view-bill/view-bill-left-pane';
import BillHistorySearchResults from './BillHistorySearchResults';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageGrid: {
      width: '100%',
    },
    searchContainer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    header: {
      fontWeight: 'bold',
      color: colors.noirBlur,
    },
    radioGroup: {
      width: '100%',
      boxSizing: 'border-box',
      margin: theme.spacing(2, 0, 4),
    },
    footnote: {
      fontSize: '0.875rem',
      color: colors.noirBlur,
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    dateInputs: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    disclaimer: {
      fontSize: '0.875rem',
      color: `${colors.roseRed} !important`,
    },
    searchButton: {
      marginTop: theme.spacing(2),
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    backLink: {
      marginTop: theme.spacing(1),
    },
    downloadButton: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
        width: '100%',
      },
    },
    results: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    fileType: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      margin: theme.spacing(2, 0),

      '& > p': {
        marginRight: theme.spacing(3),
      },
    },
    amountInfo: {
      marginLeft: '50px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      },
    },
    searchHeaderContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    accordionSummary: {
      fontSize: '0.875rem',
      padding: 0,
    },
    accordionSummaryHeading: {
      fontSize: '0.875rem',
      padding: 0,
    },
    accordionDetails: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    accordionDetailsItems: {
      marginBottom: '20px',
    },
    mobilePhoneButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'block',
        border: '1px solid #27788C',
        borderRadius: '5px',
        color: '#27788C',
        textDecoration: 'none',
        padding: '10px',
        textAlign: 'center',
        marginTop: '10px',
      },
    },
    selected: {
      '&&': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
      '&&:active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
      '&&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
  }),
);

enum SortType {
  DueDate = 'Due date',
  BillDate = 'Bill date',
}

type SearchHeaderProps = {
  sortBy: string;
  startDate: string | number | Date | null | undefined;
  endDate: string | number | Date | null | undefined;
  amountDue: number | null | undefined;
  totalBills: number;
  setDateRange: React.Dispatch<
    React.SetStateAction<
      | {
          startDate: string | number | Date | null | undefined;
          endDate: string | number | Date | null | undefined;
        }
      | null
      | undefined
    >
  >;
  delimiterType: BillingAndPaymentDelimiterType;
  onChangeDelimiterType: (_: any, type: any) => void;
  reportType: BillingAndPaymentReportType;
  onChangeReportType: (e: any) => void;
  onDownload: () => Promise<void>;
  downloadLoading: boolean;
};

const SearchHeader = ({
  sortBy,
  endDate,
  startDate,
  amountDue,
  totalBills,
  setDateRange,
  delimiterType,
  onChangeDelimiterType,
  reportType,
  onChangeReportType,
  onDownload,
  downloadLoading,
}: SearchHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box display="flex" className={classes.searchHeaderContainer}>
        <Box display="flex" flexDirection="column">
          {sortBy && (
            <Typography variant="body2">
              {`${t('SORTED_BY')} `}
              <span style={{ fontWeight: 'bold' }}>
                {SortType[sortBy as keyof typeof SortType]}
              </span>
            </Typography>
          )}
          <Typography variant="body2">
            {`${t('DATE_RANGE')} `}
            {isMobile ? (
              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>
                  {toDateString(startDate, 'MM/dd/yyy')}
                </span>
                {` ${t('TO').toLowerCase()} `}
                <span style={{ fontWeight: 'bold' }}>
                  {toDateString(endDate, 'MM/dd/yyyy')}
                </span>
              </Typography>
            ) : (
              <>
                <span style={{ fontWeight: 'bold' }}>
                  {toDateString(startDate, 'MM/dd/yyy')}
                </span>
                {` ${t('TO').toLowerCase()} `}
                <span style={{ fontWeight: 'bold' }}>
                  {toDateString(endDate, 'MM/dd/yyyy')}
                </span>
              </>
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.amountInfo}
        >
          <Typography variant="body2">
            {`${t('TOTAL_AMOUNT_DUE')} `}
            <span style={{ fontWeight: 'bold' }}>
              {`$${toCurrencyString(amountDue ?? 0, true)}`}
            </span>
          </Typography>
          {totalBills > 0 && (
            <Typography variant="body2">
              {`${t('ACCOUNTS_BILLED')} `}
              <span style={{ fontWeight: 'bold' }}>{totalBills}</span>
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.backLink}>
        <TextLink
          onClick={(e: any) => {
            e.preventDefault();
            setDateRange(null);
          }}
          to={ROUTES.DOWNLOAD_MULTIPLE_BILLS}
        >
          {t('CHANGE_SEARCH_OPTIONS')}
        </TextLink>
      </Box>
      <Box>
        <Divider className={classes.divider} />
      </Box>
      <Box>
        <Accordion style={{ boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.accordionSummary}
          >
            <Typography className={classes.accordionSummaryHeading}>
              {t('DOWNLOAD_ALL_AS_CSV_TXT')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionSummary}>
            <Box width="100%">
              <Box className={classes.accordionDetails}>
                <Box className={classes.accordionDetailsItems}>
                  <ToggleButtonGroup
                    value={delimiterType}
                    exclusive
                    onChange={onChangeDelimiterType}
                    style={{ height: '48px' }}
                  >
                    <ToggleButton
                      value={BillingAndPaymentDelimiterType.Csv}
                      aria-label={BillingAndPaymentDelimiterType.Csv}
                      classes={{ selected: classes.selected }}
                    >
                      <Typography variant="body2">{t('CSV')}</Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={BillingAndPaymentDelimiterType.Pipe}
                      aria-label={BillingAndPaymentDelimiterType.Pipe}
                      classes={{ selected: classes.selected }}
                    >
                      <Typography variant="body2">{t('TXT')}</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box className={classes.accordionDetailsItems}>
                  <Dropdown
                    style={{ height: '48px' }}
                    value={reportType}
                    onChange={onChangeReportType}
                    selectionList={[
                      {
                        label: t('SHORT_FORM'),
                        value: BillingAndPaymentReportType.ShortForm,
                      },
                      {
                        label: t('LONG_FORM'),
                        value: BillingAndPaymentReportType.LongForm,
                      },
                      {
                        label: t('ACCOUNTING'),
                        value: BillingAndPaymentReportType.Accounting,
                      },
                    ]}
                  />
                </Box>
                <Box className={classes.accordionDetailsItems}>
                  <PGEButton
                    style={{ height: '48px' }}
                    className={classes.downloadButton}
                    onClick={onDownload}
                    disabled={downloadLoading}
                  >
                    {downloadLoading ? (
                      <CircularProgress />
                    ) : (
                      t('DOWNLOAD_BILLS_AS_CSV_OR_TXT')
                    )}
                  </PGEButton>
                </Box>
              </Box>
              <Box>
                <Divider className={classes.divider} />
              </Box>
              <Box>
                <Box className={classes.accordionDetailsItems}>
                  <Typography variant="body2" component="div">
                    <Box display="inline-block">
                      {isMobile
                        ? t('DOWNLOAD_BILLING_DATA_ASSISTANCE_2').slice(0, -4)
                        : t('DOWNLOAD_BILLING_DATA_ASSISTANCE_2')}
                      <a
                        className={
                          isMobile ? classes.mobilePhoneButton : undefined
                        }
                        href={`tel:${t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}`}
                      >
                        {`${t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')} `}
                      </a>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

const useGroupDisplay = (group: Group | null | undefined): string => {
  const { t } = useTranslation();

  if (group && group?.type === GroupType.Virtual) {
    return t('ALL_ACCOUNTS');
    //@ts-ignore
  } else if (name) {
    return name;
  }

  return t('THIS_GROUP');
};

const ViewAndDownloadBills = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { richT, t } = useTranslation();
  const { encryptedPersonId } = useSelectedAccountParams();
  const [sortBy, setSearchBy] = useState<string>('DueDate');
  const [startDate, setStartDate] = useState<string | Date | number | null>(
    new Date(),
  );
  const [endDate, setEndDate] = useState<string | Date | number | null>(
    getOneMonthInFuture(),
  );

  const [delimiterType, setDelimiterType] = useState<
    BillingAndPaymentDelimiterType
  >(BillingAndPaymentDelimiterType.Csv);

  const [reportType, setReportType] = useState<BillingAndPaymentReportType>(
    BillingAndPaymentReportType.ShortForm,
  );

  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);
  const [datePickerError, setDatePickerError] = useState<string | null>(null);
  const [showEarliestDateMessage, setShowEarliestDateMessage] = useState<
    boolean
  >(false);

  const {
    data: bills,
    loading,
    setDateRange,
    downloadLoading,
    selectedGroup,
    downloadBillingAndPaymentHistory,
  } = useViewAndDownloadBills(getBillingAndPaymentHistoryDetails, {
    encryptedPersonId: encryptedPersonId || '',
    sortBy,
    reportType: reportType,
    delimiterType: delimiterType,
  });

  const groupDisplay = useGroupDisplay(selectedGroup);

  const downloader = useDownloadBill();
  const { wrapWithLoader } = useWrapWithLoader();

  const onChangeSearchBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBy((e.target as HTMLInputElement).value);
  };

  const onChangeReportType = (e: any) => {
    const { target } = e;
    setReportType(target.value as BillingAndPaymentReportType);
  };

  const onChangeDelimiterType = (_: any, type: any) => {
    if (type) {
      setDelimiterType(type as BillingAndPaymentDelimiterType);
    }
  };

  const handleChangeDate = (name: string) => (date: any) => {
    const sixMonths = 1000 * 60 * 60 * 24 * 184;
    if (name === 'startDate') {
      if (date) {
        const minDate = new Date('2018-04-01T00:00:00.000Z').getTime();
        if (date < minDate) {
          setShowEarliestDateMessage(true);
        } else {
          setShowEarliestDateMessage(false);
        }
      }
      setStartDate(date ? date : null);
      setStartDateError(null);
      if (endDate && date) {
        const endDateTime = new Date(endDate).getTime();
        const startDateTime = new Date(date).getTime();
        const isGreaterThanSixMonths = endDateTime - startDateTime > sixMonths;
        setEndDateError(isGreaterThanSixMonths ? t('MAX_DATE_RANGE') : null);
      } else {
        setEndDateError(null);
      }
    }

    if (name === 'endDate') {
      setEndDate(date ? date : null);
      setEndDateError(null);
      if (startDate && date) {
        const endDateTime = new Date(date).getTime();
        const startDateTime = new Date(startDate).getTime();
        const isGreaterThanSixMonths = endDateTime - startDateTime > sixMonths;
        setEndDateError(isGreaterThanSixMonths ? t('MAX_DATE_RANGE') : null);
      } else {
        setStartDateError(null);
      }
    }
  };

  const hasDateError = (error: string) => {
    setDatePickerError(error);
  };

  const search = () => {
    if (startDate && endDate) {
      const endDateTime = new Date(endDate).getTime();
      const startDateTime = new Date(startDate).getTime();
      if (endDateTime < startDateTime) {
        setEndDateError(t('END_DATE_ERROR'));
      } else if (!startDateError && !endDateError && !datePickerError) {
        setStartDateError(null);
        setEndDateError(null);
        setDateRange({
          startDate,
          endDate,
        });
      }
    } else {
      if (!startDate) {
        setStartDateError(t('SET_START_DATE'));
      } else {
        setStartDateError(null);
      }

      if (!endDate) {
        setEndDateError(t('SET_END_DATE'));
      } else {
        setEndDateError(null);
      }
    }
  };

  const onDownload = () => {
    return downloadBillingAndPaymentHistory();
  };

  const downloadSingleBill = wrapWithLoader(
    async (encryptedBillId: string, isSummary: boolean) => {
      if (encryptedBillId) {
        const billId = encryptedBillId;
        const linkSource = await downloader.downloadBill(billId, isSummary);
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = `${t('VIEW_BILL').replace(
          ' ',
          '_',
        )}-${toDateString(startDate, 'MMddyyyy')}_${toDateString(
          endDate,
          'MMddyyyy',
        )}.pdf`;
        downloadLink.click();
      }
    },
    () => t('NO_BILL_FOUND'),
  );

  const minStartDate = useMemo(() => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 3,
      today.getMonth(),
      today.getDate(),
    );
    return minDate;
  }, []);

  const renderResults = (isLoading: boolean) => {
    if (!bills) {
      return (
        <Paper className={classes.searchContainer}>
          <form onSubmit={search}>
            <Typography variant="h6" className={classes.header}>
              {richT('VIEW_BILLS_FOR_GROUP', {
                groupName: groupDisplay,
              })}
            </Typography>
            <RadioGroup
              className={classes.radioGroup}
              aria-label={'View Bills for Group By'}
              value={sortBy}
              onChange={onChangeSearchBy}
            >
              <FormControlLabel
                value="DueDate"
                control={<Radio color="primary" />}
                label={t('DUE_DATE')}
              />
              <FormControlLabel
                value="BillDate"
                control={<Radio color="primary" />}
                label={`${toSentenceCase(t('BILL_DATE'))}`}
              />
              <Typography variant="subtitle1" className={classes.footnote}>
                {`${t('BILL_DATE_NOTE')}`}
              </Typography>
            </RadioGroup>
            <Divider className={classes.divider} />
            <Typography variant="h6" className={classes.header}>
              {t('ENTER_A_DATE_RANGE')}
            </Typography>
            {showEarliestDateMessage && (
              <Typography variant="h6" component="div">
                <Box display="inline-block">
                  {`${t('EARLIEST_RECORD_DATE_MESSAGE')} ${t('ASSISTANCE')} `}
                  <a
                    href={`tel:${t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}`}
                    style={{
                      textDecoration: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    {`${t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')} `}
                  </a>
                  {t('ASSISTANCE_HOURS')}
                </Box>
              </Typography>
            )}
            <span className={classes.dateInputs}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  value={startDate}
                  label={t('FROM')}
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'start date',
                  }}
                  maxDate={new Date()}
                  maxDateMessage={t('MAX_START_DATE')}
                  onChange={handleChangeDate('startDate') as any}
                  minDate={minStartDate}
                  minDateMessage={t('MIN_START_DATE')}
                  onError={hasDateError as any}
                />
              </MuiPickersUtilsProvider>
              {startDateError && (
                <Typography variant="subtitle1" component="div">
                  <Box
                    color={colors.roseRed}
                    fontSize="0.75rem"
                    marginLeft="15px"
                    marginTop="-3px"
                  >
                    {startDateError}
                  </Box>
                </Typography>
              )}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-to"
                  value={endDate}
                  label={t('TO')}
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                  name="endDate"
                  onChange={handleChangeDate('endDate') as any}
                  onError={hasDateError as any}
                />
              </MuiPickersUtilsProvider>
              {endDateError && (
                <Typography variant="subtitle1" component="div">
                  <Box
                    color={colors.roseRed}
                    fontSize="0.75rem"
                    marginLeft="15px"
                    marginTop="-3px"
                  >
                    {endDateError}
                  </Box>
                </Typography>
              )}
            </span>
            <Typography variant="subtitle1" className={classes.footnote}>
              {t('DATE_RANGE_NOTE')}
            </Typography>
            <Typography variant="subtitle2" className={classes.disclaimer}>
              {t('DATE_RANGE_DISCLAIMER')}
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <PGEButton
                className={classes.searchButton}
                onClick={search}
                disabled={isLoading}
              >
                {!isLoading ? (
                  t('SEARCH')
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress color="primary" />
                  </Box>
                )}
              </PGEButton>
            </Box>
          </form>
        </Paper>
      );
    }

    if (bills?.billingSummaries?.length) {
      return (
        <>
          {loading && <Backdrop forceOpen />}
          <Paper className={classes.searchContainer}>
            <SearchHeader
              startDate={startDate}
              endDate={endDate}
              sortBy={sortBy}
              amountDue={bills?.totalAmount}
              totalBills={bills?.totalAccounts || 0}
              setDateRange={setDateRange}
              delimiterType={delimiterType}
              onChangeDelimiterType={onChangeDelimiterType}
              reportType={reportType}
              onChangeReportType={onChangeReportType}
              onDownload={onDownload}
              downloadLoading={downloadLoading}
            />
          </Paper>
          <Paper className={classes.results}>
            <BillHistorySearchResults
              results={bills?.billingSummaries}
              onDownloadSingleBill={downloadSingleBill}
            />
          </Paper>
        </>
      );
    }

    return (
      <>
        <Paper className={classes.searchContainer}>
          {loading && <Backdrop forceOpen />}
          <SearchHeader
            startDate={startDate}
            endDate={endDate}
            sortBy={sortBy}
            amountDue={bills?.totalAmount}
            totalBills={0}
            setDateRange={setDateRange}
            delimiterType={delimiterType}
            onChangeDelimiterType={onChangeDelimiterType}
            reportType={reportType}
            onChangeReportType={onChangeReportType}
            onDownload={onDownload}
            downloadLoading={downloadLoading}
          />
          <Box
            color={colors.noirBlur}
            display="inline-block"
            marginTop={`${theme.spacing(4)}px`}
          >
            {t('NO_BILLING_HISTORY')}{' '}
            <a
              href={`tel:${t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}`}
              style={{
                textDecoration: 'none',
                color: colors.noirBlur,
                fontWeight: 'bold',
              }}
            >
              {t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}
            </a>{' '}
            {t('ASSISTANCE_HOURS_VIEW_AND_DOWNLOAD_BILLS')}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <PGEButton
              onClick={(e: any) => {
                e.preventDefault();
                setDateRange(null);
              }}
            >
              {t('OK')}
            </PGEButton>
          </Box>
        </Paper>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('VIEW_AND_DOWNLOAD_BILLS')}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<ViewBillLeftPane />}>
        <Typography variant="h1" style={{ paddingBottom: theme.spacing(4) }}>
          {t('VIEW_AND_DOWNLOAD_BILLS')}
        </Typography>
        <AccountGroupDropdown />
        {renderResults(loading)}
      </Page2ColumnLayout>
    </>
  );
};

export default ViewAndDownloadBills;
