import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { EmailTextToggleControl } from './EmailTextToggle';
import { useStyles } from './styles';
import { Model, AlertOptions, AlertState } from './types';
import { FormState } from '../../../hooks/useFormState.types';
import { useTranslation } from '../../../hooks/useTranslation';
import NumericTextField from '../../numeric-text-field';

type Props = {
  alerts: Array<AlertOptions>;
  form: FormState<Model>;
};

function sanitizeNumber(num: string) {
  if (num.startsWith('$')) {
    return num.slice(1).replace(/,/g, '');
  }
  return num;
}

export default function ManageAlertsControls({ alerts, form }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.subCard}>
      <Typography variant={'h2'} className={classes.colorTextPrimary}>
        {t('YOUR_ALERTS')}
      </Typography>
      <div className={classes.gridContainer}>
        <div className={classes.row}>
          <Box>
            <Typography variant={'body1'}>{t('DESCRIPTION')}</Typography>
          </Box>
          <Box>
            <Typography variant={'body1'} style={{ paddingLeft: 5 }}>
              {t('EMAIL')}
            </Typography>
          </Box>
          <Box>
            <Typography variant={'body1'} style={{ paddingLeft: 7 }}>
              {t('TEXT')}
            </Typography>
          </Box>
        </div>
        {alerts.map(item => {
          const value = form.values[item.key as keyof Model] as AlertState;
          const anyChosen = value.text || value.email;

          return (
            <EmailTextToggleControl
              value={value}
              onChange={newValue =>
                form.setValue(item.key as keyof Model, newValue)
              }
              {...item}
            >
              {item.textFieldKey && anyChosen ? (
                <NumericTextField
                  className={classes.subField}
                  style={{ width: 140 }}
                  maxLength={5}
                  prefix="$"
                  allowDecimal
                  inputProps={{ type: 'number' }}
                  variant={'outlined'}
                  {...form.props(item.textFieldKey as keyof Model)}
                  error={
                    anyChosen &&
                    Boolean(form.errors[item.textFieldKey as keyof Model])
                  }
                  helperText={
                    anyChosen && form.errors[item.textFieldKey as keyof Model]
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    form.setValue(
                      item.textFieldKey as keyof Model,
                      sanitizeNumber(e.target.value),
                    )
                  }
                />
              ) : null}
            </EmailTextToggleControl>
          );
        })}
      </div>
    </div>
  );
}
