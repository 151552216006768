import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import CurrencyTextField from '../../currency-text-field';
import DatePicker from '../../date-picker';
import Ineligible from '../../auto-pay/auto-pay-start/Ineligible';
import useStyles from '../../payment-form/PaymentForm.styles';
import { UsePaymentFormResponse } from '../PaymentForm.types';
import {
  AccountDetail,
  PaymentProfile,
  AccountCustomer,
  PaymentCategory,
} from '../../../__generated__/pge-types';
import { OneTimePayConfigData } from '../oneTimePayment.types';
import { getDefaultOneTimePaymentInfoFormData } from '../oneTimePay.utils';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { usePaymentInfoFormState } from '../../../hooks/useOneTimePayment';
import PGEButton from '../../buttons';
import Tooltip from '../../tooltip';
import {
  ClickAwayListener,
  makeStyles,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from '../../../util/style-utils';
import {
  formatDate,
  getFormattedOneYearDate,
  toCurrencyDisplayFormat,
  toDateStringFullMonthName,
} from '../../../util/format';
import { PaymentProfileInfo } from '../../paymentus/types';
import TextLink from '../../text-link/TextLink';
import moment from 'moment-timezone';
import Divider from '@material-ui/core/Divider';
import { parse } from 'date-fns';
import PaymentProfileOnetimeSplitSelector from '../../paymentus/payment-profile-onetime-split-selector/PaymentProfileOnetimeSplitSelector';
import PaymentAccordian from '../../payment-accordian/PaymentAccordian';
import PaymentLimitsFees from '../../paymentus/payment-limits-fees';
import AddPaymentMethodDropdown from '../../payment-method-type/AddPaymentMethodDropdown';
import PaymentMethodTypeIconList from '../../payment-method-type/PaymentMethodTypeIconList';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { RECONNECT_PAY_OPTIONS } from '../../reconnect-service/reconnectServicePayment.types';
export interface PaymentInfoFormProps extends UsePaymentFormResponse {
  path: string;
  isMobile: boolean;
  customer: AccountCustomer | undefined;
  account: AccountDetail;
  onSubmit: (data: OneTimePayConfigData) => void;
  oneTimePayConfigData: OneTimePayConfigData | null;
  savedProfileList: PaymentProfile[] | undefined;
  paymentMethodCapLimit: number;
  handleEligible: (value: boolean) => void;
  isForTPA?: boolean;
  amountToBePaid?: string;
  calendarEndDate?: Date;
  backButtonRedirect?: string;
  maxPaymentDate?: Date;
  isForReconnect?: boolean;
  fullAmount?: string;
  minimumAmount?: string;
}

const useStylesForToolTip = makeStyles(() => ({
  tooltip: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '3px solid #F3AD3C',
    borderRadius: '5px',
    opacity: 1,
  },
  arrow: {
    color: '#F3AD3C',
  },
}));

const PaymentInfoForm: FunctionComponent<PaymentInfoFormProps> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const classesForToolTip = useStylesForToolTip();
  const PstTime = moment()
    .tz('Canada/Pacific')
    .format('MM/DD/yyyy');
  const today = formatDate(new Date(PstTime), 'MM/dd/yyyy');
  const datePickerEndDate =
    props.isForTPA && props?.calendarEndDate
      ? formatDate(props.calendarEndDate)
      : getFormattedOneYearDate(new Date(PstTime));

  const [nextButton, setNextButton] = useState(false);
  const [eligibility, setEligibility] = useState(true);
  const [oneTimePayConfig, setOneTimePayConfig] = useState<
    OneTimePayConfigData
  >(
    props.oneTimePayConfigData ||
      getDefaultOneTimePaymentInfoFormData(
        props?.account,
        props.savedProfileList,
        props.maxPaymentDate,
      ),
  );

  const [selectedOption, setSelectedOption] = useState<RECONNECT_PAY_OPTIONS>(
    oneTimePayConfig?.selectedReconnectOption!,
  );
  const [reconnectPayAmount, setReconnectPayAmount] = useState<string>(
    oneTimePayConfig?.paymentAmount,
  );

  useEffect(() => {
    if (props.isForReconnect) {
      paymentInfoFormState.values.paymentAmount = String(reconnectPayAmount);

      setNextButton(
        Array.isArray(
          oneTimePayConfig.paymentSelector.selectedPaymentProfileList,
        ) &&
          oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length &&
          Number(paymentInfoFormState.values.paymentAmount) >= 1 &&
          Number(paymentInfoFormState.values.paymentAmount) <=
            Number(props.fullAmount) &&
          Number(paymentInfoFormState.values.paymentAmount) >=
            Number(props.minimumAmount) &&
          !paymentInfoFormState.errors?.paymentAmount &&
          !paymentInfoFormState.errors?.paymentDate
          ? false
          : true,
      );
      setOneTimePayConfig(config => {
        config.paymentAmount = String(reconnectPayAmount);
        return { ...config };
      });
    }
  }, [reconnectPayAmount]);

  const handleOptionChange = (
    optionSelection: RECONNECT_PAY_OPTIONS = 'RECONNECT_PAY_MINIMUM_DUE',
  ) => {
    paymentInfoFormState.reset();
    if (props.isForReconnect) {
      setSelectedOption(optionSelection);
      if (optionSelection === 'RECONNECT_PAY_MINIMUM_DUE') {
        setReconnectPayAmount(props.minimumAmount!);
      }
      if (optionSelection === 'RECONNECT_PAY_FULL_AMOUNT') {
        setReconnectPayAmount(props.fullAmount!);
      }
      if (optionSelection === 'RECONNECT_PAY_OTHER_AMOUNT') {
        setReconnectPayAmount(reconnectPayAmount);
      }
      setOneTimePayConfig(config => {
        config.selectedReconnectOption = optionSelection;
        config.fullAmount = props.fullAmount;
        config.minimumAmount = props.minimumAmount;
        return { ...config };
      });
    }
  };

  const paymentInfoFormState = usePaymentInfoFormState(oneTimePayConfig);

  const { wrapWithLoader } = useWrapWithLoader();

  const isCashOnly = Boolean(props?.account?.paymentEligibility?.isCashOnly);
  const notificationContext = useContext(NotificationsContext);
  const handleNext = wrapWithLoader(
    paymentInfoFormState.submit(async () => {
      try {
        if (eligibility) {
          oneTimePayConfig.paymentAmount =
            paymentInfoFormState.values.paymentAmount;
          oneTimePayConfig.paymentDate =
            paymentInfoFormState.values.paymentDate;
          props.onSubmit(oneTimePayConfig);

          !props.isForReconnect &&
            !props.isForTPA &&
            (await navigate(ROUTES.PAYMENT_VERIFICATION));
        } else {
          props.handleEligible(true);
          window.scrollTo(0, 0);
        }
      } catch (_) {
        // Do nothing, this means, there was a form error
      }
    }),
  );

  const onPaidAmountChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const amountDue = props?.account?.currentCharges?.amountDue || 0.0;
    const payAmount = Number(event.target.value);
    props.isForReconnect ? setReconnectPayAmount(event.target.value) : null;
    const action =
      (amountDue <= 0 && payAmount >= 100) ||
      (amountDue > 0 && payAmount >= amountDue * 2);

    await paymentInfoFormState.onChange(event);

    toggleOpen(action);
  };

  const [isOpen, toggleOpen] = useState(false);

  const onCloseTooltip = () => {
    toggleOpen(false);
  };

  const isNonBillable = Boolean(
    props?.account?.paymentEligibility?.isNonBillableNoBalance,
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.isForReconnect) {
      handleOptionChange(oneTimePayConfig?.selectedReconnectOption);
    }
  }, []);

  const splitCondition = oneTimePayConfig.splitAmounts.filter(
    item => item.error === true,
  );
  useEffect(() => {
    if (
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length ===
        props.paymentMethodCapLimit &&
      oneTimePayConfig.splitPayStatus
    ) {
      setNextButton(splitCondition.length ? true : false);
    }
  }, [splitCondition.length >= 1]);

  useEffect(() => {
    let nextButtonDisableStatus = true;
    if (
      oneTimePayConfig.splitPayStatus &&
      !Boolean(oneTimePayConfig.selectedAccordian)
    ) {
      if (
        oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length ===
          props.paymentMethodCapLimit &&
        Number(paymentInfoFormState.values.paymentAmount) >= 2
      ) {
        nextButtonDisableStatus = oneTimePayConfig.paymentSelector.selectedPaymentProfileList.some(
          profile => profile.error === true,
        )
          ? true
          : false;
      }
    } else {
      if (
        oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length &&
        Number(paymentInfoFormState.values.paymentAmount) >= 1
      ) {
        nextButtonDisableStatus = false;
      }
    }
    setNextButton(
      !paymentInfoFormState.errors?.paymentAmount &&
        !paymentInfoFormState.errors?.paymentDate
        ? nextButtonDisableStatus
        : true,
    );
  }, [oneTimePayConfig.splitPayStatus, paymentInfoFormState.errors]);

  useEffect(() => {
    const selectedProfilesArray =
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList;
    let nextButtonDisableStatus = true;
    if (
      Array.isArray(selectedProfilesArray) &&
      selectedProfilesArray.length &&
      !Boolean(oneTimePayConfig.selectedAccordian)
    ) {
      if (oneTimePayConfig.splitPayStatus) {
        if (
          selectedProfilesArray.length === props.paymentMethodCapLimit &&
          Number(paymentInfoFormState.values.paymentAmount) >= 2
        ) {
          nextButtonDisableStatus = selectedProfilesArray.some(
            profile => profile.error === true,
          )
            ? true
            : false;
        }
      } else {
        nextButtonDisableStatus = selectedProfilesArray.some(
          p => p.profile.token === '',
        )
          ? true
          : selectedProfilesArray.some(profile => profile.error === true)
          ? true
          : false;
        if (props.isForReconnect && paymentInfoFormState.values.paymentAmount) {
          nextButtonDisableStatus =
            Number(paymentInfoFormState.values.paymentAmount) >= 1 &&
            Number(paymentInfoFormState.values.paymentAmount) <=
              Number(props.fullAmount) &&
            Number(paymentInfoFormState.values.paymentAmount) >=
              Number(props.minimumAmount)
              ? false
              : true;
        }
      }
      setEligibility(
        selectedProfilesArray.some(
          p => p.profile.type === 'CHQ' || p.profile.type === 'SAV',
        ) && isCashOnly
          ? false
          : true,
      );
    } else {
      if (
        Array.isArray(selectedProfilesArray) &&
        selectedProfilesArray.length === 0
      ) {
        nextButtonDisableStatus = true;
      } else {
        nextButtonDisableStatus =
          Number(paymentInfoFormState.values.paymentAmount) >= 1 ? false : true;
      }
    }
    setNextButton(
      !paymentInfoFormState.errors?.paymentAmount &&
        !paymentInfoFormState.errors?.paymentDate
        ? nextButtonDisableStatus
        : true,
    );
  }, [oneTimePayConfig.paymentSelector.selectedPaymentProfileList]);

  const deletePaymentProfileFromList = (deletedProfile: PaymentProfileInfo) => {
    window.scrollTo(0, 0);
    setOneTimePayConfig(config => {
      if (Array.isArray(config.paymentSelector.selectedPaymentProfileList)) {
        config.paymentSelector.selectedPaymentProfileList.some(
          profile => profile.profile.token === deletedProfile.profile.token,
        )
          ? (config.paymentSelector.selectedPaymentProfileList = config.paymentSelector.selectedPaymentProfileList.filter(
              ({ profile }) => profile.token !== deletedProfile.profile.token,
            ))
          : null;
      }
      if (Array.isArray(config.paymentSelector.savedProfileList)) {
        config.paymentSelector.savedProfileList = config.paymentSelector.savedProfileList.filter(
          p => p.token !== deletedProfile.profile.token,
        );
      }
      config.initialLoading = false;
      return { ...config };
    });
  };

  const updateSavedPaymentProfiles = (profile: PaymentProfileInfo) => {
    window.scrollTo(0, 0);
    if (profile) {
      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        variant: 'filled',
        message: t('PAYMENT_PROFILE_ADD_SUCCESS_MESSAGE'),
      });
    }
    setOneTimePayConfig(config => {
      if (Array.isArray(config.paymentSelector.savedProfileList)) {
        config.paymentSelector.savedProfileList.splice(
          config.paymentSelector.savedProfileList.length - 1,
          0,
          profile.profile,
        );
      }
      config.initialLoading = false;
      return { ...config };
    });
  };

  if (isNonBillable) {
    return (
      <Ineligible title={t('ONLINE_PAYMENT_UNAVAILABLE')}>
        {richT('ONETIME_PAY_INELIGIBLE_NON_BILLABLE')}
      </Ineligible>
    );
  }

  const convertAmountInTwoParts = (number: string) => {
    const numbersArray = number.split('.');
    const halfAmount = String((Number(numbersArray[0]) / 2).toFixed(2));
    const secondHalf = String(
      (Number(halfAmount) + Number('0.' + numbersArray[1])).toFixed(2),
    );
    return [secondHalf, halfAmount];
  };

  const updateSplitPayAmount = (index: string, value: string) => {
    let isError: boolean = false,
      errorText: string = '';
    if (Number(value) < 1) {
      isError = true;
      errorText = t('SPLITPAY_LESSTHAN_AMOUNT_TEXT');
    }
    if (Number(value) > Number(paymentInfoFormState.values.paymentAmount)) {
      isError = true;
      errorText = t('SPLITPAY_PAYMENT_AMOUNT_IS_HIGHER_THAN_DUE');
    }
    setOneTimePayConfig(config => {
      config.splitAmounts.map((item, i) => {
        if (i === Number(index)) {
          item.value = value;
          item.error = isError;
          item.errorText = errorText;
        } else {
          if (!isError) {
            if (
              Number(
                String(
                  (
                    Number(paymentInfoFormState.values.paymentAmount) -
                    Number(value)
                  ).toFixed(2),
                ),
              ) < 1
            ) {
              item.errorText = t('SPLITPAY_LESSTHAN_AMOUNT_TEXT');
              item.error = true;
              item.value = String(
                (
                  Number(paymentInfoFormState.values.paymentAmount) -
                  Number(value)
                ).toFixed(2),
              );
            } else {
              item.errorText = '';
              item.error = false;
              item.value = String(
                (
                  Number(paymentInfoFormState.values.paymentAmount) -
                  Number(value)
                ).toFixed(2),
              );
            }
          }
        }
      });
      if (
        config.paymentSelector.selectedPaymentProfileList.length ===
        props.paymentMethodCapLimit
      ) {
        config.paymentSelector.selectedPaymentProfileList
          .filter(({ isPrimary }) => isPrimary === true)
          .map(profile => {
            profile.value = config.splitAmounts[0].value!;
            profile.error = config.splitAmounts[0].error!;
            profile.errorText = config.splitAmounts[0].errorText!;
          });
        config.paymentSelector.selectedPaymentProfileList
          .filter(({ isPrimary }) => isPrimary === false)
          .map(profile => {
            profile.value = config.splitAmounts[1].value!;
            profile.error = config.splitAmounts[1].error!;
            profile.errorText = config.splitAmounts[1].errorText!;
          });
      }
      return { ...config };
    });
  };
  const updateSplitPayValue = (splitStatus: boolean) => {
    setOneTimePayConfig(config => {
      config.splitPayStatus = splitStatus;
      if (splitStatus) {
        props.isForReconnect ? (config.splitAmounts = []) : null;
        const amountsArray = convertAmountInTwoParts(
          String(Number(paymentInfoFormState.values.paymentAmount).toFixed(2)),
        );
        amountsArray.map((amount, i) => {
          config.splitAmounts.push({
            value:
              Number(paymentInfoFormState.values.paymentAmount) > 0
                ? amount
                : '0.00',
            error:
              Number(paymentInfoFormState.values.paymentAmount) < 2
                ? true
                : false,
            errorText:
              Number(paymentInfoFormState.values.paymentAmount) < 2
                ? t('SPLITPAY_LESSTHAN_AMOUNT_TEXT')
                : '',
          });
        });
      } else {
        config.splitAmounts = [];
        const removeSecondaryFromArray = config.paymentSelector.selectedPaymentProfileList.filter(
          profile => profile.isPrimary === true,
        );
        if (removeSecondaryFromArray.length) {
          removeSecondaryFromArray.map(p => {
            p.value = paymentInfoFormState.values.paymentAmount;
            p.error = false;
            p.errorText = '';
            p.isPrimary = true;
          });
        }
        config.paymentSelector.selectedPaymentProfileList = removeSecondaryFromArray;
      }
      return { ...config };
    });
  };
  const onAccordianSelection = (index: number) => {
    // window.scrollTo(0, 0);
    if (oneTimePayConfig.selectedAccordian !== index) {
      setOneTimePayConfig(config => {
        config.splitPayStatus = false;
        config.splitAmounts = [];
        if (Boolean(index)) {
          //second accordian selected
          config.paymentSelector.selectedPaymentProfileList = [];
        } else {
          //first accordian selected
          if (config.paymentSelector.savedProfileList!.length) {
            config.paymentSelector.selectedPaymentProfileList = [
              {
                profile: oneTimePayConfig.paymentSelector.savedProfileList!.some(
                  p => p.default === true,
                )
                  ? oneTimePayConfig.paymentSelector.savedProfileList!.filter(
                      p => p.default === true,
                    )[0]
                  : oneTimePayConfig.paymentSelector.savedProfileList![0],
                error: false,
                errorText: '',
                isPrimary: true,
                value: paymentInfoFormState.values.paymentAmount,
              },
            ];
          } else {
            config.paymentSelector.selectedPaymentProfileList = [];
          }
        }
        config.selectedAccordian = index;
        return { ...config };
      });
    }
  };
  const addOrUpdateProps = {
    onNew: (profile: PaymentProfileInfo) => {
      onTempProfileSuccess(profile);
    },
    allowedCategories: [
      PaymentCategory.Dd,
      PaymentCategory.Cc,
      PaymentCategory.Dc,
      PaymentCategory.PaypalAccount,
      PaymentCategory.AmazonPay,
    ],
    isPrimaryPM: false,
    customLabel: t('ENTER_A_PAYMENT_METHOD'),
  };
  //After Add payment method component integrate - TODO
  const onTempProfileSuccess = (profile: PaymentProfileInfo) => {
    if (
      !paymentInfoFormState.errors.paymentAmount &&
      !paymentInfoFormState.errors.paymentDate
    ) {
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList = [];
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList = [
        {
          profile: profile.profile,
          error: false,
          errorText: '',
          value: paymentInfoFormState.values.paymentAmount,
        },
      ];
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleNext();
  };

  return (
    <form noValidate onSubmit={handleNext}>
      <Grid container spacing={3} className={classes.oneTimeContainer}>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
            <Card>
              <CardContent>
                <Grid item xs={12} style={{ marginBottom: '1em' }}>
                  <Typography
                    className={classes.colorTextPrimary}
                    component={'span'}
                    variant={'h2'}
                  >
                    {props.isForTPA
                      ? t('SET_UP_DOWN_PAYMENT')
                      : t('MAKE_A_PAYMENT')}
                  </Typography>
                  <Divider className={classes.margin1} />
                </Grid>
                {props.isForReconnect ? (
                  <>
                    <Grid
                      item
                      container
                      direction={'column'}
                      xs={12}
                      style={{ marginBottom: '1em' }}
                    >
                      <Typography
                        className={classes.colorTextPrimary}
                        component={'span'}
                        variant={'h6'}
                      >
                        {t('SELECT_PAYMENT_AMOUNT')}
                      </Typography>
                    </Grid>
                    <Grid item container direction={'column'} xs={12}>
                      <Typography variant="body2">
                        {t('RECONNECT_SUB_TITLE_TEXT')}
                      </Typography>
                    </Grid>
                    <Grid item container direction={'column'} xs={12}>
                      <RadioGroup
                        value={selectedOption || ''}
                        onChange={e => {
                          handleOptionChange(
                            e.target.value as RECONNECT_PAY_OPTIONS,
                          );
                        }}
                      >
                        <FormControlLabel
                          value={'RECONNECT_PAY_MINIMUM_DUE'}
                          className={classes.formRadioCls}
                          control={
                            <Radio
                              color={'primary'}
                              disabled={oneTimePayConfig.splitPayStatus}
                            />
                          }
                          label={
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography variant="body2">
                                  {t('MIN_OPTION')}
                                </Typography>
                              </Grid>
                              <Grid item style={{ paddingLeft: '0px' }}>
                                <Typography
                                  variant="body2"
                                  className={classes.radioOptionAmountText}
                                >
                                  $
                                  {toCurrencyDisplayFormat(
                                    Number(props.minimumAmount) || 0,
                                    true,
                                    'CR',
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                        <FormControlLabel
                          value={'RECONNECT_PAY_FULL_AMOUNT'}
                          className={classes.formRadioCls}
                          control={
                            <Radio
                              color={'primary'}
                              disabled={oneTimePayConfig.splitPayStatus}
                            />
                          }
                          label={
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography variant="body2">
                                  {t('FULL_OPTION')}
                                </Typography>
                              </Grid>
                              <Grid item style={{ paddingLeft: '0px' }}>
                                <Typography
                                  variant="body2"
                                  className={classes.radioOptionAmountText}
                                >
                                  $
                                  {toCurrencyDisplayFormat(
                                    Number(props.fullAmount) || 0,
                                    true,
                                    'CR',
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                        <Grid
                          container
                          direction={isMobile() ? 'column' : 'row'}
                          spacing={1}
                        >
                          <Grid item xs={isMobile() ? 12 : 3}>
                            <FormControlLabel
                              className={classes.formRadioCls}
                              style={{ fontSize: '1em', marginRight: '0px' }}
                              value={'RECONNECT_PAY_OTHER_AMOUNT'}
                              control={
                                <Radio
                                  color={'primary'}
                                  disabled={oneTimePayConfig.splitPayStatus}
                                />
                              }
                              label={
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Typography variant="body2">
                                      {t('OTHER_OPTION_TEXT_PART_1')}
                                    </Typography>
                                  </Grid>
                                  <Grid item style={{ paddingLeft: '0px' }}>
                                    <Typography
                                      variant="body2"
                                      className={classes.radioOptionAmountText}
                                    >
                                      {t('OTHER_OPTION_TEXT_PART_2')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={isMobile() ? 10 : 3}
                            style={isMobile() ? { marginLeft: '30px' } : {}}
                          >
                            <CurrencyTextField
                              className={classes.currencyTextField}
                              style={{ margin: '0px' }}
                              label={''}
                              name={'paymentAmount'}
                              defaultValue={oneTimePayConfig.paymentAmount}
                              {...paymentInfoFormState.props('paymentAmount')}
                              onChange={onPaidAmountChange}
                              disabled={
                                oneTimePayConfig.splitPayStatus ||
                                selectedOption ===
                                  'RECONNECT_PAY_MINIMUM_DUE' ||
                                selectedOption === 'RECONNECT_PAY_FULL_AMOUNT'
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={isMobile() ? 12 : 6}
                            style={!isMobile() ? { marginTop: '12px' } : {}}
                          >
                            <Typography variant={'body2'}>
                              {t('OTHER_OPTION_TEXT_PART_3')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </>
                ) : (
                  <Grid className={classes.infoContainer}>
                    <Grid item xs={12} className={classes.listGrid}>
                      <Grid
                        item
                        xs={isMobile() ? 12 : 6}
                        className={classes.labelDisplay}
                      >
                        <Typography className={classes.labelText}>
                          {t('AMOUNT_DUE')}
                        </Typography>
                        <Typography className={classes.labelValue}>
                          $
                          {Math.abs(
                            props?.account?.currentCharges?.amountDue!,
                          ).toFixed(2)}
                          {props?.account?.currentCharges?.amountDue! < 0
                            ? ' CR'
                            : ''}
                        </Typography>
                      </Grid>
                      {props?.account?.currentCharges?.dueDate !== null ? (
                        <Grid
                          item
                          xs={isMobile() ? 12 : 6}
                          className={classes.labelDisplay}
                          style={{
                            marginLeft: isMobile() ? 0 : '3em',
                            marginTop: isMobile() ? '1.5em' : 0,
                          }}
                        >
                          <Typography className={classes.labelText}>
                            {t('DUE_DATE')}
                          </Typography>
                          <Typography className={classes.labelValue}>
                            {props?.account?.currentCharges?.dueDate
                              ? toDateStringFullMonthName(
                                  parse(
                                    props?.account?.currentCharges?.dueDate,
                                    'MM/dd/yyyy',
                                    new Date(),
                                  ),
                                )
                              : null}
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={isMobile() ? 12 : 6}
                          style={{
                            marginLeft: isMobile() ? 0 : '3em',
                          }}
                        ></Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} className={classes.listGrid}>
                      <Grid
                        item
                        xs={isMobile() ? 12 : 6}
                        style={{ marginTop: '0.6em' }}
                      >
                        <ClickAwayListener
                          onClickAway={() => toggleOpen(false)}
                        >
                          <Tooltip
                            arrow
                            PopperProps={{
                              disablePortal: true,
                            }}
                            title={
                              <div className={classes.toolTipContent}>
                                <div>
                                  {t('PAYMENT_AMOUNT_GREATER_THEN_DUE')}
                                </div>
                                <div>
                                  <CloseIcon
                                    className={classes.closeIcon}
                                    onClick={onCloseTooltip}
                                  />
                                </div>
                              </div>
                            }
                            disableHoverListener
                            disableTouchListener
                            disableFocusListener
                            classes={classesForToolTip}
                            open={isOpen}
                            placement={isMobile() ? 'bottom' : 'right'}
                            onClose={() => toggleOpen(false)}
                          >
                            <div>
                              {props.isForTPA ? (
                                <CurrencyTextField
                                  className={classes.currencyTextField}
                                  name={'paymentAmount'}
                                  defaultValue={
                                    props.isForTPA
                                      ? props.amountToBePaid
                                      : oneTimePayConfig.paymentAmount
                                  }
                                  {...paymentInfoFormState.props(
                                    'paymentAmount',
                                  )}
                                  onChange={onPaidAmountChange}
                                  disabled={
                                    oneTimePayConfig.splitPayStatus ||
                                    props.isForTPA
                                  }
                                  value={props.amountToBePaid} //TODO:Test for pay now
                                />
                              ) : (
                                <CurrencyTextField
                                  className={classes.currencyTextField}
                                  name={'paymentAmount'}
                                  defaultValue={
                                    props.isForTPA
                                      ? props.amountToBePaid
                                      : oneTimePayConfig.paymentAmount
                                  }
                                  {...paymentInfoFormState.props(
                                    'paymentAmount',
                                  )}
                                  onChange={onPaidAmountChange}
                                  disabled={
                                    oneTimePayConfig.splitPayStatus ||
                                    props.isForTPA
                                  }
                                />
                              )}
                            </div>
                          </Tooltip>
                        </ClickAwayListener>
                      </Grid>
                      <Grid
                        item
                        xs={isMobile() ? 12 : 6}
                        className={classes.listAlign}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          minDate={today}
                          maxDate={datePickerEndDate}
                          name={'paymentDate'}
                          label={t('PAYMENT_DATE')}
                          variant={'outlined'}
                          {...paymentInfoFormState.props('paymentDate')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Divider className={classes.margin1} />
                <Grid item xs={12} className={classes.paymentWallet}>
                  <Grid
                    container
                    direction="row"
                    justify={'space-between'}
                    className={classes.margin2}
                  >
                    <Grid item>
                      <Box marginBottom={1}>
                        <Typography variant="h2">
                          {' '}
                          {t('HOW_DO_YOU_WANT_TO_PAY')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item justify={'flex-end'}>
                      <PaymentLimitsFees tabIndex={-1} />
                    </Grid>
                  </Grid>
                  <PaymentAccordian
                    onIndexChange={index => {
                      onAccordianSelection(index);
                    }}
                    accordionItems={[
                      {
                        selectedTab:
                          oneTimePayConfig.selectedAccordian === 0
                            ? true
                            : false,
                        tabTitle: t('PAYMENT_METHOD_SELECTOR_TITLE'),
                        tabSubTitle: t('PAYMENT_METHOD_SELECTOR_SUBTITLE'),
                        children: (
                          <PaymentProfileOnetimeSplitSelector
                            paymentAmountByUser={
                              paymentInfoFormState.values.paymentAmount
                            }
                            paymentMethodCapLimit={props.paymentMethodCapLimit}
                            OneTimePayConfig={oneTimePayConfig}
                            selectedPaymentList={
                              oneTimePayConfig.paymentSelector
                                .selectedPaymentProfileList
                            }
                            splitPayLabelClick={splitStatus => {
                              updateSplitPayValue(splitStatus);
                            }}
                            onAmountChange={(index, value) => {
                              updateSplitPayAmount(index, value);
                            }}
                            PaymentMethodChange={profiles => {
                              setOneTimePayConfig(config => ({
                                ...config,
                                paymentSelector: {
                                  ...config.paymentSelector,
                                  selectedPaymentProfileList: profiles,
                                },
                              }));
                            }}
                            AddNewPaymentProfile={profile => {
                              updateSavedPaymentProfiles(profile);
                            }}
                            DeletePaymentProfile={profile => {
                              deletePaymentProfileFromList(profile);
                            }}
                          />
                        ),
                      },
                      {
                        selectedTab:
                          oneTimePayConfig.selectedAccordian === 1
                            ? true
                            : false,
                        tabTitle: t('PAYMENT_WITHOUT_SAVING_TITLE'),
                        tabSubTitle: t('PAYMENT_WITHOUT_SAVING_SUBTITLE'),
                        children: (
                          <>
                            <Grid container item spacing={1} direction="column">
                              <Grid item container>
                                <AddPaymentMethodDropdown
                                  {...addOrUpdateProps}
                                />
                              </Grid>
                              <Grid item>
                                <Grid container direction="row">
                                  <PaymentMethodTypeIconList
                                    labelComponent={
                                      <Typography>
                                        {' '}
                                        + {t('WE_ACCEPT')}
                                      </Typography>
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ),
                      },
                    ]}
                  ></PaymentAccordian>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid
          container
          className={classes.actionArea}
          direction={isMobile() ? 'column-reverse' : 'row'}
          justify={props.isForTPA ? 'flex-end' : 'space-between'}
          style={{ paddingRight: '1em' }}
        >
          <Grid item md="auto">
            <Box className={classes.cancelLink}>
              {!props.isForTPA ? (
                props.isForReconnect ? (
                  <TextLink
                    onClick={(e: any) => {
                      e.preventDefault();
                      return navigate(ROUTES.RECONNECT_SERVICE_START);
                    }}
                  >
                    {t('BACK')}
                  </TextLink>
                ) : (
                  <TextLink
                    style={{ textDecoration: 'none' }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      return navigate(ROUTES.ACCOUNT);
                    }}
                  >
                    {t('CANCEL')}
                  </TextLink>
                )
              ) : (
                <TextLink
                  onClick={(e: any) => {
                    e.preventDefault();
                    return navigate(
                      props.backButtonRedirect
                        ? props.backButtonRedirect
                        : ROUTES.NEED_MORE_TIME_TO_PAY,
                    );
                  }}
                >
                  {t('BACK')}
                </TextLink>
              )}
            </Box>
          </Grid>
          <Grid item md="auto">
            <Box>
              {!Boolean(oneTimePayConfig.selectedAccordian) && (
                <PGEButton
                  type={'submit'}
                  variant="contained"
                  color="primary"
                  data-testid="change-password-button"
                  disabled={nextButton}
                  size="large"
                >
                  {props.isForTPA ? t('TPA_STEPPER_LABEL_4') : t('NEXT')}
                </PGEButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentInfoForm;
