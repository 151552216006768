import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { navigate } from '@reach/router';
import React from 'react';
import { parse } from 'date-fns';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import {
  OneTimePayConfigData,
  OneTimePayConfirmationResponse,
} from '../oneTimePayment.types';
import { isWithInThreeDays } from '../oneTimePay.utils';
import Alerts from '../../alerts';
import PGEButton from '../../buttons';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import {
  toCurrencyString,
  toDateStringFullMonthName,
  truncateWithEllipses,
} from '../../../util/format';
import { isMobile } from '../../../util/style-utils';
import useStyles from '../payment-confirmation-form/PaymentConfirmation.styles';
import PaymentProfileIcon from '../../paymentus/payment-profile-icon/PaymentProfileIcon';
import useGTMUtilNmttp from '../../need-more-time-to-pay/gtm/useGTMUtilNmttp';
import { getPaymentMethodLabel } from '../../../hooks/usePaymentus';
import {
  PaymentCategory,
  PaymentConfirmation,
  PaymentProfile,
} from '../../../__generated__/pge-types';
import { DownloadAndPrint } from '../../download-print/DownloadAndPrint';

type Props = {
  path?: string;
  oneTimePayConfirmationData: OneTimePayConfirmationResponse;
  oneTimePayConfig: OneTimePayConfigData;
  paymentMethodCapLimit: number;
  redirectionURL?: string;
  isForReconnect?: boolean;
  printContainerId: string;
};

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    color: {
      color: theme.palette.success.main,
    },
  }),
);

export default function PaymentConfirmationForm({
  oneTimePayConfirmationData,
  oneTimePayConfig,
  paymentMethodCapLimit,
  redirectionURL,
  isForReconnect,
  printContainerId,
}: Props) {
  const { richT, t } = useTranslation();
  const classes = useStyles();
  const styles = useCardStyles();
  const { customer } = useAccountCustomer();
  const {
    gtm_PaymentConfirmationForm_HandleOnTryAgainClick,
    gtm_PaymentConfirmationForm_HandleDoneMouseDown,
  } = useGTMUtilNmttp();
  const paymentDateIsWithInThreeDays = isWithInThreeDays(
    oneTimePayConfig.paymentDate,
  );
  const selectedProfileList =
    oneTimePayConfig.paymentSelector.selectedPaymentProfileList;

  const getFormattedPaymentAmount = (paymentAmount: string) => {
    return paymentAmount
      ? `$${toCurrencyString(parseFloat(paymentAmount), false)}`
      : '';
  };
  const successProfiles = oneTimePayConfirmationData.paymentConfirmation.filter(
    p => p.isSuccess === true,
  );
  const navigateToMakePayment = () => {
    // gtm: only register NMTTP/TPA data if it's coming from TPA flow...
    redirectionURL === ROUTES.TPA_MAKE_PAYMENT
      ? gtm_PaymentConfirmationForm_HandleOnTryAgainClick()
      : null;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    isForReconnect
      ? navigate(ROUTES.RECONNECT_SERVICE_START)
      : navigate(redirectionURL || ROUTES.PAYMENT);
  };

  const handleDoneMouseDown = () => {
    gtm_PaymentConfirmationForm_HandleDoneMouseDown();

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.ACCOUNT);
  };

  const getMethodDisplayLabel = (paymentConfirmation: PaymentConfirmation) =>
    getPaymentMethodLabel(
      paymentConfirmation.cardType
        ? paymentConfirmation.paymentMethod === 'DC'
          ? paymentConfirmation.cardType + '_DEBIT'
          : paymentConfirmation.cardType
        : paymentConfirmation.paymentMethod || '',
      true,
    );

  const getMaskedAccountDisplayLabel = (
    paymentConfirmation: PaymentConfirmation,
    paymentProfile: PaymentProfile,
  ) => {
    return [
      PaymentCategory.PaypalAccount,
      PaymentCategory.PaypalCredit,
      PaymentCategory.AmazonPay,
    ]
      .map(p => p.toString())
      .includes(paymentConfirmation.cardType)
      ? paymentProfile.cardHolderName
        ? truncateWithEllipses(paymentConfirmation.digitalWalletEmail, 7)
        : t('GUEST_PAY_AUTHORIZED')
      : '******' + paymentConfirmation.lastFourDigitsCardNumber;
  };

  if (
    oneTimePayConfirmationData.paymentConfirmation.some(
      p => p.isSuccess === false,
    )
  ) {
    return (
      <>
        <Grid container className={classes.marginTop1}>
          <Grid item xs={12} className={classes.marginBot1}>
            <Typography
              className={classes.colorTextPrimary}
              component={'span'}
              variant={'h2'}
            >
              {t('PAYMENT_CONFIRMATION')}
            </Typography>
          </Grid>
          <Paper component={'main'} className={classes.mainContainer}>
            <Card>
              <CardContent>
                <Grid item xs={12} className={classes.listGrid}>
                  <Grid item xs={isMobile() ? 12 : 3}>
                    <Typography className={classes.colorTextPrimary}>
                      {t('ONETIMEPAY_PAYMENT_AMOUNT')}
                    </Typography>
                  </Grid>
                  <Grid item xs={isMobile() ? 12 : 8}>
                    <Typography
                      className={classes.colorTextPrimary}
                      variant={'h2'}
                    >
                      {successProfiles.length
                        ? getFormattedPaymentAmount(
                            String(successProfiles[0].paymentAmount),
                          )
                        : '$0.00'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12} className={classes.listGrid}>
                  <Grid item xs={isMobile() ? 12 : 3}>
                    <Typography className={classes.colorTextPrimary}>
                      {t('ONETIMEPAY_PAY_ON')}
                    </Typography>
                  </Grid>
                  <Grid item xs={isMobile() ? 12 : 8}>
                    <Typography
                      className={classes.colorTextPrimary}
                      variant={'h2'}
                    >
                      {oneTimePayConfig.paymentDate
                        ? toDateStringFullMonthName(
                            parse(
                              oneTimePayConfig.paymentDate,
                              'MM/dd/yyyy',
                              new Date(),
                            ),
                          )
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
          {oneTimePayConfirmationData.paymentConfirmation.map(
            (paymentProfile, index) => {
              return (
                <Paper
                  component={'main'}
                  className={classes.paperContainer}
                  key={index}
                >
                  <Card style={{ boxShadow: 'none' }}>
                    <CardContent
                      className={classes.paymentAlertBox}
                      style={{ padding: 0 }}
                    >
                      <Alerts
                        disableColorOverride={true}
                        isOpen
                        severity={
                          paymentProfile.isSuccess ? 'success' : 'error'
                        }
                        variant={'outlined'}
                        message={
                          <>
                            <Grid
                              item
                              xs={12}
                              className={classes.paymentPartialContainer}
                            >
                              <Grid item xs={isMobile() ? 12 : 3}>
                                <Typography
                                  className={`${classes.colorTextPrimary} ${classes.noWrap}`}
                                >
                                  {isMobile() ? (
                                    <>
                                      {t('ONETIMEPAY_PAYMENT_AMOUNT')}
                                      <span
                                        className={classes.paymentAmountCustom}
                                      >
                                        {' '}
                                        $
                                        {paymentProfile.paymentAmount.toFixed(
                                          2,
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    t('ONETIMEPAY_PAYMENT_AMOUNT')
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                className={
                                  oneTimePayConfirmationData.paymentConfirmation
                                    .length === paymentMethodCapLimit
                                    ? classes.paymentPartialSuccess
                                    : classes.paymentSingleFailure
                                }
                              >
                                {!isMobile() ? (
                                  <Typography
                                    className={classes.colorTextPrimary}
                                    variant={'h2'}
                                  >
                                    ${paymentProfile.paymentAmount.toFixed(2)}
                                  </Typography>
                                ) : null}

                                <Typography
                                  className={`${classes.colorTextPrimary} ${classes.noWrap}`}
                                  variant={'body1'}
                                  component="span"
                                  style={{
                                    marginLeft: isMobile() ? 0 : '1.5em',
                                  }}
                                >
                                  {t('ONETIMEPAY_USING_LABEL') +
                                    ' ' +
                                    getMethodDisplayLabel(paymentProfile) +
                                    ' '}
                                  <Typography
                                    component="span"
                                    className={classes.paymentIconPartial}
                                    variant={'body2'}
                                  >
                                    <PaymentProfileIcon
                                      type={
                                        paymentProfile.cardType
                                          ? paymentProfile.cardType ===
                                            'GOOGLE_PAY'
                                            ? PaymentCategory.Gp
                                            : paymentProfile.cardType ===
                                              'APPLE_PAY'
                                            ? PaymentCategory.Ap
                                            : paymentProfile.cardType
                                          : paymentProfile.paymentMethod
                                      }
                                    ></PaymentProfileIcon>
                                    <Typography
                                      variant={'h2'}
                                      component="span"
                                      style={{
                                        verticalAlign: 'middle',
                                        marginLeft: '0.2em',
                                      }}
                                    >
                                      {getMaskedAccountDisplayLabel(
                                        paymentProfile,
                                        oneTimePayConfig.paymentSelector
                                          .selectedPaymentProfileList[index]
                                          .profile,
                                      )}
                                    </Typography>
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                            {paymentProfile.isSuccess ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Typography
                                    className={classes.confirmationNumber}
                                    variant={'body1'}
                                  >
                                    {t('ONETIMEPAY_YOUR_CONFIRMATION_NEW')}
                                  </Typography>
                                  <Typography
                                    className={classes.colorTextPrimary}
                                    style={{ marginLeft: '.5em' }}
                                  >
                                    <strong>
                                      {paymentProfile.confirmationId}
                                    </strong>
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  className={classes.receiptContainer}
                                >
                                  <Typography
                                    className={classes.colorTextPrimary}
                                  >
                                    {isMobile() ? (
                                      <>
                                        {t('RECEIPT_OF_PAYMENT_NEW')}
                                        <span
                                          className={classes.marginLeftSpace}
                                        >
                                          <strong>{customer?.email}</strong>
                                        </span>
                                      </>
                                    ) : (
                                      t('RECEIPT_OF_PAYMENT_NEW')
                                    )}
                                  </Typography>
                                  {!isMobile() ? (
                                    <Typography
                                      className={classes.marginLeftSpace}
                                    >
                                      <strong>{customer?.email}</strong>
                                    </Typography>
                                  ) : null}
                                </Grid>
                                {paymentDateIsWithInThreeDays && (
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.marginTop1}
                                  >
                                    <Typography
                                      className={classes.colorTextPrimary}
                                    >
                                      {t('PAYMENT_DEDUCTION_SLUG')}.
                                    </Typography>
                                  </Grid>
                                )}
                                {!paymentDateIsWithInThreeDays && (
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.marginTop1}
                                  >
                                    <Typography
                                      className={classes.colorTextPrimary}
                                      component={'span'}
                                    >
                                      {richT(
                                        'ONETIMEPAY_CAN_CANCEL_PAYMENT_MESSAGE',
                                        {
                                          CALL_FOR_ASSISTANCE_NUMBER: t(
                                            'CALL_FOR_ASSISTANCE_NUMBER',
                                          ),
                                        },
                                      )}
                                    </Typography>
                                  </Grid>
                                )}
                                {oneTimePayConfirmationData.paymentConfirmation.some(
                                  p =>
                                    p?.pendingDisconnect
                                      ?.isPendingDisconnect === true,
                                ) ? (
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.marginTop1}
                                  >
                                    <Typography
                                      className={classes.colorTextDanger}
                                      component={'span'}
                                    >
                                      {richT(
                                        'MULTIPAY_PENDING_DISCONNECT_WARNING',
                                        {
                                          ASSISTANCE_NUMBER: t(
                                            'CALL_FOR_ASSISTANCE_NUMBER',
                                          ),
                                        },
                                      )}
                                    </Typography>
                                  </Grid>
                                ) : null}
                              </>
                            ) : (
                              paymentProfile?.errorMessage && (
                                <Grid item xs={12}>
                                  <Typography
                                    className={classes.colorTextPrimary}
                                    component={'span'}
                                  >
                                    {t(paymentProfile?.errorMessage)}
                                  </Typography>
                                </Grid>
                              )
                            )}
                          </>
                        }
                      />
                    </CardContent>
                  </Card>
                </Paper>
              );
            },
          )}
          <Grid
            item
            xs={12}
            className={classes.buttonContainer}
            style={{ paddingRight: 0 }}
            data-noprint
          >
            <PGEButton
              size={'large'}
              onMouseDown={navigateToMakePayment}
              variant={'outlined'}
              color={'secondary'}
              className={classes.tryAgain}
            >
              {t('TRY_AGAIN')}
            </PGEButton>
            <PGEButton
              size={'large'}
              onMouseDown={() => navigate(ROUTES.ACCOUNT)}
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={classes.submit}
            >
              {t('DONE')}
            </PGEButton>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Paper component={'main'} className={styles.border}>
          <Box style={{ padding: '1em' }}>
            <Grid container spacing={4}>
              <Grid item container direction="row" justify="space-between">
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Typography
                        variant={'h2'}
                        className={styles.color}
                        style={{ paddingTop: '0.2em' }}
                      >
                        <CheckCircleIcon fontSize="inherit" />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'h2'}>
                        {t('PAYMENT_CONFIRMATION')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <DownloadAndPrint
                    containerId={printContainerId}
                    excludeSelectors={['[data-noprint]']}
                    minHeight={
                      isForReconnect || redirectionURL ? 100 : undefined
                    }
                  ></DownloadAndPrint>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={4}
                  style={{ paddingLeft: '0.5em' }}
                >
                  <Grid container item>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid item xs={12} className={classes.listGrid}>
                          <Grid item xs={isMobile() ? 12 : 3}>
                            <Typography className={classes.colorTextPrimary}>
                              {t('ONETIMEPAY_PAYMENT_AMOUNT')}
                            </Typography>
                          </Grid>
                          <Grid item xs={isMobile() ? 12 : 8}>
                            <Typography
                              className={classes.colorTextPrimary}
                              variant={'h2'}
                            >
                              {getFormattedPaymentAmount(
                                oneTimePayConfig?.paymentAmount,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider />

                        <Grid item xs={12} className={classes.listGrid}>
                          <Grid item md={3} xs={12}>
                            <Typography className={classes.colorTextPrimary}>
                              {t('ONETIMEPAY_PAY_ON')}
                            </Typography>
                          </Grid>
                          <Grid item md={9} xs={12}>
                            <Typography
                              className={classes.colorTextPrimary}
                              variant={'h2'}
                            >
                              {oneTimePayConfig.paymentDate
                                ? toDateStringFullMonthName(
                                    parse(
                                      oneTimePayConfig.paymentDate,
                                      'MM/dd/yyyy',
                                      new Date(),
                                    ),
                                  )
                                : null}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider />

                        {oneTimePayConfirmationData.paymentConfirmation.map(
                          (paymentProfile, index) => {
                            return (
                              <div key={index}>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.paymentMethodContainer}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={
                                      selectedProfileList.length ===
                                      paymentMethodCapLimit
                                        ? 3
                                        : 6
                                    }
                                  >
                                    <Typography
                                      className={`${classes.colorTextPrimary} ${classes.noWrap}`}
                                    >
                                      {selectedProfileList.length ===
                                      paymentMethodCapLimit ? (
                                        isMobile() ? (
                                          <>
                                            {t('ONETIMEPAY_PAYMENT_AMOUNT')}
                                            <span
                                              className={
                                                classes.paymentAmountCustom
                                              }
                                            >
                                              {' '}
                                              $
                                              {paymentProfile.paymentAmount.toFixed(
                                                2,
                                              )}
                                            </span>
                                          </>
                                        ) : (
                                          t('ONETIMEPAY_PAYMENT_AMOUNT')
                                        )
                                      ) : (
                                        t('ONETIMEPAY_PAID_WITH') +
                                        ' ' +
                                        getMethodDisplayLabel(paymentProfile)
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={
                                      selectedProfileList.length ===
                                      paymentMethodCapLimit
                                        ? 9
                                        : 6
                                    }
                                    xs={12}
                                    className={
                                      classes.paymentMethodTextContainer
                                    }
                                  >
                                    {selectedProfileList.length ===
                                      paymentMethodCapLimit && !isMobile() ? (
                                      <Typography
                                        className={classes.colorTextPrimary}
                                        variant={'h2'}
                                      >
                                        $
                                        {paymentProfile.paymentAmount.toFixed(
                                          2,
                                        )}
                                      </Typography>
                                    ) : null}

                                    {selectedProfileList.length ===
                                    paymentMethodCapLimit ? (
                                      <div style={{ width: '100%' }}>
                                        <Typography
                                          variant={'body1'}
                                          className={`${classes.paymentmethodLabel} ${classes.noWrap}`}
                                          component="span"
                                        >
                                          {t('ONETIMEPAY_USING_LABEL') +
                                            ' ' +
                                            getMethodDisplayLabel(
                                              paymentProfile,
                                            )}

                                          <Typography
                                            className={
                                              classes.paymentMethodIcon
                                            }
                                            style={{
                                              width: isMobile()
                                                ? '100%'
                                                : '90%',
                                              marginLeft: isMobile()
                                                ? 0
                                                : selectedProfileList.length ===
                                                  paymentMethodCapLimit
                                                ? '0.5em'
                                                : 0,
                                            }}
                                            component="span"
                                            variant={'body2'}
                                          >
                                            <PaymentProfileIcon
                                              type={
                                                paymentProfile.cardType
                                                  ? paymentProfile.cardType ===
                                                    'GOOGLE_PAY'
                                                    ? PaymentCategory.Gp
                                                    : paymentProfile.cardType ===
                                                      'APPLE_PAY'
                                                    ? PaymentCategory.Ap
                                                    : paymentProfile.cardType
                                                  : paymentProfile.paymentMethod
                                              }
                                            ></PaymentProfileIcon>
                                            <Typography
                                              variant={'h2'}
                                              component="span"
                                              style={{
                                                verticalAlign: 'middle',
                                                marginLeft: '0.2em',
                                              }}
                                            >
                                              {getMaskedAccountDisplayLabel(
                                                paymentProfile,
                                                oneTimePayConfig.paymentSelector
                                                  .selectedPaymentProfileList[
                                                  index
                                                ].profile,
                                              )}
                                            </Typography>
                                          </Typography>
                                        </Typography>
                                      </div>
                                    ) : (
                                      <div style={{ width: '100%' }}>
                                        <Typography
                                          className={classes.paymentMethodIcon}
                                          style={{
                                            width: isMobile() ? '100%' : '90%',
                                            marginLeft: isMobile()
                                              ? 0
                                              : selectedProfileList.length ===
                                                paymentMethodCapLimit
                                              ? '0.5em'
                                              : 0,
                                          }}
                                          component="span"
                                          variant={'body2'}
                                        >
                                          <PaymentProfileIcon
                                            type={
                                              paymentProfile.cardType
                                                ? paymentProfile.cardType ===
                                                  'GOOGLE_PAY'
                                                  ? PaymentCategory.Gp
                                                  : paymentProfile.cardType ===
                                                    'APPLE_PAY'
                                                  ? PaymentCategory.Ap
                                                  : paymentProfile.cardType
                                                : paymentProfile.paymentMethod
                                            }
                                          ></PaymentProfileIcon>
                                          <Typography
                                            variant={'h2'}
                                            component="span"
                                            style={{
                                              verticalAlign: 'middle',
                                              marginLeft: '0.2em',
                                            }}
                                          >
                                            {getMaskedAccountDisplayLabel(
                                              paymentProfile,
                                              oneTimePayConfig.paymentSelector
                                                .selectedPaymentProfileList[
                                                index
                                              ].profile,
                                            )}
                                          </Typography>
                                        </Typography>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.paymentMethodTextContainer}
                                >
                                  <Typography
                                    className={classes.confirmationNumber}
                                    variant={'body1'}
                                  >
                                    {t('ONETIMEPAY_YOUR_CONFIRMATION_NEW')}
                                  </Typography>
                                  <Typography
                                    className={classes.confirmationValue}
                                  >
                                    <strong>
                                      {paymentProfile.confirmationId}
                                    </strong>
                                  </Typography>
                                </Grid>
                              </div>
                            );
                          },
                        )}
                        {selectedProfileList.length ===
                        paymentMethodCapLimit ? (
                          <>
                            <div className={classes.padding1} />
                            <Divider />
                          </>
                        ) : null}
                        <Grid item xs={12} className={classes.receiptContainer}>
                          <Typography className={classes.colorTextPrimary}>
                            {isMobile() ? (
                              <>
                                {t('RECEIPT_OF_PAYMENT_NEW')}
                                <span className={classes.marginLeftSpace}>
                                  <strong>{customer?.email}</strong>
                                </span>
                              </>
                            ) : (
                              t('RECEIPT_OF_PAYMENT_NEW')
                            )}
                          </Typography>
                          {!isMobile() ? (
                            <Typography className={classes.marginLeftSpace}>
                              <strong>{customer?.email}</strong>
                            </Typography>
                          ) : null}
                        </Grid>
                        {paymentDateIsWithInThreeDays && (
                          <Grid item xs={12} className={classes.marginTop1}>
                            <Typography className={classes.colorTextPrimary}>
                              {t('PAYMENT_DEDUCTION_SLUG')}.
                            </Typography>
                          </Grid>
                        )}
                        {!paymentDateIsWithInThreeDays && (
                          <Grid item xs={12} className={classes.marginTop1}>
                            <Typography
                              className={classes.colorTextPrimary}
                              component={'span'}
                            >
                              {richT('ONETIMEPAY_CAN_CANCEL_PAYMENT_MESSAGE', {
                                CALL_FOR_ASSISTANCE_NUMBER: t(
                                  'CALL_FOR_ASSISTANCE_NUMBER',
                                ),
                              })}
                            </Typography>
                          </Grid>
                        )}
                        {oneTimePayConfirmationData.paymentConfirmation.some(
                          p =>
                            p?.pendingDisconnect?.isPendingDisconnect === true,
                        ) ? (
                          <Grid item xs={12} className={classes.marginTop1}>
                            <Typography
                              className={classes.colorTextDanger}
                              component={'span'}
                            >
                              {richT('MULTIPAY_PENDING_DISCONNECT_WARNING', {
                                ASSISTANCE_NUMBER: t(
                                  'CALL_FOR_ASSISTANCE_NUMBER',
                                ),
                              })}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                    {!isForReconnect && (
                      <Grid
                        container
                        className={classes.marginTop1}
                        item
                        xs={12}
                        data-noprint
                      >
                        <Grid
                          item
                          xs={12}
                          className={classes.buttonContainer}
                          data-noprint
                        >
                          <PGEButton
                            data-testid={'pay-bill-button'}
                            size={'large'}
                            onMouseDown={handleDoneMouseDown}
                            type={'submit'}
                            variant={'contained'}
                            color={'primary'}
                            className={classes.submit}
                          >
                            {t('DONE')}
                          </PGEButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {isForReconnect && (
          <Grid
            container
            className={classes.marginTop1}
            item
            xs={12}
            data-noprint
          >
            <Grid item xs={12} className={classes.buttonContainer} data-noprint>
              <PGEButton
                data-testid={'pay-bill-button'}
                size={'large'}
                onMouseDown={handleDoneMouseDown}
                type={'submit'}
                variant={'contained'}
                color={'primary'}
                className={classes.submit}
              >
                {t('DONE')}
              </PGEButton>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}
