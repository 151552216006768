import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '../../../components/buttons';
import { useIsMobile } from '../../../util/style-utils';
import { useStyles } from './styles';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import Alerts from '../../alerts';

type Props = {
  path?: string;
};

export default function ManageAlertsConfirmPhone(_: Props) {
  const { richT, t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();

  return (
    <>
      <Card className={classes.manageAlertsSuccess}>
        <Alerts
          disableColorOverride={true}
          isOpen
          severity={'success'}
          variant={'outlined'}
          title={
            <h2 className={classes.alertTitle}>
              {t('TRANSACTION_SUCCESSFUL')}
            </h2>
          }
          message={
            <Grid container spacing={2}>
              <Grid item>
                <Typography
                  className={classes.colorTextPrimary}
                  component="span"
                >
                  {richT('MANAGE_ALERTS_SUCCESS')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify={isMobile ? 'center' : 'flex-end'}
              >
                <Grid item xs={10} md="auto">
                  <Button
                    color={'secondary'}
                    variant={'contained'}
                    onClick={() => navigate(ROUTES.ACCOUNT)}
                    fullWidth
                  >
                    {t('DONE')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Card>
    </>
  );
}
