import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { navigate } from 'gatsby';
import React from 'react';
import {
  CommPreference,
  NotificationType,
} from '../../../__generated__/pge-types';
import { toSentenceCase } from '../../../util/format';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import Button from '../../buttons';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    alerts: {
      display: 'flex',
      flexDirection: 'column',
    },
    blueIcons: {
      background: theme.palette.primary.main,
    },
    greenIcons: {
      background: theme.palette.success.main,
    },
    alertsButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1em',
    },
  }),
);

interface ManageAlertsContentProps {
  alerts: CommPreference[] | undefined | null | any;
}

export const ManageAlertsContent = (props: ManageAlertsContentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mapAlertDescription = (notificationType: NotificationType): string => {
    switch (notificationType) {
      case NotificationType.Out:
        return t('OUTAGE_NOTIFICATIONS');
      case NotificationType.Webpyrem:
        return t('PAYMENT_REMINDER');
      case NotificationType.Webpyrcv:
        return t('PAYMENT_RECEIVED');
      case NotificationType.Webpda:
        return t('PAST_DUE_ALERT');
      case NotificationType.Webdisc:
        return t('DISCONNECT_NOTICE');
      case NotificationType.Webuse:
        return t('WEEKLY_USAGE');
      case NotificationType.Webexc:
        return t('BILL_MAY_EXCEED');
      default:
        return '';
    }
  };

  return (
    <>
      <Grid className={classes.alerts} item xs={12}>
        <List>
          {(props.alerts || []).map((alert: CommPreference, index: number) => {
            const enabled =
              alert?.deliveryTypeDetails
                ?.find(a => a?.deliveryType === 'EMAIL')
                ?.contactDetails?.some(con => con?.isSelected) ||
              alert?.deliveryTypeDetails
                ?.find(a => a?.deliveryType === 'SMS')
                ?.contactDetails?.some(con => con?.isSelected);
            return (
              <div key={`alert-${index}`}>
                <ListItem>
                  {enabled && (
                    <ListItemIcon>
                      <CheckIcon style={{ color: colors.shoreGreen }} />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    inset={!enabled}
                    primary={
                      alert.notificationType
                        ? toSentenceCase(
                            mapAlertDescription(alert.notificationType),
                          )
                        : ''
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            );
          })}
          <Grid item xs={12} className={classes.alertsButton}>
            <Button onClick={() => navigate(ROUTES.MANAGE_ALERTS)}>
              {t('UPDATE_ALERTS')}
            </Button>
          </Grid>
        </List>
      </Grid>
    </>
  );
};

interface AlertDetailsProps {
  commPreferences: CommPreference[] | undefined | null | any;
  title?: string;
}

const ManageAlerts = (props: AlertDetailsProps) => {
  const classes = useStyles();

  return (
    <>
      <Paper>
        <Card>
          <CardContent>
            {props.title && (
              <Typography variant="h2" className={classes.colorTextPrimary}>
                {props.title}
              </Typography>
            )}
            <ManageAlertsContent alerts={props.commPreferences} />
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default ManageAlerts;
