import React, { Fragment } from 'react';
import {
  Box,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  CardContent,
  Radio,
  RadioGroup,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import TextField from '../text-field';
import TextLink from '../text-link';
import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import useOCPAInfoForm from '../../../src/hooks/useOCPAInfoForm';
import OCPASuccessForm from './OCPASuccessForm';
import AccountDropdown from '../account-dropdown';
import PhoneTextField from '../phone-text-field';
import ZipInputField from '../zip-input-field';
import ProvinceInputField from '../province-input-field';
import {
  statesAndProvincesGrouping,
  isCanadianState,
} from '../../components/utility/state-dropdown';
import Backdrop from '../backdrop';
import PGEButton from '../buttons';
import Dropdown from '../dropdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-input': {
        padding: '15px',
        fontSize: '16px',
      },
      '& .MuiInputLabel-formControl': {
        top: '-5px',
      },
      '& .MuiInputLabel-root': {
        fontSize: '16px',
        top: '-5px',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        fontSize: '16px',
      },
    },
    error: {
      '& .MuiOutlinedInput-input': {
        borderColor: colors.errorRed,
      },
    },
    cancelButton: {
      display: 'flex',
      alignItems: 'center',
      '& a': {
        background: colors.navBarBackground,
        border: '1px solid',
        borderColor: colors.sparkBlue,
        borderRadius: 4,
        padding: 8,
        color: colors.sparkBlue,
        textDecoration: 'none',
        '&:hover': {
          background: colors.navBarBackground,
          borderColor: colors.sparkBlue,
          color: colors.sparkBlue,
          textDecoration: 'none',
        },
      },
    },
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(38),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },
    subHeading: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    accountBox: {
      margin: '16px 0px 32px',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0px 20px',
      },
    },
    text: {
      color: '#20415B',
      fontSize: 16,
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    accordHeading: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.125rem',
      color: colors.sparkBlue,
    },
    submit: {
      color: colors.navBarBackground,
      borderColor: colors.orange,
      backgroundColor: colors.orange,
      '&:hover': {
        backgroundColor: colors.orange,
        borderColor: colors.orange,
        cursor: 'pointer',
      },
    },
    paperBlue: {
      padding: '20px',
      margin: '20px 0px',
      background: colors.navBarHoverBackground,
    },
    whiteBox: {
      padding: '15px',
      margin: '20px 0px',
      background: colors.navBarBackground,
    },
    radioLabel: { fontSize: '16px', fontWeight: 'bold' },
    discTrue: { margin: '0px 15px' },
    discFalse: {
      margin: '0px 15px',
      '& p': { fontWeight: 500, color: colors.errorRed },
    },
    errorMessage: {
      margin: '0 14px',
    },
    optOutHeading: {
      fontSize: '1.125rem',
      display: 'flex',
      color: colors.sparkBlue,
    },
    boxPadding: {
      padding: '1.5rem 3rem',
      backgroundColor: colors.white,
      [theme.breakpoints.down('sm')]: {
        padding: '1.5rem',
      },
    },
  }),
);

type Props = {
  path?: string;
};

export default function OCPAForm(_: Props) {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const {
    loading,
    customer,
    isComplete,
    ocpaOptionsReqError,
    form,
    handleSubmit,
  } = useOCPAInfoForm();

  const isProvinceSelected = isCanadianState(form.values.state);

  return (
    <Fragment>
      {loading && <Backdrop forceOpen />}
      <form id={'OCPA-form'} noValidate onSubmit={handleSubmit}>
        {
          <Box>
            <Typography className={classes.heading}>
              {t('OCPA_PAGE_TITLE')}
            </Typography>
            {isComplete ? (
              <OCPASuccessForm />
            ) : (
              <>
                <Box className={classes.accountBox} css={{ marginTop: 32 }}>
                  <AccountDropdown />
                </Box>
                <Paper className={classes.paperBlue}>
                  <Box
                    style={{
                      padding: '0px 0px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography className={classes.text}>
                      {richT('OCPA_INFO_TEXT')}
                    </Typography>
                  </Box>
                  <Box className={classes.whiteBox}>
                    <Typography
                      variant="h2"
                      style={{ padding: '1rem 0rem 0rem 1rem' }}
                    >
                      {t('REQUESTOR_INFORMATION')}
                    </Typography>
                    <CardContent>
                      <Typography className={classes.text}>
                        {t('REQUESTOR_INFORMATION_TEXT_1')}
                      </Typography>
                      <br />
                      <Typography className={classes.text}>
                        {t('REQUESTOR_INFORMATION_TEXT_2')}
                      </Typography>
                      <Typography className={classes.text}>
                        {t('REQUESTOR_INFORMATION_TEXT_3')}
                      </Typography>

                      <Grid
                        container
                        spacing={2}
                        css={{ marginTop: 32 }}
                        className={classes.root}
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            name={'firstName'}
                            id="firstName"
                            label={`${t('FIRST_NAME')} ${'*'}`}
                            style={{ width: '100%', marginBottom: 12 }}
                            inputProps={{ maxLength: 90, fontSize: 12 }}
                            {...form.props('firstName')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            name={'middleName'}
                            id="middleName"
                            label={t('MIDDLE_NAME')}
                            style={{ width: '100%', marginBottom: 12 }}
                            inputProps={{ maxLength: 90, fontSize: 12 }}
                            {...form.props('middleName')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            name={'lastName'}
                            id="lastName"
                            label={`${t('LAST_NAME')} ${'*'}`}
                            style={{ width: '100%', marginBottom: 12 }}
                            inputProps={{ maxLength: 90, fontSize: 12 }}
                            {...form.props('lastName')}
                          />
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          alignItems="center"
                          style={{ padding: '0px 12px' }}
                          className={classes.root}
                        >
                          <Grid item xs={12} md={6}>
                            <Dropdown
                              label={`${t('CONTACT_PHONE_TYPE')} ${'*'}`}
                              data-testid="phoneType"
                              selectionList={[
                                {
                                  value: t('ALT_PHONE'),
                                  label: t('ALT_PHONE'),
                                },
                                {
                                  value: t('PRIMARY_PHONE'),
                                  label: t('PRIMARY_PHONE'),
                                },
                                {
                                  value: t('MOB_PHONE'),
                                  label: t('MOB_PHONE'),
                                },
                                {
                                  value: t('INTERNATIONAL'),
                                  label: t('INTERNATIONAL'),
                                },
                                {
                                  value: t('BUSINESS_OR_WORK'),
                                  label: t('BUSINESS_OR_WORK'),
                                },
                                {
                                  value: t('BUSINESS_OR_WORK_MOBILE'),
                                  label: t('BUSINESS_OR_WORK_MOBILE'),
                                },
                              ]}
                              {...form.props('phoneType')}
                              onChange={(e: any) => {
                                void form.setValue('phoneType', e.target.value);
                                void form.onChange(e);
                              }}
                              error={Boolean(form.errors.phoneType)}
                            />

                            {form.errors.phoneType && (
                              <FormHelperText
                                className={classes.errorMessage}
                                error={true}
                              >
                                {form.errors.phoneType}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <PhoneTextField
                              name={'phoneValue'}
                              label={`${t('CONTACT_NUMBER')} ${'*'}`}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              {...form.props('phoneValue')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        className={classes.root}
                      >
                        <Typography
                          variant="h2"
                          style={{ padding: '2rem 0rem  0em 1rem' }}
                        >
                          {t('MAILING_ADDRESS')}
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ margin: '20px 0px 5px' }}
                        >
                          <TextField
                            name={'address'}
                            id="streetAddressUpdateInfo"
                            label={`${t(
                              'STREET_ADDRESS_UPDATE_INFO_LABEL',
                            )} ${'*'}`}
                            inputProps={{ maxLength: 60, fontSize: 16 }}
                            style={{ width: '100%' }}
                            {...form.props('address')}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            name={'city'}
                            id="cityUpdateInfo"
                            label={`${t('CITY')} ${'*'}`}
                            style={{ width: '100%' }}
                            inputProps={{ maxLength: 20, fontSize: 16 }}
                            {...form.props('city')}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={Boolean(form.errors.state)}
                          >
                            <InputLabel id="select-state-label">
                              {`${t('STATE_PROVINCE_LABEL')} ${'*'}`}
                            </InputLabel>
                            <Select
                              fullWidth
                              data-testid="ocpa-state"
                              labelId="ocpa-state-label"
                              id="stateUpdateInfo"
                              label={`${t('STATE_PROVINCE_LABEL')} ${'*'}`}
                              placeholder={t('STATE_PROVINCE_LABEL')}
                              aria-labelledby="account-select-label"
                              name="state"
                              onChange={async (e: any) => {
                                e.persist();
                                await form.setValue('state', e.target.value);
                                if (form.values.zip) {
                                  if (
                                    !statesAndProvincesGrouping.unitedStates.includes(
                                      e.target.value,
                                    ) &&
                                    form.values.zip.length === 5
                                  ) {
                                    await form.setValue('zip', '');
                                  }
                                  if (
                                    !statesAndProvincesGrouping.canada.includes(
                                      e.target.value,
                                    ) &&
                                    form.values.zip.length === 7
                                  ) {
                                    await form.setValue('zip', '');
                                  }
                                }
                              }}
                              onBlur={form.onBlur}
                              value={form.values.state}
                              native
                            >
                              <option aria-label="None" value="" />
                              <optgroup
                                style={{ backgroundColor: '#fff' }}
                                label={t('UNITED_STATES')}
                              >
                                {statesAndProvincesGrouping.unitedStates.map(
                                  stateValue => (
                                    <option key={stateValue} value={stateValue}>
                                      {stateValue}
                                    </option>
                                  ),
                                )}
                              </optgroup>
                              <optgroup
                                style={{ backgroundColor: '#fff' }}
                                label={t('CANADA')}
                              >
                                {statesAndProvincesGrouping.canada.map(
                                  stateValue => (
                                    <option key={stateValue} value={stateValue}>
                                      {stateValue}
                                    </option>
                                  ),
                                )}
                              </optgroup>
                            </Select>
                            <FormHelperText error={Boolean(form.errors.state)}>
                              {form.errors.state}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {isProvinceSelected ? (
                            <ProvinceInputField
                              name={'zip'}
                              id="zipCodeUpdateInfo"
                              label={`${t(
                                'ZIP_CODE_UPDATE_INFO_LABEL',
                              )} ${'*'}`}
                              style={{ width: '100%' }}
                              {...form.props('zip')}
                            />
                          ) : (
                            <ZipInputField
                              name={'zip'}
                              id="zipCodeUpdateInfo"
                              label={`${t(
                                'ZIP_CODE_UPDATE_INFO_LABEL',
                              )} ${'*'}`}
                              style={{ width: '100%' }}
                              {...form.props('zip')}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className={classes.root}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ marginTop: 16 }}
                          className={classes.root}
                        >
                          <TextField
                            name={'email'}
                            id="nameUpdateInfo"
                            label={`${t('EMAIL_ADDRESS')} ${'*'}`}
                            style={{ width: '100%', marginTop: 12 }}
                            inputProps={{ maxLength: 90, fontSize: 16 }}
                            {...form.props('email')}
                          />
                        </Grid>
                        {form.values.email !== customer?.email && (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: 16 }}
                            className={classes.root}
                          >
                            <TextField
                              name={'confirmEmail'}
                              id="confirmEmail"
                              label={`${t(
                                'CONFIRM_EMAIL_ADDRESS_LABEL',
                              )} ${'*'}`}
                              style={{ width: '100%', marginTop: 12 }}
                              inputProps={{ maxLength: 90, fontSize: 16 }}
                              {...form.props('confirmEmail')}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ margin: '1rem 0rem 1rem 0rem' }}
                      >
                        <Grid item md={12}>
                          <Typography className={classes.subHeading}>
                            {t('HOW_DO_YOU_WANT_TO_CONTACT')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <RadioGroup
                            row
                            aria-labelledby={t('CONTACT_MAIL_SELECTION')}
                            name={'contactMailType'}
                            value={form.props('contactMailType').value}
                            onChange={form.props('contactMailType').onChange}
                            onBlur={form.props('contactMailType').onBlur}
                          >
                            <FormControlLabel
                              value={'email'}
                              control={<Radio color={'primary'} />}
                              label={
                                <Typography className={classes.radioLabel}>
                                  {t('EMAIL')}
                                </Typography>
                              }
                            />

                            <FormControlLabel
                              value={'byMail'}
                              control={<Radio color={'primary'} />}
                              label={
                                <Typography className={classes.radioLabel}>
                                  {t('BY_MAIL')}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Box>
                  <Box className={classes.whiteBox}>
                    <Typography
                      variant="h2"
                      style={{ padding: '1rem 0rem 0rem 1rem' }}
                    >
                      {t('OCPA_WHO_YOU_ARE')}
                    </Typography>
                    <Typography
                      className={classes.text}
                      style={{ padding: '1rem' }}
                    >
                      {t('SELECT_ID_TYPE_TEXT')}
                    </Typography>
                    <Grid
                      container
                      className={classes.root}
                      spacing={2}
                      style={{ marginTop: '10px', padding: '0px 15px' }}
                    >
                      <Grid item xs={12} md={12}>
                        <Dropdown
                          label={`${t('PLEASE_SELECT_ID_TYPE')} ${'*'}`}
                          data-testid="idType"
                          selectionList={[
                            {
                              value: t('SOCIAL_SECURITY_NUMBER'),
                              label: t('SOCIAL_SECURITY_NUMBER'),
                            },
                            {
                              value: `${t('DRIVERS_LICENSE')} / ${t(
                                'STATE_ID_CARD',
                              )}`,
                              label: `${t('DRIVERS_LICENSE')} / ${t(
                                'STATE_ID_CARD',
                              )}`,
                            },
                            {
                              value: t('MATRICULA_ID'),
                              label: t('MATRICULA_ID'),
                            },
                            { value: t('PASSPORT'), label: t('PASSPORT') },
                            {
                              value: t('RESIDENT_ALIEN'),
                              label: t('RESIDENT_ALIEN'),
                            },
                            { value: t('VISA_ID'), label: t('VISA_ID') },
                            {
                              value: t('MILITARY_ID'),
                              label: t('MILITARY_ID'),
                            },
                            {
                              value: t('OR_TRIBAL_ID'),
                              label: t('OR_TRIBAL_ID'),
                            },
                          ]}
                          {...form.props('idType')}
                          onChange={(e: any) => {
                            void form.setValue('idType', e.target.value);
                            void form.onChange(e);
                          }}
                          error={Boolean(form.errors.idType)}
                        />

                        {form.errors.idType && (
                          <FormHelperText
                            className={classes.errorMessage}
                            error={true}
                          >
                            {form.errors.idType}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          name={'idValue'}
                          label={`${t(
                            'ENTER_LAST_FOUR_CHARACTERS_OF_YOUR_ID',
                          )} ${'*'}`}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          {...form.props('idValue')}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.paperBlue}>
                    <Typography
                      variant="h2"
                      style={{ padding: '1rem 0rem 0rem 0rem' }}
                    >
                      {t('HOW_CAN_WE_HELP')}
                    </Typography>
                  </Box>
                  <Paper className={classes.paperBlue} style={{ padding: 0 }}>
                    <Box className={classes.boxPadding}>
                      <FormControlLabel
                        data-testid="marketing-communication-label"
                        control={
                          <Checkbox
                            style={{ alignSelf: 'flex-start', paddingTop: 0 }}
                            data-testid={'mailingAddressId'}
                            color="primary"
                            {...form.props('personalInfoReport')}
                          />
                        }
                        label={
                          <Box style={{ margin: '0px 15px' }}>
                            <Typography
                              variant={'h3'}
                              className={classes.accordHeading}
                            >
                              {t('OCPA_REQUEST_PERSONAL_INFO_REPORT_TITLE')}
                            </Typography>
                            <>
                              <Typography className={classes.text}>
                                {richT(
                                  'OCPA_REQUEST_PERSONAL_INFO_REPORT_DESC',
                                )}
                              </Typography>
                            </>
                          </Box>
                        }
                      />
                    </Box>
                  </Paper>
                  <Paper className={classes.paperBlue} style={{ padding: 0 }}>
                    <Box className={classes.boxPadding}>
                      <FormControlLabel
                        data-testid="deleteInfo-label"
                        control={
                          <Checkbox
                            style={{ alignSelf: 'flex-start', paddingTop: 0 }}
                            color="primary"
                            {...form.props('deleteInfo')}
                          />
                        }
                        label={
                          <Box style={{ margin: '0px 15px' }}>
                            <Typography
                              variant={'h3'}
                              className={classes.accordHeading}
                            >
                              {t('OCPA_DELETE_MY_PERSONAL_INFO_TITLE')}
                            </Typography>

                            <Typography className={classes.text}>
                              {richT('OCPA_DELETE_MY_PERSONAL_INFO_DESC')}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Paper>
                  <Paper className={classes.paperBlue} style={{ padding: 0 }}>
                    <Box className={classes.boxPadding}>
                      <FormControlLabel
                        data-testid="marketing-communication-label"
                        control={
                          <Checkbox
                            style={{ alignSelf: 'flex-start', paddingTop: 0 }}
                            data-testid={'marketingCommunication'}
                            {...form.props('marketingCommunication')}
                            color="primary"
                          />
                        }
                        label={
                          <Box style={{ margin: '0px 15px' }}>
                            <Typography
                              variant={'h3'}
                              className={classes.optOutHeading}
                            >
                              {t('OCPA_OPT_OUT_MARKETING_TITLE')}
                            </Typography>
                            <Typography className={classes.text}>
                              {richT('OCPA_OPT_OUT_MARKETING_DESC')}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Paper>
                  <Box
                    className={classes.boxPadding}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <FormControlLabel
                      data-testid="marketing-communication-label"
                      control={
                        <Checkbox
                          style={{ alignSelf: 'flex-start', paddingTop: 20 }}
                          data-testid={'disclaimer'}
                          name={'disclaimer'}
                          {...form.props('disclaimer')}
                          color="primary"
                        />
                      }
                      label={
                        <Box
                          className={
                            form.values.disclaimer
                              ? classes.discTrue
                              : classes.discFalse
                          }
                        >
                          <Typography variant="body1">
                            {richT('OCPA_DESCLAIMER_TEXT')}
                          </Typography>
                        </Box>
                      }
                    />
                    {ocpaOptionsReqError && (
                      <Box
                        className={classes.discFalse}
                        style={{ padding: '.5rem 1.5rem' }}
                      >
                        <Typography variant="body1">
                          {t('OCPA_OPTIONS_REQUIRED')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: '15px 0px',
                    }}
                  >
                    <Grid item className={classes.cancelButton}>
                      <TextLink to={ROUTES.PRIVACY_POLICY}>
                        {t('CANCEL')}
                      </TextLink>
                    </Grid>
                    <Grid item>
                      <PGEButton
                        type={'submit'}
                        variant={'contained'}
                        color={'primary'}
                        className={classes.submit}
                      >
                        {t('SUBMIT')}
                      </PGEButton>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            )}
          </Box>
        }
      </form>
    </Fragment>
  );
}
