import { convertValidationRules } from '../../../hooks/useFormState';
import { validatePhone, validateEmail } from '../../../util/form-validation';
import { Model } from './types';
import { allAlertTypes } from './utils';
import { NotificationType } from '../../../__generated__/pge-types';

type AdditionalContext = {
  t: (translation: string) => string;
};

function textIsEnabled(values: Model) {
  return allAlertTypes.some(state => values[state].text === true);
}

export default convertValidationRules<Model, AdditionalContext>(context => ({
  phoneNumber: value => {
    if (!textIsEnabled(context.values) && value.length === 0) {
      // No phone number needed if there isnt any text values selected
      return null;
    }
    return validatePhone(value);
  },
  email: validateEmail,
  exceedThreshold: value => {
    const values = context.values[NotificationType.Webexc];
    if (values.text || values.email) {
      if (Number(value) === 0) {
        return context.t('THRESHOLD_ERROR');
      }
      if (Number(value) > 99999) {
        return context.t('THRESHOLD_ERROR');
      }
    }
  },
}));
