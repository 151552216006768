import React, { useContext, useReducer } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, navigate, useLocation } from '@reach/router';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';

import ROUTES from '../../routes';
import AccountListReducer from '../account-summary/multi-accounts/accountListReducer';
import {
  toDateString,
  toCurrencyDisplayFormat,
  toDateStringFullMonthName,
} from '../../util/format';
import {
  ViewPaymentHistoryDetail,
  ViewPaymentHistoryBillingAndPaymentType,
} from '../../__generated__/pge-types';
import { useTranslation } from '../../hooks/useTranslation';
import Pagination from '../../components/pagination';
import { PageSize } from '../../components/pagination/types';
import useAccountsStore from '../../hooks/useAccountsStore';
import useDownloadBill from '../../hooks/useDownloadBill';
import colors from '../../themes/main-colors';
import TextLink from '../text-link';
import { PictureAsPdf } from '@material-ui/icons';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridDivider: {
      margin: theme.spacing(2, 0),
    },
    paper: {
      padding: theme.spacing(3),
    },
    dateCell: {
      fontSize: '13px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    actionArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    doneButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    header: {
      fontSize: '12px',
    },
    amountValue: {
      fontSize: theme.spacing(1.5),
    },
    hiddenSmDown: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    textSmallFontSize: {
      fontSize: '14px',
    },
    textRightAlign: {
      textAlign: 'right',
    },
    textLeftAlign: {
      textAlign: 'left',
    },
    closedAccountsLink: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        order: 1,
      },
    },
    viewBillTextLink: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
          '&:active': {
            borderBottom: 'none',
          },
        },
      },
    },
    rowDate: {
      fontSize: '12px',
    },
    rowType: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    servicePeriod: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    billsRow: {
      width: '100%',
      borderRadius: '3px',
      background: colors.lightGray2,
      marginTop: '5px',
      padding: '5px',
      fontWeight: 'bold',
      fontSize: '12px',
    },
  }),
);

export interface ViewPaymentHistoryDetailProps {
  record: ViewPaymentHistoryDetail;
  index?: number;
  currentBill: ViewPaymentHistoryDetail | null | undefined;
  isClosedAccount?: boolean;
  isMobile?: boolean;
  isSummaryBillAccount?: boolean;
}

const getRecordType = (record: ViewPaymentHistoryDetail) => {
  const { t } = useTranslation();
  switch (record.type) {
    case ViewPaymentHistoryBillingAndPaymentType.Agency:
      return t('AGENCY_PAYMENT').toUpperCase();
    case ViewPaymentHistoryBillingAndPaymentType.PendingPayment:
      return t('PAYMENT_PENDING').toUpperCase();
    case ViewPaymentHistoryBillingAndPaymentType.PendingAgencyPay:
      return t('PENDING_AGENCY_PAYEMTN_LABEL').toUpperCase();
    case ViewPaymentHistoryBillingAndPaymentType.Payment:
      return t('PAYMENT').toUpperCase();
    case ViewPaymentHistoryBillingAndPaymentType.Bill:
      return `${t('BILL').toLocaleUpperCase()} ${t('BILL_FOR_SERVICE')}`;
    default:
      return null;
  }
};

const getBillPDF = ({
  record,
  currentBill,
  isClosedAccount,
  isMobile,
  isSummaryBillAccount,
}: ViewPaymentHistoryDetailProps) => {
  const { t } = useTranslation();

  const downloader = useDownloadBill();
  const { wrapWithLoader } = useWrapWithLoader();
  const downloadBill = wrapWithLoader(
    async (
      encryptedBillId: string,
      startDate: any,
      endDate: any,
      isSummary: boolean,
    ) => {
      if (encryptedBillId) {
        const billId = encryptedBillId;

        const linkSource = await downloader.downloadBill(billId, isSummary);
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = `${t('VIEW_BILL').replace(
          ' ',
          '_',
        )}-${toDateString(startDate, 'MMddyyyy')}_${toDateString(
          endDate,
          'MMddyyyy',
        )}.pdf`;
        downloadLink.click();
      }
    },
    () => t('NO_BILL_FOUND'),
  );

  const isCurrentBill = (item: ViewPaymentHistoryDetail): boolean => {
    if (currentBill) {
      return item.encryptedBillId === currentBill.encryptedBillId;
    }
    return false;
  };

  return !isMobile ? (
    <>
      {!isClosedAccount && isCurrentBill(record) ? (
        <TextLink
          to={ROUTES.VIEW_BILL}
          style={{
            textDecoration: 'underline',
            marginRight: '10px',
            fontSize: '12px',
          }}
        >
          {t('VIEW_BILL_ONLINE')}
        </TextLink>
      ) : null}
      {isSummaryBillAccount && (
        <PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
      )}
      {isSummaryBillAccount && (
        <TextLink
          style={{
            textDecoration: 'underline',
            marginRight: '10px',
            fontSize: '12px',
          }}
          onClick={async (e: any) => {
            e.preventDefault();
            await downloadBill(
              record?.encryptedBillId || '',
              record?.billingPeriodStartDate,
              record?.billingPeriodEndDate,
              true,
            );
          }}
        >
          {t('SUMMARY_BILL_PDF')}
        </TextLink>
      )}
      <PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
      <TextLink
        style={{
          textDecoration: 'underline',
          marginRight: '10px',
          fontSize: '12px',
        }}
        onClick={async (e: any) => {
          e.preventDefault();
          await downloadBill(
            record?.encryptedBillId || '',
            record?.billingPeriodStartDate,
            record?.billingPeriodEndDate,
            false,
          );
        }}
      >
        {t('DETAIL_BILL_PDF')}
      </TextLink>
    </>
  ) : (
    <Grid container>
      {!isClosedAccount && isCurrentBill(record) ? (
        <Grid item xs={12}>
          <TextLink
            to={ROUTES.VIEW_BILL}
            style={{
              textDecoration: 'underline',
              marginRight: '10px',
              fontSize: '12px',
            }}
          >
            {t('VIEW_BILL_ONLINE')}
          </TextLink>
        </Grid>
      ) : null}
      {isSummaryBillAccount && (
        <Grid item xs={6}>
          <PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
          <TextLink
            style={{
              textDecoration: 'underline',
              marginRight: '10px',
              fontSize: '12px',
            }}
            onClick={async (e: any) => {
              e.preventDefault();
              await downloadBill(
                record?.encryptedBillId || '',
                record?.billingPeriodStartDate,
                record?.billingPeriodEndDate,
                true,
              );
            }}
          >
            {t('SUMMARY_BILL_PDF')}
          </TextLink>
        </Grid>
      )}
      <Grid item xs={6}>
        <PictureAsPdf style={{ marginRight: 4, fontSize: 12 }} />
        <TextLink
          style={{
            textDecoration: 'underline',
            marginRight: '10px',
            fontSize: '12px',
          }}
          onClick={async (e: any) => {
            e.preventDefault();
            await downloadBill(
              record?.encryptedBillId || '',
              record?.billingPeriodStartDate,
              record?.billingPeriodEndDate,
              false,
            );
          }}
        >
          {t('DETAIL_BILL_PDF')}
        </TextLink>
      </Grid>
    </Grid>
  );
};

const RecordDescription = ({ record }: ViewPaymentHistoryDetailProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (record.type === ViewPaymentHistoryBillingAndPaymentType.Bill) {
    return (
      <>
        {!isMobile ? (
          <Typography className={classes.rowDate}>&nbsp;</Typography>
        ) : null}
        <Typography className={classes.servicePeriod}>
          {`${toDateString(
            record.billingPeriodStartDate,
            'MM/dd/yyyy',
          )} - ${toDateString(record.billingPeriodEndDate, 'MM/dd/yyyy')}`}
        </Typography>
      </>
    );
  } else {
    return null;
  }
};

const PaymentHistoryHeaders = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={2}>
          <Typography variant="body2" className={classes.header}>
            {t('DATE')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" className={classes.header}>
            {t('SERVICE_PERIOD')}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.header}>
            {t('KWH')}
          </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Typography
            variant="body2"
            className={classes.header}
            style={{ textTransform: 'capitalize' }}
          >
            {t('AMOUNT_DUE')}
          </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Typography variant="body2" className={classes.header}>
            {t('AMOUNT_PAID')}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.gridDivider} />
    </>
  );
};

interface PaymentDescriptionProps {
  amount: number | undefined | null;
  type: ViewPaymentHistoryBillingAndPaymentType | null | undefined;
  amountType: string;
}

const PaymentDescription = ({
  amount,
  type,
  amountType,
}: PaymentDescriptionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  let content = null;
  switch (type) {
    case ViewPaymentHistoryBillingAndPaymentType.Agency:
      content = amount
        ? `$${toCurrencyDisplayFormat(amount, false, 'CR')}`
        : '';
    case ViewPaymentHistoryBillingAndPaymentType.Bill:
      content = amount
        ? `$${toCurrencyDisplayFormat(amount, false, 'CR')}`
        : '';
    case ViewPaymentHistoryBillingAndPaymentType.PendingPayment:
      content = amount
        ? `$${toCurrencyDisplayFormat(amount, false, 'CR')}`
        : '';
    case ViewPaymentHistoryBillingAndPaymentType.PendingAgencyPay:
      content = amount
        ? `$${toCurrencyDisplayFormat(amount, false, 'CR')}`
        : '';
    case ViewPaymentHistoryBillingAndPaymentType.Payment:
      content = amount
        ? `$${toCurrencyDisplayFormat(amount, false, 'CR')}`
        : '';
  }

  return (
    <Typography
      variant="body2"
      className={isMobile ? classes.textSmallFontSize : classes.amountValue}
    >
      {amountType === 'amountPaid' ? '-' + content : content}
    </Typography>
  );
};

export const PaymentHistoryRecord = ({
  record,
  currentBill,
  isClosedAccount,
  isSummaryBillAccount,
}: ViewPaymentHistoryDetailProps) => {
  const { date } = record;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return !isMobile ? (
    <Grid container data-testid={'payment-history-record-field'}>
      <Grid
        item
        xs={12}
        md={2}
        className={classes.dateCell}
        data-testid={'date-field'}
      >
        <Typography className={classes.rowDate}>
          {toDateStringFullMonthName(date)}
        </Typography>
        <Typography className={classes.rowType}>
          {getRecordType(record)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} data-testid={'description-field'}>
        <RecordDescription
          record={record}
          currentBill={currentBill}
          isClosedAccount={isClosedAccount}
        />
      </Grid>
      <Grid
        item
        xs={4}
        md={2}
        className={classes.textLeftAlign}
        data-testid={'kwh-field'}
      >
        <Typography className={classes.rowDate}>&nbsp;</Typography>
        <Typography variant="body2" className={classes.textSmallFontSize}>
          {record.kwh ? `${record.kwh} kWh` : ''}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        md={2}
        className={classes.textLeftAlign}
        data-testid={'amount-due-field'}
      >
        <Typography className={classes.rowDate}>&nbsp;</Typography>
        <PaymentDescription
          amount={record.amountDue}
          type={record.type}
          amountType="amountDue"
        />
      </Grid>
      <Grid
        item
        xs={4}
        md={2}
        className={classes.textLeftAlign}
        data-testid={'amount-paid-field'}
      >
        <Typography className={classes.rowDate}>&nbsp;</Typography>
        <PaymentDescription
          amount={record.amountPaid}
          type={record.type}
          amountType="amountPaid"
        />
      </Grid>
      {record.type === ViewPaymentHistoryBillingAndPaymentType.Bill ? (
        <Grid item className={classes.billsRow} data-testid={'bill-row'}>
          <Grid container spacing={2}>
            <Grid item className={classes.viewBillTextLink}>
              {getBillPDF({
                record,
                currentBill,
                isClosedAccount,
                isMobile,
                isSummaryBillAccount,
              })}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <Grid container data-testid={'payment-history-record-field'}>
      <Grid
        item
        xs={12}
        className={classes.dateCell}
        data-testid={'date-field'}
      >
        <Typography className={classes.rowDate}>
          {toDateStringFullMonthName(date)}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.dateCell}>
        <Typography className={classes.rowType}>
          {getRecordType(record)}
        </Typography>
      </Grid>
      {!record.amountPaid ? (
        <Grid
          item
          xs={8}
          className={`${classes.dateCell} ${classes.textLeftAlign}`}
          data-testid={'description-field'}
        >
          <RecordDescription
            record={record}
            currentBill={currentBill}
            isClosedAccount={isClosedAccount}
          />
        </Grid>
      ) : null}
      {record.kwh ? (
        <Grid
          item
          xs={4}
          className={classes.textLeftAlign}
          data-testid={'kwh-field'}
        >
          <Typography variant="body2" className={classes.textSmallFontSize}>
            {record.kwh ? `${record.kwh} kWh` : ''}
          </Typography>
        </Grid>
      ) : null}
      {record.amountDue ? (
        <Grid
          item
          xs={8}
          md={2}
          className={classes.textRightAlign}
          data-testid={'amount-due-field'}
        >
          <PaymentDescription
            amount={record.amountDue}
            type={record.type}
            amountType="amountDue"
          />
        </Grid>
      ) : null}
      {record.amountPaid ? (
        <Grid
          item
          xs={8}
          className={classes.textRightAlign}
          data-testid={'amount-paid-field'}
        >
          <PaymentDescription
            amount={record.amountPaid}
            type={record.type}
            amountType="amountPaid"
          />
        </Grid>
      ) : null}
      {record.type === ViewPaymentHistoryBillingAndPaymentType.Bill ? (
        <Grid item className={classes.billsRow} data-testid={'bill-row'}>
          <Grid container>
            {getBillPDF({
              record,
              currentBill,
              isClosedAccount,
              isMobile,
              isSummaryBillAccount,
            })}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const PaymentHistoryEmpty = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography>{t('PAYMENT_HISTORY_EMPTY')}</Typography>
      <Typography>{`${t('ASSISTANCE')} ${t(
        'CALL_FOR_ASSISTANCE_NUMBER',
      )}`}</Typography>
    </>
  );
};

export interface PaymentHistoryPageProps {
  history?: ViewPaymentHistoryDetail[];
  historyLoading: boolean | undefined;
  page?: number;
  pageSize: PageSize;
  totalCount?: number;
  onPageChange?: (newPage: number, newPageSize: PageSize) => void;
  currentBill: ViewPaymentHistoryDetail | undefined | null;
  isClosedAccount?: boolean;
  isSummaryBillAccount?: boolean;
}

export default ({
  history,
  historyLoading,
  page = 1,
  pageSize = 15,
  totalCount = 0,
  onPageChange = () => ({}),
  currentBill,
  isClosedAccount = false,
  isSummaryBillAccount,
}: PaymentHistoryPageProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);
  const location = useLocation();

  const { customerAccountState } = useAccountsStore();

  const noPaymentHistory =
    historyLoading === false && (!history || !history.length);

  const isNotClosedAccountBillingPage = !location.pathname.includes(
    '/closed-accounts',
  );

  return (
    <>
      <Pagination
        page={page}
        pageSize={pageSize}
        pageSizes={[5, 10, 15]}
        count={totalCount}
        onPageChange={onPageChange}
      />
      <Paper className={classes.paper}>
        <Grid container>
          {historyLoading === undefined || historyLoading === true ? (
            <Grid item xs={12} className={classes.loading}>
              <CircularProgress size={35} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              {noPaymentHistory ? (
                <PaymentHistoryEmpty />
              ) : (
                <>
                  <span className={classes.hiddenSmDown}>
                    <PaymentHistoryHeaders />
                  </span>
                  {(history || []).map((record, index) => (
                    <React.Fragment key={index}>
                      <PaymentHistoryRecord
                        record={record}
                        currentBill={currentBill}
                        isClosedAccount={isClosedAccount}
                        isSummaryBillAccount={isSummaryBillAccount}
                      />
                      {index + 1 < (history || []).length && (
                        <Divider className={classes.gridDivider} />
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
      <Grid container>
        <Grid item xs={12} className={classes.actionArea}>
          <Box className={classes.closedAccountsLink}>
            {isClosedAccountsEnabled &&
              isNotClosedAccountBillingPage &&
              customerAccountState?.hasInactiveAccounts && (
                <Link to={ROUTES.CLOSED_ACCOUNTS}>
                  {t('VIEW_CLOSED_ACCOUNTS')}
                </Link>
              )}
          </Box>
          <Button
            color="primary"
            variant="contained"
            className={classes.doneButton}
            onClick={() =>
              navigate(
                isClosedAccount ? ROUTES.CLOSED_ACCOUNTS : ROUTES.ACCOUNT,
              )
            }
          >
            {noPaymentHistory ? t('OK') : t('DONE')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
