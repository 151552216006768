import {
  Button, Grid,
  Menu,
  MenuItem, Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme
} from '@material-ui/core';
import React, { FC, Fragment, useEffect, useState } from 'react';
import PGEButton from '../../../buttons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../../../hooks/useTranslation';

import colors from '../../../../themes/main-colors';
import { PictureAsPdf } from '@material-ui/icons';
import PaperBox from '../../../utility/paper-box';
import {
  MAX_NUM_MONTHS_FOR_SHORT_TERM,
  TpaAccountDetails,
  TpaMonthsMatrix,
} from '../useTPA';
import SingleInstallment from '../confirm-plan/SingleInstallment';
import {
  getSelectedYearTPAMonthMatrix, getAllTpaPaymentsOverDue
} from '../utils';
import { formatDate, toDateString } from '../../../../util/format';
import useAuthQuery from '../../../../hooks/useAuthQuery';
import { getAccountDetailsBillDetailsQuery } from '../../query';
import { AccountDetail } from '../../../../__generated__/pge-types';
import useSelectedAccountParams from '../../../../hooks/useSelectedAccountParams';
import useDownloadBill from '../../../../hooks/useDownloadBill';
import useWrapWithLoader from '../../../../hooks/useWrapWithLoading';
import TPAPaymentYear from '../../landing/tpa-view/TPAPaymentYear';
import { isEqual } from 'lodash';
import { navigate } from '@reach/router';
import routes from '../../../../routes';
import { convertToHigherValue } from '../tpaAmountCalculationUtil';
interface Props {
  tpaDetails: TpaAccountDetails | undefined;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperCard: {
      boxShadow: 'inset 0px 2px 0px #27788C, 0px 3px 6px #00000029',
      padding: '0px !important',
    },
    leftSideAmount: {
      background: colors.white,
      rowGap: '20px',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    columnView: {
      padding: theme.spacing(2),
    },
    leftColumn: {
      backgroundColor: colors.lightBlue,
      borderRadius: '5px 0px 0px 5px',
      marginTop: '2px',
    },
    overDueBanner: {
      border: '1px solid red',
      borderRadius: '5px',
      backgroundColor: colors.white,
      color: colors.darkerRed,
      textAlign: 'center',
    },
    tpaPlanViewWrap: {
      padding: theme.spacing(2, 0, 0, 0),
    },
    completePlanInstallmentWrap: {
      textAlign: 'center',
      '& h5': {
        fontWeight: 300,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 -16px', //-16 to nullify the padding
      },
    },
  }),
);
const PaymentPlanProgress: FC<Props> = ({ tpaDetails }) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { accountParams } = useSelectedAccountParams();
  const { data: getAccountDetailsData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsBillDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams
  });

  const tpaMatrix = tpaDetails?.enrolledInstallmentDetails?.monthsMatrix;
  

  const isExtraLongTPAPlan =
    tpaMatrix && tpaMatrix.length > MAX_NUM_MONTHS_FOR_SHORT_TERM;
  const [tpaPaymentSelectedYear, setTpaPaymentSelectedYear] = useState<string>(
    new Date().getFullYear().toString(),
  );
  const [relevantTPAMatrix, setRelevantTPAMatrix] = useState<TpaMonthsMatrix[]>(
    isExtraLongTPAPlan
      ? getSelectedYearTPAMonthMatrix(tpaPaymentSelectedYear, tpaMatrix)
      : tpaMatrix!,
  );
  const tpaPaymentsOverdue: TpaMonthsMatrix[] = relevantTPAMatrix
    ? getAllTpaPaymentsOverDue(tpaMatrix!)
    : [];

  if (!relevantTPAMatrix) {
    return null;
  }
  const tpaYears = [
    ...new Set(
      tpaMatrix?.map(item => item.paymentDate.getFullYear().toString()),
    ),
  ];
  useEffect(() => {
    
    if (tpaYears.length > 0) {     
      setRelevantTPAMatrix(
        getSelectedYearTPAMonthMatrix(tpaPaymentSelectedYear, tpaMatrix!),
      );
    }
  }, [tpaPaymentSelectedYear]);

  useEffect(() => {    
    if (tpaYears.length > 0 && tpaYears.length === 1 && tpaYears[0] != tpaPaymentSelectedYear) {      
        setTpaPaymentSelectedYear(tpaYears[0]);     
    }
  }, [tpaYears]);
 
 
  const theme = useTheme();
  const downloader = useDownloadBill();
  const { wrapWithLoader } = useWrapWithLoader();
  const downloadBill = wrapWithLoader(
    async (e: React.MouseEvent, isSummary: boolean) => {
      if (e) {
        e.preventDefault();
        setAnchorEl(null);
      }

      const downloadLink = document.createElement('a');

      downloadLink.href = await downloader.downloadBill(
        getAccountDetailsData?.getAccountDetails?.[0].billInfo?.billDetails
          ?.encryptedBillId,
        isSummary,
      );

      downloadLink.download = `${t('VIEW_BILL').replace(
        ' ',
        '_',
      )}-${toDateString(
        getAccountDetailsData?.getAccountDetails?.[0].billInfo?.billDetails
          ?.billingPeriodStartDate,
      )}_${toDateString(
        getAccountDetailsData?.getAccountDetails?.[0].billInfo?.billDetails
          ?.billingPeriodEndDate,
      )}.pdf`;

      downloadLink.click();
    },
    () => t('NO_BILL_FOUND'),
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {tpaPaymentsOverdue && tpaPaymentsOverdue.length > 0 && (
        <Typography variant="body2" className={classes.overDueBanner}>
          {richT('OVERDUE_BANNER_MESSAGE', {
            dueDate: formatDate(
              tpaPaymentsOverdue[tpaPaymentsOverdue.length - 1].paymentDate,
              'MM/dd/yy',
            ),
          })}
        </Typography>
      )}
      <PaperBox className={classes.paperCard}>
        <Grid container>
          <Grid
            item
            container
            sm={6}
            className={`${classes.columnView} ${classes.leftColumn}`}
          >
            <Grid item container style={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {richT('PAYMENT_PLAN_PROGRESS_HEADING_TEXT', {
                    ACCOUNT_NUMBER: accountParams?.accountNumber,
                    MONTHS: tpaDetails?.enrolledInstallmentDetails?.totalMonths!.toString(),
                    END_DATE: formatDate(
                      new Date(
                        tpaDetails?.enrolledInstallmentDetails?.monthsMatrix[
                          tpaDetails?.enrolledInstallmentDetails?.totalMonths -
                            1
                        ].paymentDate!,
                      ),
                      'MMM. yyyy',
                    ),
                  })}
                </Typography>

                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  className={classes.leftSideAmount}
                >
                  <Grid item>
                    <Typography variant="body2">
                      {t('PAYMENT_PLAN_BALANCE')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1">
                      $
                      {tpaDetails?.enrolledInstallmentDetails?.remainingBalance.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      style={{ color: colors.errorRed }}
                    >
                      {t('PAYMENT_PLAN_LATE_CHARGE_MESSAGE')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'flex-end' }}>
              {tpaPaymentsOverdue && tpaPaymentsOverdue.length > 0 && (
                <Button
                  variant="outlined"
                  style={{
                    whiteSpace: 'nowrap',
                    background: colors.white,
                    color: colors.sparkBlue,
                    width: '100%',
                    borderRadius: '25px',
                    marginBottom: theme.spacing(2),
                  }}
                  size={'large'}
                  onClick={async () =>
                    await navigate(routes.PAYMENT)
                  }
                  data-testid="paybill-button"
                  id="paybill-button"
                >
                  {t('PAY_BILL')}
                </Button>
              )}
              <PGEButton
                style={{ width: '100%', borderRadius: '25px' }}
                size={'large'}
                endIcon={<ExpandMore />}
                onClick={handleClick}
                data-testid="positioned-button"
                id="positioned-button"
                aria-controls={open ? 'positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                {t('DOWNLOAD_PDF')}
              </PGEButton>
              <Menu
                id="positioned-menu"
                aria-labelledby="positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {
                    background: colors.brightBlue,
                    color: colors.white,
                    borderRadius: '25px',
                  },
                }}
              >
                <MenuItem onClick={e => downloadBill(e, false)}>
                  <PictureAsPdf style={{ marginRight: '10px' }} />
                  {t('DETAILED_BILL_DOWNLOAD')}
                </MenuItem>
                {getAccountDetailsData?.getAccountDetails?.[0]
                  .isSummaryBillAccount && (
                  <MenuItem onClick={e => downloadBill(e, true)}>
                    <PictureAsPdf style={{ marginRight: '10px' }} />
                    {t('SUMMARY_BILL_DOWNLOAD')}
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
          <Grid item
            sm={6} style={{width:'100%'}}>
            <Grid container className={classes.columnView}>
            {tpaYears && tpaYears.length && tpaYears.length > 1 ? (
              <Grid item xs={12}>
              <TPAPaymentYear
                tpaPaymentYears={tpaYears}
                handleTPAPaymentYearClick={year =>
                  setTpaPaymentSelectedYear(year)
                }
                tpaPaymentSelectedYear={tpaPaymentSelectedYear}
              />
              </Grid>
            ) : null}
             <Grid item container xs={12}>
              
            {relevantTPAMatrix &&
              relevantTPAMatrix.map(
                (tpaInsMatrix: TpaMonthsMatrix, index: number) =>
                  tpaInsMatrix.paymentDate && (
                    <Grid item>
                    <SingleInstallment
                      key={index}
                      installmentAmount={tpaInsMatrix.monthlyAmount}
                      installmentDate={tpaInsMatrix.paymentDate}
                      isFirstInstallment={false}
                      isPaymentCompleted={tpaInsMatrix?.isPaymentCompleted}
                      isUpcomingPayment={
                        tpaPaymentsOverdue.length > 0 &&
                        tpaInsMatrix.monthNumber ===
                          tpaPaymentsOverdue[tpaPaymentsOverdue.length - 1]
                            .monthNumber
                      }
                      isTPAPaymentOverDue={tpaPaymentsOverdue.some(obj =>
                        isEqual(obj, tpaInsMatrix),
                      )}
                      overDueAmount={convertToHigherValue(tpaPaymentsOverdue
                        .map(tpaDue => tpaDue.monthlyAmount)
                        .reduce((prev, curr) => prev + curr, 0),2)}
                      isTPALandingPage={true}
                    />
                    </Grid>
                  ),
              )}
              </Grid>
              </Grid>
          </Grid>
        </Grid>
      </PaperBox>
    </Fragment>
  );
};
export default PaymentPlanProgress;
