import React, { FC, useState } from 'react';
import { Typography, makeStyles, createStyles, Grid } from '@material-ui/core';
import colors from '../../../../themes/main-colors';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yearHolder: {
      margin: theme.spacing(0, 0, 4, 0),
      [theme.breakpoints.down('md')]: {
        overflow: 'auto',
      },
    },
    singleYear: {
      border: `1px solid ${colors.lightGray1}`,
      backgroundColor: colors.gray97,
      borderRight: 0,
      color: colors.noirBlur,
      padding: theme.spacing(1.5),
      fontWeight: 700,
      cursor: 'pointer',
      '&:last-child': {
        borderRight: `1px solid ${colors.lightGray1}`,
      },
      [theme.breakpoints.down('sm')]: {
        borderColor: colors.noirBlur,
        backgroundColor: colors.white,
        padding: theme.spacing(1, 1.5),
        fontWeight: 300,
      },
    },
    active: {
      backgroundColor: colors.noirBlur,
      color: colors.white,
    },
  }),
);
interface Props {
  tpaPaymentYears: Array<string>;
  tpaPaymentSelectedYear: string;
  handleTPAPaymentYearClick: (year: string) => void;
}

const TPAPaymentYear: FC<Props> = (props: Props) => {
  const {
    tpaPaymentYears,
    tpaPaymentSelectedYear,
    handleTPAPaymentYearClick,
  } = props;
  const classes = useStyles();
  const [activeYear, setActiveYear] = useState<string | null>(
    tpaPaymentSelectedYear,
  );

  return (
    <Grid
      wrap="nowrap"
      container
      justify="center"
      direction="row"
      className={classes.yearHolder}
    >
      {tpaPaymentYears.map((year: string, index: number) => (
        <Typography
          onClick={() => {
            setActiveYear(year);
            handleTPAPaymentYearClick(year);
          }}
          variant="body2"
          component="span"
          data-testid={`tpa-year-selector${index}`}
          key={year}
          className={clsx(
            classes.singleYear,
            year === activeYear && classes.active,
          )}
        >
          {year}
        </Typography>
      ))}
    </Grid>
  );
};
export default TPAPaymentYear;
