import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { AlertOptions, AlertState } from './types';
import { useStyles } from './styles';
import TextLink from '../../text-link';
import { toSentenceCase } from '../../../util/format';

const AlertLabel = ({
  onTextClick,
  label,
}: Pick<AlertOptions, 'label' | 'onTextClick'>) => {
  if (onTextClick === undefined) {
    return toSentenceCase(label || '');
  }
  return (
    <TextLink
      tooltip
      external
      onClick={event => {
        event.preventDefault();
        onTextClick();
      }}
    >
      {toSentenceCase(label)}
    </TextLink>
  );
};

interface ToggleButtonProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export const ToggleButton = ({
  disabled = false,
  value,
  onChange,
}: ToggleButtonProps) => {
  const toggle = disabled
    ? undefined
    : () => {
        onChange(!value);
      };

  return (
    <>
      {!value ? (
        <IconButton
          color={disabled ? undefined : 'primary'}
          onClick={toggle}
          key={String(value)}
        >
          <AddCircleIcon
            data-testid="testing-add-circle-icon"
            fontSize="large"
          />
        </IconButton>
      ) : (
        <IconButton
          style={disabled ? undefined : { color: '#43936F' }}
          onClick={toggle}
          key={String(value)}
        >
          <CheckCircleIcon
            data-testid="testing-check-circle-icon"
            fontSize="large"
          />
        </IconButton>
      )}
    </>
  );
};

type Props = {
  children: React.ReactNode;
  value: AlertState;
  onChange: (value: AlertState) => void;
} & AlertOptions & { label: React.ReactNode };

export function EmailTextToggleControl({
  label,
  emailDisabled = false,
  textDisabled = false,
  value,
  onChange,
  children,
  onTextClick,
  hideEmail = false,
  hideText = false,
}: Props) {
  const classes = useStyles();
  const createChangeHandler = (kind: 'email' | 'text') => () => {
    const newValue = {
      ...value,
      [kind]: !value[kind],
    };
    onChange(newValue);
  };

  const labelPortion = (
    <Typography variant={'body1'} style={{ fontWeight: 600 }}>
      {AlertLabel({ label, onTextClick })}
    </Typography>
  );
  const rowStart = Boolean(children) ? (
    <Grid
      container
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Grid item style={{ paddingRight: 10 }}>
        {labelPortion}
      </Grid>
      {children}
    </Grid>
  ) : (
    labelPortion
  );

  return (
    <div className={classes.row}>
      <Box>{rowStart}</Box>
      <Box>
        {!hideEmail ? (
          <ToggleButton
            disabled={emailDisabled}
            value={value.email}
            onChange={createChangeHandler('email')}
          />
        ) : null}
      </Box>
      <Box>
        {!hideText ? (
          <ToggleButton
            value={value.text}
            onChange={createChangeHandler('text')}
            disabled={textDisabled}
          />
        ) : null}
      </Box>
    </div>
  );
}
