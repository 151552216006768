import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent, useEffect } from 'react';
import { parse } from 'date-fns';

import { useTranslation } from '../../../hooks/useTranslation';
import useStyles from '../payment-verification-form/PaymentVerificationForm.styles';
import { UsePaymentFormResponse } from '../PaymentForm.types';
import { OneTimePayConfigData } from '../oneTimePayment.types';
import { isWithInThreeDays } from '../oneTimePay.utils';
import TextLink from '../../text-link';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import { toDateStringFullMonthName } from '../../../util/format';
import Box from '@material-ui/core/Box';
import ProceedOrCancel from '../../proceed-or-cancel/ProceedOrCancel';
import PaymentProfileIcon from '../../paymentus/payment-profile-icon/PaymentProfileIcon';
import { getAccountDisplayString } from '../../paymentus/utils';
import {
  getPaymentCategoryLabel,
  getPaymentMethodLabel,
} from '../../../hooks/usePaymentus';

interface PaymentVerificationFormProps extends UsePaymentFormResponse {
  path: string;
  isMobile: boolean;
  oneTimePayConfigData: OneTimePayConfigData;
  formattedPaymentAmount: string;
  onSubmit: () => void;
  paymentMethodCapLimit: number;
  isForTPA?: boolean;
  isForReconnect?: boolean;
  cancelHandler?: () => void;
}

const PaymentVerificationForm: FunctionComponent<PaymentVerificationFormProps> = props => {
  const { richT, t } = useTranslation();
  const classes = useStyles();
  const paymentDateIsWithInThreeDays = isWithInThreeDays(
    props.oneTimePayConfigData?.paymentDate,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedProfileList =
    props.oneTimePayConfigData?.paymentSelector?.selectedPaymentProfileList;

  return (
    <Grid container spacing={3} className={classes.marginTop1}>
      <Grid item xs={12}>
        <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
          <Card>
            <CardContent>
              {!props.isMobile ? (
                <Grid item xs={12} className={classes.marginBotSpace}>
                  <Typography
                    className={classes.colorTextPrimary}
                    component={'span'}
                    variant={'h2'}
                  >
                    {t('VERIFY_PAYMENT')}
                  </Typography>
                </Grid>
              ) : null}

              <Grid item xs={12} className={classes.listGrid}>
                <Grid item xs={3}>
                  <Typography className={classes.colorTextPrimary}>
                    {t('PAY')}
                  </Typography>
                </Grid>
                <Grid item xs={9} md={2}>
                  <Typography
                    className={classes.colorTextPrimary && classes.fontFamilyCls}
                    variant={'h2'}
                  >
                    {props.formattedPaymentAmount}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid item xs={12} className={classes.listGrid}>
                <Grid item xs={3}>
                  <Typography className={classes.colorTextPrimary}>
                    {t('ON')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classes.colorTextPrimary}
                    variant={'h2'}
                  >
                    {props.oneTimePayConfigData?.paymentDate
                      ? toDateStringFullMonthName(
                          parse(
                            props.oneTimePayConfigData?.paymentDate,
                            'MM/dd/yyyy',
                            new Date(),
                          ),
                        )
                      : null}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />
              {selectedProfileList &&
                selectedProfileList.map(paymentProfile => {
                  return (
                    <>
                      <Grid
                        item
                        key={paymentProfile.profile.token}
                        xs={12}
                        className={classes.paymentMethodContainer}
                        style={{
                          flexDirection: props.isMobile
                            ? selectedProfileList.length ===
                              props.paymentMethodCapLimit
                              ? 'row'
                              : 'column'
                            : 'row',
                        }}
                      >
                        <Grid
                          item
                          xs={
                            props.isMobile
                              ? selectedProfileList.length ===
                                props.paymentMethodCapLimit
                                ? 4
                                : 12
                              : 3
                          }
                        >
                          <Typography className={classes.colorTextPrimary}>
                            {selectedProfileList.length ===
                            props.paymentMethodCapLimit
                              ? t('ONETIMEPAY_PAYMENT_AMOUNT')
                              : t('ONETIMEPAY_USING_LABEL') +
                                ' ' +
                                getPaymentMethodLabel(
                                  paymentProfile?.profile.type,
                                  true,
                                )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={props.isMobile ? 12 : 9}
                          className={classes.gridDisplay}
                        >
                          <Typography
                            className={classes.colorTextPrimary}
                            variant={'h2'}
                          >
                            {selectedProfileList.length ===
                            props.paymentMethodCapLimit
                              ? t('$' + Number(paymentProfile.value).toFixed(2))
                              : null}
                          </Typography>
                          {(!props.isMobile &&
                            selectedProfileList.length ===
                              props.paymentMethodCapLimit) ||
                          selectedProfileList.length !==
                            props.paymentMethodCapLimit ? (
                            <div
                              style={{ width: '100%', verticalAlign: 'top' }}
                            >
                              {selectedProfileList.length ===
                              props.paymentMethodCapLimit ? (
                                <Typography
                                  variant={'body1'}
                                  className={classes.marginLeftSpace}
                                  component="span"
                                >
                                  {t('ONETIMEPAY_USING_LABEL') +
                                    ' ' +
                                    getPaymentMethodLabel(
                                      paymentProfile?.profile.type,
                                      true,
                                    )}
                                </Typography>
                              ) : null}
                              <Typography
                                className={classes.paymentMethodIcon}
                                component="span"
                                style={{
                                  width: '100%',
                                  display: 'inline',
                                  marginLeft:
                                    selectedProfileList.length ===
                                    props.paymentMethodCapLimit
                                      ? '0.5em'
                                      : 0,
                                }}
                                variant={'body2'}
                              >
                                <PaymentProfileIcon
                                  type={paymentProfile.profile?.type}
                                ></PaymentProfileIcon>
                                <Typography
                                  variant={'h2'}
                                  component="span"
                                  style={{
                                    verticalAlign: 'middle',
                                    marginLeft: '0.2em',
                                  }}
                                >
                                  {getAccountDisplayString(
                                    paymentProfile?.profile,
                                  ) || t('GUEST_PAY_AUTHORIZED')}
                                </Typography>
                              </Typography>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      {props.isMobile &&
                      selectedProfileList.length ===
                        props.paymentMethodCapLimit ? (
                        <>
                          <Grid item className={classes.gridDisplay}>
                            <Typography
                              className={classes.colorTextPrimary}
                              variant={'body1'}
                            >
                              {t('ONETIMEPAY_USING_LABEL') +
                                ' ' +
                                getPaymentMethodLabel(
                                  paymentProfile?.profile.type,
                                  true,
                                )}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.paymentMethodMob}>
                            <Typography
                              className={classes.paymentMethodIcon}
                              style={{ width: '100%' }}
                              variant={'body2'}
                            >
                              <PaymentProfileIcon
                                type={paymentProfile.profile?.type}
                              ></PaymentProfileIcon>
                              <Typography
                                variant={'h2'}
                                component="span"
                                style={{
                                  verticalAlign: 'middle',
                                  marginLeft: '0.2em',
                                }}
                              >
                                {getAccountDisplayString(
                                  paymentProfile?.profile,
                                ) || t('GUEST_PAY_AUTHORIZED')}
                              </Typography>
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  );
                })}
              {props.isMobile &&
              selectedProfileList.length !== props.paymentMethodCapLimit ? (
                <div className={classes.paddingSpace} />
              ) : (
                <Divider />
              )}
              <Grid item xs={12} className={classes.marginTop1}>
                <Typography
                  className={classes.colorTextPrimary}
                  component={'span'}
                  style={{ lineHeight: 2 }}
                >
                  {richT(
                    props.isForTPA
                      ? 'I_AUTHORIZE_PGE_TO_CHARGE_TPA_PAYMENT'
                      : paymentDateIsWithInThreeDays
                      ? 'I_AUTHORIZE_PGE_TO_CHARGE_ONETIME_PAY'
                      : 'I_AUTHORIZE_TO_PGE_TEXT_ABOVE_THREE_DAYS',
                    {
                      amount: props.formattedPaymentAmount,
                      paymentType:
                        selectedProfileList.length ===
                        props.paymentMethodCapLimit
                          ? 'account(s)'
                          : getPaymentCategoryLabel(
                              selectedProfileList[0].profile?.type,
                            ),
                    },
                  )}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      {props?.isForTPA ? (
        <Grid container item xs={12} justify={'flex-end'}>
          <Grid item xs={12} md="auto">
            <Box>
              <ProceedOrCancel
                proceedHandler={props.onSubmit}
                cancelRoute={ROUTES.TPA_MAKE_PAYMENT}
                cancelLabel={t('BACK')}
                proceedLabel={t('SUBMIT_PAYMENT')}
                cancelHandler={
                  props?.cancelHandler
                    ? props?.cancelHandler
                    : () => {
                        return false;
                      }
                }
                proceedStyle={colors.orange}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          className={classes.buttonContainer}
          spacing={1}
        >
          <Grid item md="auto">
            <Box className={classes.cancelVerificationLink}>
              <TextLink to={ROUTES.ACCOUNT} className={classes.cancel}>
                {t('CANCEL')}
              </TextLink>
            </Box>
          </Grid>
          <Grid item md="auto">
            <Box>
              <ProceedOrCancel
                proceedHandler={props.onSubmit}
                cancelRoute={
                  props?.isForReconnect
                    ? ROUTES.RECONNECT_SERVICE_PAYMENT
                    : ROUTES.PAYMENT
                }
                cancelHandler={
                  props?.cancelHandler
                    ? props?.cancelHandler
                    : () => {
                        return false;
                      }
                }
                cancelLabel={t('BACK')}
                cancelVariant="Button"
                proceedLabel={t('PAY_BILL')}
                proceedStyle={colors.orange}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentVerificationForm;
