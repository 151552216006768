import React, { FC, useContext, useState } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Grid,
  Card,
  CircularProgress,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { useTranslation } from '../../../../hooks/useTranslation';
import usePaymentForm from '../../../../hooks/usePaymentForm';
import useWrapWithLoader from '../../../../hooks/useWrapWithLoading';

import useOneTimePayment from '../../../../hooks/useOneTimePayment';
import {
  OneTimePayConfigData,
  SubmitOneTimePay,
} from '../../../payment-form/oneTimePayment.types';
import PaymentInfoForm from '../../../payment-form/payment-info-form';
import {
  toCurrencyString,
  toDateStringFullMonthName,
} from '../../../../util/format';

import ROUTES from '../../../../routes';
import PaymentVerificationForm from '../../../payment-form/payment-verification-form';

import { NotificationsContext } from '../../../../providers/NotificationsProvider';
import { PaymentObject } from '../useTPA';
import { useApolloClient } from '@apollo/react-hooks';
import useGTMUtilNmttp from '../../gtm/useGTMUtilNmttp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tpaContainer: {
      padding: theme.spacing(3, 3.5, 3, 3.5),
      [theme.breakpoints.down('sm')]: {
        padding: '24px 18px',
      },
    },
    reviewPlan: {
      marginTop: theme.spacing(3),
    },
    paymentContainer: {
      padding: theme.spacing(0, 3, 0, 3),
    },
  }),
);

type Props = {
  path: string;
  firstInstallmentAmount: number;
  firstInstallmentDueDate: Date;
  handleOnPaymentConfirmation: (data: PaymentObject) => Promise<void>;
};

const TPAMakePayment: FC<Props> = (props: Props) => {
  const {
    path,
    handleOnPaymentConfirmation,
    firstInstallmentAmount,
    firstInstallmentDueDate,
  } = props;

  const notificationContext = useContext(NotificationsContext);

  const {
    gtm_TPAMakePayment_HandleOnBackClick,
    gtm_TPAMakePayment_HandleOnMakePaymentError,
  } = useGTMUtilNmttp();

  const paymentMethodCapLimit: number = 2;
  const { t, richT } = useTranslation();
  const payBillProps = usePaymentForm();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const childProps = { isMobile, ...payBillProps };
  const classes = useStyles();
  const [showPayment, setShowPayment] = useState<Boolean>(true);
  const [
    oneTimePayConfig,
    setOneTimePayConfig,
  ] = useState<OneTimePayConfigData | null>(null);

  const handleConfigSubmit = (data: OneTimePayConfigData) => {
    setOneTimePayConfig(data);
    setShowPayment(false);
  };

  const paymentVerificationCancelHandler = () => {
    gtm_TPAMakePayment_HandleOnBackClick();
    setShowPayment(true);
  };

  const apolloClient = useApolloClient();

  //TODO: do we need this callback function for TPA ?
  const handleEligibleValue = (value: boolean) => {
    console.log('handle eligible value');
  };
  const { wrapWithLoader } = useWrapWithLoader();

  const {
    accountDetails,
    customer,
    savedProfileList,
    profileListLoading,
    accountLoading,
    encryptedPersonId,
    submitOneTimePay,
    refetchPayBillAccountDetails,
    refetchSingleAccountDetails,
  } = useOneTimePayment();

  const getFormattedPaymentAmount = () => {
    return oneTimePayConfig?.paymentAmount
      ? `$${toCurrencyString(
          parseFloat(oneTimePayConfig?.paymentAmount),
          false,
        )}`
      : '';
  };
  const handleConfirmSubmit = wrapWithLoader(async function() {
    try {
      notificationContext.setState({ isOpen: false });

      const additionalPayInfo: SubmitOneTimePay = {
        emailAddress: customer?.email!,
        encryptedPersonId: encryptedPersonId!,
      };

      const oneTimeSubmitPayResult = await submitOneTimePay(
        accountDetails!,
        oneTimePayConfig!,
        additionalPayInfo,
      );
      const oneTimeSubmitData = oneTimeSubmitPayResult.data;

      if (oneTimeSubmitData?.makePayment.paymentConfirmation.length) {
        const oneTimePayConfirmationData = oneTimeSubmitData.makePayment;
        try {
          await refetchPayBillAccountDetails();
          await refetchSingleAccountDetails();
        } catch (e) {
          await handleOnPaymentConfirmation({
            oneTimePayConfirmationData,
            oneTimePayConfig,
            paymentMethodCapLimit,
          });

          // We could not refetch the account, so clear the account from the cache to
          // force a data reload
          // TODO: When we migrate to Apollo 3, use cache.evict with cache.gc
          const cache = apolloClient.cache as any;
          return cache.data?.delete(
            `AccountDetail:${accountDetails?.accountNumber}`,
          );
        }
        await handleOnPaymentConfirmation({
          oneTimePayConfirmationData,
          oneTimePayConfig,
          paymentMethodCapLimit,
        });
        return;
      } else {
        gtm_TPAMakePayment_HandleOnMakePaymentError();
        window.scrollTo(0, 0);
        return notificationContext.setState({
          isOpen: true,
          message: richT('PAYMENT_ERROR_MESSAGE'),
          severity: 'error',
        });
      }
    } catch (_) {
      gtm_TPAMakePayment_HandleOnMakePaymentError();
      window.scrollTo(0, 0);
      return notificationContext.setState({
        isOpen: true,
        message: richT('PAYMENT_ERROR_MESSAGE'),
        severity: 'error',
      });
    }
  });

  if (
    accountDetails === undefined ||
    accountLoading ||
    customer === undefined ||
    savedProfileList === undefined ||
    profileListLoading
  ) {
    return <CircularProgress data-testid="loading-data" size={35} />; // show loading until data is retrieved
  }

  return (
    <>
      <Card className={classes.paymentContainer}>
        <Grid>
          <Typography variant={'h4'}>
            {richT('PAYMENT_CARD_DUE_TEXT', {
              AMOUNT: `${firstInstallmentAmount}`,
              DUE_DATE: `${toDateStringFullMonthName(firstInstallmentDueDate)}`,
            })}
          </Typography>
        </Grid>
      </Card>

      {customer && savedProfileList && accountDetails && (
        <>
          {showPayment && (
            <PaymentInfoForm
              path={path}
              {...childProps}
              customer={customer}
              savedProfileList={savedProfileList}
              account={accountDetails}
              onSubmit={handleConfigSubmit}
              handleEligible={handleEligibleValue}
              oneTimePayConfigData={oneTimePayConfig}
              paymentMethodCapLimit={paymentMethodCapLimit}
              isForTPA={true}
              amountToBePaid={`$${firstInstallmentAmount}`}
              calendarEndDate={firstInstallmentDueDate}
              backButtonRedirect={ROUTES.TPA_CONFIRM_PLAN}
              maxPaymentDate={firstInstallmentDueDate}
            />
          )}
          {!showPayment && (
            <PaymentVerificationForm
              path={ROUTES.PAYMENT_VERIFICATION}
              {...childProps}
              oneTimePayConfigData={oneTimePayConfig!}
              formattedPaymentAmount={getFormattedPaymentAmount()}
              onSubmit={handleConfirmSubmit}
              paymentMethodCapLimit={paymentMethodCapLimit}
              isForTPA={true}
              cancelHandler={paymentVerificationCancelHandler}
            />
          )}
        </>
      )}
    </>
  );
};
export default TPAMakePayment;
