import { AlertState, AlertStates, Model } from './types';
import { NotificationType } from '../../../__generated__/pge-types';

export const allAlertTypes = [
  NotificationType.Out,
  NotificationType.Webpyrem,
  NotificationType.Webpyrcv,
  NotificationType.Webpda,
  NotificationType.Webdisc,
  NotificationType.Webuse,
  NotificationType.Webexc,
] as const;

export const allAlertTypes1 = [
  NotificationType.Out,
  NotificationType.Webpyrem,
  NotificationType.Webpyrcv,
  NotificationType.Webpda,
  NotificationType.Webdisc,
  NotificationType.Webuse,
  NotificationType.Webexc,
];

export const defaultAlertState: AlertState = {
  email: false,
  text: false,
};

export const defaultAlertStates: AlertStates = {
  [NotificationType.Out]: defaultAlertState,
  [NotificationType.Webpyrem]: defaultAlertState,
  [NotificationType.Webpyrcv]: defaultAlertState,
  [NotificationType.Webpda]: defaultAlertState,
  [NotificationType.Webdisc]: defaultAlertState,
  [NotificationType.Webuse]: defaultAlertState,
  [NotificationType.Webexc]: defaultAlertState,
};

export const noTextChecked = (data: Model) => {
  return allAlertTypes.every(alrt => !data[alrt].text);
};
