import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import _unionBy from 'lodash/unionBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  PaymentCategory,
  PaymentProfile,
} from '../../../__generated__/pge-types';
import {
  PaymentProfileInfo,
  MultiPaymentProfileChangeHandler,
  selectedProfileInfo,
} from '../types';

import useStyles from './PaymentProfileOnetimeSplitSelector.styles';
import { OneTimePayConfigData } from '../../payment-form/oneTimePayment.types';
import SplitPayTextField from '../manage-payment-profile/SplitPayTextField';
import PaymentMethodSelector from '../../payment-wallet/payment-method-selector';
import ExpandLessSharpIcon from '@material-ui/icons/ExpandLessSharp';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';

import useAccountCustomer from '../../../hooks/useAccountCustomer';

type Props = {
  paymentAmountByUser: string;
  paymentMethodCapLimit: number;
  OneTimePayConfig: OneTimePayConfigData;
  selectedPaymentList: selectedProfileInfo[];
  splitPayLabelClick: (value: boolean) => void;
  onAmountChange: (name: string, amount: string) => void;
  PaymentMethodChange: MultiPaymentProfileChangeHandler;
  AddNewPaymentProfile: (profile: PaymentProfileInfo) => void;
  DeletePaymentProfile: (profile: PaymentProfileInfo) => void;
};

export const PaymentProfileOnetimeSplitSelector = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const primaryIndex: number = 0;
  const secondaryIndex: number = 1;
  const {
    paymentAmountByUser,
    paymentMethodCapLimit,
    OneTimePayConfig,
    selectedPaymentList,
    splitPayLabelClick,
    onAmountChange,
    PaymentMethodChange,
    AddNewPaymentProfile,
    DeletePaymentProfile,
  } = props;

  const [paymentProfiles, setPaymentProfiles] = useState<PaymentProfile[]>(
    OneTimePayConfig.paymentSelector.savedProfileList || [],
  );

  const [selectedProfileInformation, setSelectedProfileInformation] = useState<
    selectedProfileInfo[]
  >(OneTimePayConfig.paymentSelector.selectedPaymentProfileList || []);
  useEffect(() => {
    setSelectedProfileInformation(selectedPaymentList);
  }, [selectedPaymentList.length]);
  useEffect(() => {
    PaymentMethodChange(selectedProfileInformation);
  }, [selectedProfileInformation]);

  useEffect(() => {
    if (OneTimePayConfig.splitPayStatus) {
      return;
    } else {
      const arr = selectedProfileInformation.filter(
        profile => profile.isPrimary === true,
      );
      if (arr.length) {
        setSelectedProfileInformation(arr);
      }
    }
  }, [OneTimePayConfig.splitPayStatus]);

  useEffect(() => {
    if (Array.isArray(paymentProfiles) && Boolean(paymentProfiles.length)) {
      if (
        !Boolean(
          OneTimePayConfig.paymentSelector.selectedPaymentProfileList.length,
        ) &&
        OneTimePayConfig.initialLoading &&
        !Boolean(OneTimePayConfig.selectedAccordian)
        // && paymentProfiles.some(p => p.default === true)
      ) {
        setSelectedProfileInformation([
          {
            profile: paymentProfiles.some(p => p.default === true)
              ? paymentProfiles.filter(p => p.default === true)[0]
              : paymentProfiles[0],
            value: paymentAmountByUser,
            error: false,
            errorText: '',
            isPrimary: true,
          },
        ]);
      }
    }
  }, [paymentProfiles]);

  const PaymentProfileChange = (
    profile: PaymentProfileInfo,
    status: boolean,
    index: number,
  ) => {
    setSelectedProfileInformation(state => {
      if (state.length) {
        const updatedArr = state.filter(
          ({ isPrimary }) => isPrimary === status,
        );
        if (updatedArr.length) {
          updatedArr.map(item => {
            item.profile = profile.profile;
            item.value = OneTimePayConfig.splitAmounts.length
              ? OneTimePayConfig.splitAmounts[index].value!
              : paymentAmountByUser;
            item.error = OneTimePayConfig.splitAmounts.length
              ? OneTimePayConfig.splitAmounts[index].error!
              : false;
            item.errorText = OneTimePayConfig.splitAmounts.length
              ? OneTimePayConfig.splitAmounts[index].errorText!
              : '';
            item.isPrimary = status;
          });
        } else {
          if (status) {
            state.unshift({
              profile: profile.profile,
              value: OneTimePayConfig.splitAmounts.length
                ? OneTimePayConfig.splitAmounts[index].value!
                : paymentAmountByUser,
              error: OneTimePayConfig.splitAmounts.length
                ? OneTimePayConfig.splitAmounts[index].error!
                : false,
              errorText: OneTimePayConfig.splitAmounts.length
                ? OneTimePayConfig.splitAmounts[index].errorText!
                : '',
              isPrimary: status,
            });
          } else {
            state.push({
              profile: profile.profile,
              value: OneTimePayConfig.splitAmounts.length
                ? OneTimePayConfig.splitAmounts[index].value!
                : paymentAmountByUser,
              error: OneTimePayConfig.splitAmounts.length
                ? OneTimePayConfig.splitAmounts[index].error!
                : false,
              errorText: OneTimePayConfig.splitAmounts.length
                ? OneTimePayConfig.splitAmounts[index].errorText!
                : '',
              isPrimary: status,
            });
          }
        }
        const secondary = selectedProfileInformation.filter(
          ({ isPrimary }) => isPrimary === !status,
        );
        const splitIndex = !status ? primaryIndex : secondaryIndex;
        if (secondary.length) {
          secondary.map(items => {
            items.value = OneTimePayConfig.splitAmounts.length
              ? OneTimePayConfig.splitAmounts[splitIndex].value!
              : paymentAmountByUser;
            items.error = OneTimePayConfig.splitAmounts.length
              ? OneTimePayConfig.splitAmounts[splitIndex].error!
              : false;
            items.errorText = OneTimePayConfig.splitAmounts.length
              ? OneTimePayConfig.splitAmounts[splitIndex].errorText!
              : '';
          });
        }
      } else {
        state.push({
          profile: profile.profile,
          value: paymentAmountByUser,
          error: false,
          errorText: '',
          isPrimary: status,
        });
      }
      return [...state];
    });
  };

  const onSplitAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onAmountChange(name, value);
  };
  const { customer } = useAccountCustomer();
  const addOrUpdateProps = {
    ownerId: customer?.personId,
    allowedCategories: [
      PaymentCategory.Dd,
      PaymentCategory.Cc,
      PaymentCategory.Dc,
      PaymentCategory.PaypalAccount,
      PaymentCategory.AmazonPay,
    ],
    onNew: async (profile: PaymentProfileInfo) => {
      AddNewPaymentProfile(profile);
    },
  };
  return (
    <>
      <Grid xs={12}>
        <Grid item xs={12} className={classes.item1margin3}>
          <PaymentMethodSelector
            onChange={selectedProfile => {
              PaymentProfileChange(selectedProfile, true, primaryIndex);
            }}
            onNew={newProfileInfo => {
              AddNewPaymentProfile(newProfileInfo);
              PaymentProfileChange(newProfileInfo, true, primaryIndex);
            }}
            onDelete={deleteProfile => {
              DeletePaymentProfile(deleteProfile);
            }}
            currentProfileInfo={
              selectedProfileInformation.length &&
              selectedProfileInformation.filter(
                ({ isPrimary }) => isPrimary === true,
              ).length
                ? {
                    profile:
                      selectedProfileInformation[
                        selectedProfileInformation.findIndex(
                          ({ isPrimary }) => isPrimary === true,
                        )
                      ].profile,
                  }
                : undefined
            }
            excludeProfilesInfo={
              selectedProfileInformation.length &&
              selectedProfileInformation.filter(
                ({ isPrimary }) => isPrimary === false,
              ).length
                ? [
                    {
                      profile:
                        selectedProfileInformation[
                          selectedProfileInformation.findIndex(
                            ({ isPrimary }) => isPrimary === false,
                          )
                        ].profile,
                    },
                  ]
                : []
            }
          />
        </Grid>
        {OneTimePayConfig.splitPayStatus ? (
          <>
            <Grid item xs={12} className={classes.margin1}>
              <Typography
                className={classes.colorTextPrimary}
                variant={'body2'}
              >
                {t('AMOUNT_TO_BE_SPLIT')}
              </Typography>
            </Grid>
            <Grid item>
              <SplitPayTextField
                name={String(primaryIndex)}
                value={OneTimePayConfig.splitAmounts[primaryIndex].value}
                onChange={onSplitAmountChange}
                error={OneTimePayConfig.splitAmounts[primaryIndex]?.error}
                helperText={
                  OneTimePayConfig.splitAmounts[primaryIndex]?.error
                    ? OneTimePayConfig.splitAmounts[primaryIndex].errorText
                    : null
                }
              />
            </Grid>
          </>
        ) : null}
        <Divider className={classes.dividerStyle} />

        <Grid item xs={12}>
          <Typography
            className={classes.splitPayText}
            component={'span'}
            variant={'h6'}
            onClick={() => splitPayLabelClick(!OneTimePayConfig.splitPayStatus)}
          >
            {t('SPLIT_PAY_WITH_SECOND_METHOD')}
            <span style={{ verticalAlign: 'middle' }}>
              {OneTimePayConfig.splitPayStatus ? (
                <ExpandLessSharpIcon color="primary" />
              ) : (
                <ExpandMoreSharpIcon color="primary" />
              )}
            </span>
          </Typography>
        </Grid>

        {OneTimePayConfig.splitPayStatus ? (
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.margin3}>
              <PaymentMethodSelector
                onChange={selectedProfile => {
                  PaymentProfileChange(selectedProfile, false, secondaryIndex);
                }}
                onNew={newProfileInfo => {
                  AddNewPaymentProfile(newProfileInfo);
                  PaymentProfileChange(newProfileInfo, false, secondaryIndex);
                }}
                onDelete={deleteProfile => {
                  DeletePaymentProfile(deleteProfile);
                }}
                selectLabel={t('SELECT_SECONDARY_PAYMENT_METHOD')}
                currentProfileInfo={
                  selectedProfileInformation.length &&
                  selectedProfileInformation.filter(
                    ({ isPrimary }) => isPrimary === false,
                  ).length
                    ? {
                        profile:
                          selectedProfileInformation[
                            selectedProfileInformation.findIndex(
                              ({ isPrimary }) => isPrimary === false,
                            )
                          ].profile,
                      }
                    : undefined
                }
                excludeProfilesInfo={
                  selectedProfileInformation.length &&
                  selectedProfileInformation.filter(
                    ({ isPrimary }) => isPrimary === true,
                  ).length
                    ? [
                        {
                          profile:
                            selectedProfileInformation[
                              selectedProfileInformation.findIndex(
                                ({ isPrimary }) => isPrimary === true,
                              )
                            ].profile,
                        },
                      ]
                    : []
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.margin1}>
              <Typography
                className={classes.colorTextPrimary}
                variant={'body2'}
              >
                {t('AMOUNT_TO_BE_SPLIT')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <SplitPayTextField
                name={String(secondaryIndex)}
                value={OneTimePayConfig.splitAmounts[secondaryIndex].value}
                onChange={onSplitAmountChange}
                error={OneTimePayConfig.splitAmounts[secondaryIndex]?.error}
                helperText={
                  OneTimePayConfig.splitAmounts[secondaryIndex]?.error
                    ? OneTimePayConfig.splitAmounts[secondaryIndex].errorText
                    : null
                }
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default PaymentProfileOnetimeSplitSelector;
