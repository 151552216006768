import React from 'react';
import { Link } from '@reach/router';
import Paper from '@material-ui/core/Paper';
import { FormState } from '../../../hooks/useFormState.types';
import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PGEButton from '../../buttons';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Check from '@material-ui/icons/Check';
import { useTheme } from '@material-ui/core';
import { Model, AlertOptions, AlertState } from './types';
import { displayPhoneNumberFormat } from '../../../util/format';
import { useStyles } from './styles';
import { useIsMobile } from '../../../util/style-utils';
import ROUTES from '../../../routes';

type EmailTextDisplayProps = {
  value: AlertState;
  label: string;
  textFieldKey?: string;
  form: FormState<Model>;
};

function EmailTextDisplay({
  label,
  value,
  textFieldKey,
  form,
}: EmailTextDisplayProps) {
  const theme = useTheme();
  const classes = useStyles();
  const labelPortion = (
    <Typography variant={'body1'} style={{ fontWeight: 600 }}>
      {label}
      {textFieldKey ? ` $${form.values[textFieldKey as keyof Model]}` : null}
    </Typography>
  );

  return (
    <div className={classes.row}>
      <Box>{labelPortion}</Box>
      <Box>
        {value.email === true ? (
          <Icon style={{ color: theme.palette.success.main }}>
            <Check fontSize="large" />
          </Icon>
        ) : null}
      </Box>
      <Box>
        {value.text === true ? (
          <Icon style={{ color: theme.palette.success.main }}>
            <Check fontSize="large" />
          </Icon>
        ) : null}
      </Box>
    </div>
  );
}

type Props = {
  path?: string;
  form: FormState<Model>;
  onPrevious: () => void;
  alerts: Array<AlertOptions>;
  defaultFormValues: Model;
  onSubmit: () => void;
};

export default function ManageAlertsConfirm({
  form,
  onPrevious,
  alerts,
  defaultFormValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const values = form.values;
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.accountCard}>
          <Typography variant={'h2'} className={classes.colorTextPrimary}>
            {t('SUMMARY')}
          </Typography>
          <Typography variant={'body1'} className={classes.colorTextPrimary}>
            {t('GENERIC_CONFIRM_SUMMARY')}
          </Typography>
          <Box>
            <Typography variant={'body1'} className={classes.colorTextPrimary}>
              {t('EMAIL')}
              <Typography variant={'body1'} className={classes.boldText}>
                {values.email}
              </Typography>
            </Typography>
          </Box>
          {(defaultFormValues.phoneNumber || form.values.phoneNumber) && (
            <Box>
              <Typography
                variant={'body1'}
                className={classes.colorTextPrimary}
              >
                {t('PHONE_NUMBER')}
                <Typography variant={'body1'} className={classes.boldText}>
                  {defaultFormValues.phoneNumber && !form.values.phoneNumber
                    ? t('REMOVED')
                    : displayPhoneNumberFormat(form.values.phoneNumber)}
                </Typography>
              </Typography>
            </Box>
          )}
          <div className={`${classes.gridContainer} ${classes.noLastBorder}`}>
            <div className={classes.row}>
              <Box>
                <Typography variant={'body1'}>{t('DESCRIPTION')}</Typography>
              </Box>
              <Box>
                <Typography variant={'body1'} style={{ paddingLeft: 5 }}>
                  {t('EMAIL')}
                </Typography>
              </Box>
              <Box>
                <Typography variant={'body1'} style={{ paddingLeft: 7 }}>
                  {t('TEXT')}
                </Typography>
              </Box>
            </div>
            {alerts.map(item => {
              return (
                <EmailTextDisplay
                  {...item}
                  form={form}
                  value={form.values[item.key as keyof Model] as AlertState}
                />
              );
            })}
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justify={isMobile ? 'center' : 'space-between'}
          wrap="wrap-reverse"
          spacing={2}
        >
          <Grid item xs={12} md="auto">
            <Box textAlign="center">
              <Link to={ROUTES.ACCOUNT}>{t('CANCEL')}</Link>
            </Box>
          </Grid>
          <Grid item xs={10} md="auto">
            <Grid container wrap="wrap-reverse" spacing={2}>
              <Grid item xs={12} md="auto">
                <PGEButton
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={onPrevious}
                  fullWidth
                >
                  {t('PREVIOUS')}
                </PGEButton>
              </Grid>
              <Grid item xs={12} md="auto">
                <PGEButton
                  color={'primary'}
                  variant={'contained'}
                  onClick={onSubmit}
                  fullWidth
                >
                  {t('SUBMIT')}
                </PGEButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
