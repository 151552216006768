import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useReducer, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import AccountDropdown from '../../components/account-dropdown';
import { PageSize } from '../../components/pagination/types';
import PaymentHistoryPage from '../../components/payment-history/PaymentHistoryPage';
import PaymentHistoryReducer from '../../components/payment-history/paymentHistoryReducer';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import ViewBillLeftPane from '../../components/view-bill/view-bill-left-pane';
import useDownloadBill from '../../hooks/useDownloadBill';
import usePaymentHistory from '../../hooks/usePaymentHistory';
import { useTranslation } from '../../hooks/useTranslation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import colors from '../../themes/main-colors';
import {
  toCurrencyDisplayFormat,
  toDateString,
  toDateStringFullMonthName,
} from '../../util/format';
import {
  AccountDetail,
  ViewPaymentHistoryDetail,
  ViewPaymentHistoryBillingAndPaymentType,
} from '../../__generated__/pge-types';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainArea: {
      minWidth: '20em',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    paperBox: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1),
      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(2),
      },
    },
    accountSummary: {
      textAlign: 'center',
      padding: theme.spacing(3),
    },
  }),
);

type Props = {
  path?: string;
};

export default (_: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, dispatch] = useReducer(
    PaymentHistoryReducer.reducer,
    PaymentHistoryReducer.initialState,
  );
  const [
    currentBill,
    setCurrentBill,
  ] = useState<ViewPaymentHistoryDetail | null>(null);

  const {
    historyLoading,
    historyData,
    totalCount,
    selectedAccount,
    accountParams,
    isSummaryBillAccount,
  } = usePaymentHistory({
    paymentHistoryParams: {
      page: state.page,
      pageSize: state.pageSize,
    },
  });

  const downloader = useDownloadBill();
  const { wrapWithLoader } = useWrapWithLoader();
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);

  useEffect(() => {
    if (
      selectedAccount?.encryptedAccountNumber !==
      state.currentAccount?.encryptedAccountNumber
    ) {
      dispatch(
        PaymentHistoryReducer.actions.setCurrentAccount(selectedAccount),
      );
      setCurrentBill(null);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (historyData && !currentBill) {
      const firstBill = historyData.find(
        (item: any) =>
          item.type === ViewPaymentHistoryBillingAndPaymentType.Bill,
      );
      if (firstBill) {
        setCurrentBill(firstBill);
      }
    }
  }, [historyData]);

  const onAccountSelect = (account: AccountDetail) => {
    dispatch(PaymentHistoryReducer.actions.setCurrentAccount(account));
  };

  if (accountParams === undefined) {
    return null;
  }

  const selectedAccountIsCurrentAccount =
    (selectedAccount &&
      state.currentAccount &&
      selectedAccount?.encryptedAccountNumber ===
        state.currentAccount?.encryptedAccountNumber) ??
    false;

  return (
    <>
      <Page2ColumnLayout leftColumn={<ViewBillLeftPane />}>
        <Helmet>
          <title>{t('BILLING_PAYMENT_HISTORY_SIDE_MENU')}</title>
        </Helmet>
        <div className={classes.mainArea}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography
                className={classes.colorTextPrimary}
                component={'span'}
                variant={'h1'}
              >
                {t('BILLING_PAYMENT_HISTORY_SIDE_MENU')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AccountDropdown onSelect={onAccountSelect} />
            </Grid>
            <Grid item xs={12}>
              {selectedAccountIsCurrentAccount && (
                <Card className={classes.accountSummary}>
                  <Typography variant={'h4'}>
                    {t('ACCOUNT_BALANCE')}{' '}
                    <strong>
                      {`$${toCurrencyDisplayFormat(
                        selectedAccount?.billInfo?.amountDue,
                        true,
                        'CR',
                      )}`}
                    </strong>{' '}
                    {t('AS_OF')}{' '}
                    <strong>{toDateStringFullMonthName(new Date())}</strong>
                  </Typography>
                </Card>
              )}
            </Grid>
            <Grid item xs={12}>
              <PaymentHistoryPage
                currentBill={currentBill}
                historyLoading={
                  historyLoading || !selectedAccountIsCurrentAccount
                }
                history={historyData}
                page={state.page}
                pageSize={state.pageSize}
                totalCount={!selectedAccountIsCurrentAccount ? 0 : totalCount}
                onPageChange={(newPage: number, newPageSize: PageSize) => {
                  if (state.page !== newPage) {
                    dispatch(PaymentHistoryReducer.actions.setPage(newPage));
                  }

                  if (state.pageSize !== newPageSize) {
                    dispatch(
                      PaymentHistoryReducer.actions.setPageSize(newPageSize),
                    );
                  }
                }}
                isSummaryBillAccount={isSummaryBillAccount}
              />
            </Grid>
          </Grid>
        </div>
      </Page2ColumnLayout>
    </>
  );
};
