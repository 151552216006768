import { differenceInDays } from 'date-fns';
import { TpaMonthsMatrix } from './useTPA';

export const getUpcomingTpaPayment = (tpaMatrix: TpaMonthsMatrix[]) => {
  const upcomingTpaPayment: TpaMonthsMatrix | undefined = tpaMatrix.find(
    tpaPaymentItem => {
      const currentDate = new Date();
      return (
        differenceInDays(tpaPaymentItem.paymentDate, currentDate) >= 0 &&
        tpaPaymentItem.doesBillExist &&
        !tpaPaymentItem.isPaymentCompleted
      );
    },
  );
  return upcomingTpaPayment;
};

export const getTpaPaymentsOverDue = (tpaMatrix: TpaMonthsMatrix[]) => {
  const overDueTpaPayment: TpaMonthsMatrix[] = tpaMatrix.filter(
    tpaPaymentItem => {
      const currentDate = new Date();
      return (
        differenceInDays(tpaPaymentItem.paymentDate, currentDate) < 0 &&
        tpaPaymentItem.doesBillExist &&
        !tpaPaymentItem.isPaymentCompleted
      );
    },
  );
  return overDueTpaPayment;
};

export const getAllTpaPaymentsOverDue = (tpaMatrix: TpaMonthsMatrix[]) => {
  const overDueTpaPayment: TpaMonthsMatrix[] = tpaMatrix.filter(
    tpaPaymentItem => {
      const currentDate = new Date();
      return (
        tpaPaymentItem.paymentDate.getFullYear() < currentDate.getFullYear() || tpaPaymentItem.paymentDate.getFullYear() === currentDate.getFullYear() && tpaPaymentItem.paymentDate.getMonth() < currentDate.getMonth() + 1 &&
        !tpaPaymentItem.isPaymentCompleted
      );
    },
  );
  return overDueTpaPayment;
};

export const getTPAYears = (tpaMatrix: TpaMonthsMatrix[]) => {
  const tpaYearsArray: string[] = [
    ...new Set(
      tpaMatrix.map(({ paymentDate }) =>
        new Date(paymentDate).getFullYear().toString(),
      ),
    ),
  ];
  return tpaYearsArray;
};

export const getSelectedYearTPAMonthMatrix = (
  year: string,
  tpaMatrix: TpaMonthsMatrix[],
) => {
  const currentYearMatrix = tpaMatrix.filter(item => {
    return year === item.paymentDate.getFullYear().toString() && item;
  });
  return currentYearMatrix;
};
